/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { FaPen } from 'react-icons/fa6';
import type { TableProps } from 'antd';

import {
  useSidebarCreateOperationGroup,
} from 'components/sidebars/sidebar-create-operation-group';
import Table from 'components/core/table';

import { OperationGroupDto } from 'api/auth';
import { OperationModel } from 'models/OperationModel';
import { OperationGroupModel } from 'models/OperationGroupModel';
import { CollectionWithPages } from 'stores/collection/CollectionWithPages';

type Props = {
  collection: CollectionWithPages<OperationGroupModel, {}>;
  operations: OperationModel[];
  onUpdate: (operationGroupId: number, data: OperationGroupDto) => Promise<void>;
  onDelete: (operationGroupId: number) => Promise<void>;
};

const OperationGroupsTable = observer((props: Props) => {
  const { collection, operations, onUpdate, onDelete } = props;
  const { formatMessage } = useIntl();

  const columns: TableProps<OperationGroupModel>['columns'] = [
    {
      title: formatMessage({
        id: 'table.operation_groups.col.id',
        defaultMessage: 'ID',
      }),
      dataIndex: 'id',
      key: 'id',
      render: (id: string) => <span>{id}</span>,
    },
    {
      title: formatMessage({
        id: 'table.operation_groups.col.name',
        defaultMessage: 'Name',
      }),
      dataIndex: 'name',
      key: 'name',
      render: (name: string) => <span>{name}</span>,
    },
    {
      title: formatMessage({
        id: 'table.operation_groups.col.group_type',
        defaultMessage: 'Group type',
      }),
      dataIndex: 'groupType',
      key: 'groupType',
      render: (groupType: string) => <span>{groupType}</span>,
    },
    {
      title: formatMessage({
        id: 'table.operation_groups.col.action',
        defaultMessage: 'Action',
      }),
      key: 'action',
      render: () => (
        <button>
          <FaPen />
        </button>
      ),
    },
  ];

  const { showCreateOperationGroupSidebar } = useSidebarCreateOperationGroup({
    operations,
    onUpdate,
    onDelete,
  });

  return (
    <Table<OperationGroupModel, {}>
      collection={collection}
      columns={columns}
      onRowClick={(item) => showCreateOperationGroupSidebar(item)}
    />
  );
});

export default OperationGroupsTable;
