/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {
  BaseAdminUserVerification,
  IdDocSubType,
  IdDocType,
  VerificationDocument,
  VerificationLevel,
  VerificationStatus,
} from 'api/auth';

export class KycVerificationModel {
  constructor (dto: BaseAdminUserVerification) {
    this.id = dto.id ?? '';
    this.status = dto.status;
    this.type = dto.type;
    this.externalId = (dto as any).externalId;
    this.level = dto.level;
  }

  id: string;
  status?: VerificationStatus;
  type: BaseAdminUserVerification extends { type: infer T } ? T : never;
  externalId?: string;
  level?: VerificationLevel;
}

export class KycVerificationFileModel {
  constructor (dto: VerificationDocument, verificationId: string) {
    this.external_id = dto.external_id ?? '';
    this.file_type = dto.file_type;
    this.filename = dto.filename;
    this.id = dto.id ?? '';
    this.size = dto.size;
    this.status = dto.status;
    this.sub_type = dto.sub_type;
    this.type = dto.type;
    this.verificationId = verificationId;
  }

  external_id: string;
  file_type?: string;
  filename?: string;
  id: string;
  size?: number;
  status: VerificationStatus;
  sub_type?: IdDocSubType;
  type: IdDocType;
  verificationId: string;
}
