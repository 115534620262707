/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { createContext, ReactNode, useContext, useEffect } from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';

import { useBaseStores } from 'providers/BaseStoresProvider';
import { useParseCollectionQueryParams } from 'hooks/collection-query-params';
import { LimitsPageStore } from '../store/LimitsPageStore';

const storeContext = createContext<LimitsPageStore>({} as LimitsPageStore);

type Props = {
  children?: ReactNode;
};

export const ProvideLimitsPageStore = observer(({ children }: Props) => {
  const { authApi, notificationStore, partnerStore } = useBaseStores();

  const store = useLocalObservable(() => new LimitsPageStore(authApi, notificationStore));

  const { page, filter } = useParseCollectionQueryParams({
    collection: store.limits,
  });

  useEffect(() => {
    if (!partnerStore.currentPartner?.id) {
      return;
    }

    void store.init({
      partnerId: partnerStore.currentPartner.id,
      filter: filter,
      page: page,
    });
  }, [filter, page, store, partnerStore.currentPartner?.id]);

  return (
    <storeContext.Provider value={store}>{children}</storeContext.Provider>
  );
});

export const useLimitsPageStore = () => {
  return useContext(storeContext);
};
