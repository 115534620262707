/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import joi from 'joi';

import styles from './index.module.css';
import Button from 'components/core/button';
import FormListSingleSelect from 'components/form/form-list-single-select';

import { useBaseStores } from 'providers/BaseStoresProvider';
import { useIntlValidation } from 'hooks/intl/validation';
import { VerificationLevel } from 'api/auth';
import { UserModel } from 'models/UserModel';
import { SelectItemModel } from 'models/form/SelectItemModel';

export type ChangeKycLevelForm = {
  kycLevel: SelectItemModel<string>;
}

type Props = {
  userDetails: UserModel | null;
  onUpdate: (data: UserModel) => Promise<void>;
}

const SidebarKycLevelChange = observer((props: Props) => {
  const { userDetails, onUpdate } = props;
  const { formatMessage } = useIntl();
  const { validationOptions } = useIntlValidation();
  const { layoutStore, notificationStore } = useBaseStores();

  const [isLoading, setIsLoading] = useState(false);

  const levelOptions = [
    {
      id: VerificationLevel.OTHER,
      label: formatMessage({
        id: 'sidebar.change_kyc_level.without_level',
        defaultMessage: 'Initial',
      }),
      value: VerificationLevel.OTHER,
    },
    {
      id: VerificationLevel.L1,
      label: VerificationLevel.L1,
      value: VerificationLevel.L1,
    },
    {
      id: VerificationLevel.L2,
      label: VerificationLevel.L2,
      value: VerificationLevel.L2,
    },
  ];

  const formScheme = useMemo(
    () =>
      joi.object<ChangeKycLevelForm>({
        kycLevel: joi.object().required(),
      }),
    [],
  );

  const form = useForm<ChangeKycLevelForm>({
    resolver: joiResolver(formScheme, validationOptions),
    mode: 'onChange',
    defaultValues: {
      kycLevel: userDetails?.kycLevel
        ? levelOptions.find(({ value }) => value === userDetails.kycLevel)
        : levelOptions[0],
    },
  });

  const { control, formState, handleSubmit } = form;
  const { isValid } = formState;

  const cancelHandler = () => {
    layoutStore.toggleSidebar(false);
  };

  const changeKyc: SubmitHandler<ChangeKycLevelForm> = async (values) => {
    if (!userDetails) return;

    const mappedValues = {
      ...userDetails,
      kycLevel: values.kycLevel.value as unknown as VerificationLevel,
    };

    setIsLoading(true);
    try {
      await onUpdate(mappedValues);
      notificationStore.notify({
        title: formatMessage({
          id: 'sidebar.change_kyc_level.update_success',
          defaultMessage: 'KYC level updated successfully',
        }),
        type: 'success',
      });
      layoutStore.toggleSidebar(false);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.main}>
      <div className={styles.fields}>
        <Controller
          control={control}
          name="kycLevel"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <FormListSingleSelect
              title={formatMessage({
                id: 'sidebar.change_kyc_level.kyc_level',
                defaultMessage: 'Indicate the client\'s future level',
              })}
              options={levelOptions}
              selected={value}
              onSelect={onChange}
            />
          )}
        />
      </div>

      <div className={styles.actions}>
        <Button
          variant="secondary"
          onClick={() => cancelHandler()}
        >
          {formatMessage({
            id: 'sidebar.change_kyc_level.cancel',
            defaultMessage: 'Cancel',
          })}
        </Button>
        <Button
          variant="primary"
          disabled={isLoading || !isValid}
          onClick={handleSubmit(changeKyc)}
        >
          {formatMessage({
            id: 'sidebar.change_kyc_level.change',
            defaultMessage: 'Change level',
          })}
        </Button>
      </div>
    </div>
  );
});

export const useSidebarChangeKycLevel = (
  { onUpdate }:
  { onUpdate: (data: UserModel) => Promise<void> },
) => {
  const { formatMessage, locale } = useIntl();
  const { layoutStore } = useBaseStores();

  const showSidebar = useCallback(
    (userDetails: UserModel | null) => {
      layoutStore.toggleSidebar(
        true,
        formatMessage({
          id: 'sidebar.change_kyc_level.title',
          defaultMessage: 'Change KYC level',
        }),
        <SidebarKycLevelChange
          userDetails={userDetails}
          onUpdate={onUpdate}
        />,
      );
    },
    [locale, layoutStore, onUpdate],
  );

  return {
    showKycLevelChangeSidebar: showSidebar,
  };
};
