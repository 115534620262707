/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {
  Quest,
  UserQuestStatusDto,
  TriggeredEvent,
  AdminUserQuest,
  PlatformTypeDto,
} from 'api/quests';

export class UserEventsQuestsModel {
  constructor(
    dto: Omit<AdminUserQuest, 'triggeredEvents'> & {
      triggeredEvent: TriggeredEvent;
    }
  ) {
    this.id = dto.userId ?? '';
    this.quest = dto.quest;
    this.userId = dto.userId ?? '';
    this.questUrl = dto.questUrl;
    this.status = dto.status;
    this.triggeredEvent = dto.triggeredEvent;
  }

  quest?: Quest;
  /** @format uuid */
  userId?: string;
  id: string;
  questUrl?: string;
  status?: UserQuestStatusDto;
  triggeredEvent?: TriggeredEvent;
}

type AdditionalUserInfo = {
  platforms?: PlatformTypeDto[];
  userEmail?: string;
  userAge?: string;
  userGender?: string;
};

export class UserQuestsModel {
  constructor(
    dto: AdminUserQuest &
      AdditionalUserInfo & { total_steps?: number; executed_steps?: number }
  ) {
    this.id = dto.userId ?? '';
    this.quest = dto.quest;
    this.userId = dto.userId ?? '';
    this.questUrl = dto.questUrl;
    this.status = dto.status;
    this.triggeredEvents = dto.triggeredEvents;

    this.startedAt = dto.startedAt ?? '';
    this.finishedAt = dto.finishedAt ?? '';
    this.duration = dto.duration ?? 0;
    this.total_steps = dto.total_steps ?? 0;
    this.executed_steps = dto.executed_steps ?? 0;

    this.platforms = dto.platforms ?? [];
    this.userEmail = dto.userEmail ?? '';
    this.userAge = dto.userAge ?? '';
    this.userGender = dto.userGender ?? '';
  }

  quest?: Quest;
  /** @format uuid */
  userId?: string;
  id: string;
  questUrl?: string;
  status?: UserQuestStatusDto;
  triggeredEvents?: TriggeredEvent[];

  startedAt?: string;
  finishedAt?: string;
  duration?: number;
  total_steps?: number;
  executed_steps?: number;

  platforms?: PlatformTypeDto[];
  userEmail?: string;
  userAge?: string;
  userGender?: string;
}
