/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { FaPen } from 'react-icons/fa6';
import type { TableProps } from 'antd';

import {
  useSidebarCreateLimit,
} from 'components/sidebars/sidebar-create-limit';
import Table from 'components/core/table';

import { Limit } from 'api/auth';
import { CollectionWithPages } from 'stores/collection/CollectionWithPages';
import { LimitModel } from 'models/LimitModel';
import { AdminGroupModel } from 'models/GroupModel';
import { OperationGroupModel } from 'models/OperationGroupModel';

type Props = {
  collection: CollectionWithPages<LimitModel, {}>;
  userGroups: AdminGroupModel[],
  operationGroups: OperationGroupModel[];
  onUpdate: (limitId: number, data: Limit) => Promise<void>
  onDelete: (limitId: number) => Promise<void>;
};

const LimitsTable = observer((props: Props) => {
  const { collection, userGroups, operationGroups, onUpdate, onDelete } = props;
  const { formatMessage } = useIntl();

  const columns: TableProps<LimitModel>['columns'] = [
    {
      title: formatMessage({
        id: 'table.limits.col.id',
        defaultMessage: 'ID',
      }),
      dataIndex: 'id',
      key: 'id',
      render: (id: string) => <span>{id}</span>,
      width: 20,
    },
    {
      title: formatMessage({
        id: 'table.limits.col.operation_group_id',
        defaultMessage: 'Operation group ID',
      }),
      dataIndex: 'operationGroupId',
      key: 'operationGroupId',
      render: (operationGroupId: string) => <span>{operationGroupId}</span>,
      width: 20,
    },
    {
      title: formatMessage({
        id: 'table.limits.col.currency',
        defaultMessage: 'Currency',
      }),
      dataIndex: 'currency',
      key: 'currency',
      render: (currency: string) => <span>{currency}</span>,
    },
    {
      title: formatMessage({
        id: 'table.limits.col.day_limit',
        defaultMessage: 'Day',
      }),
      dataIndex: 'dayLimit',
      key: 'dayLimit',
      render: (day: number) => <span>{day}</span>,
    },
    {
      title: formatMessage({
        id: 'table.limits.col.month_limit',
        defaultMessage: 'Month',
      }),
      dataIndex: 'monthLimit',
      key: 'monthLimit',
      render: (month: number) => <span>{month}</span>,
    },
    {
      title: formatMessage({
        id: 'table.limits.col.year_limit',
        defaultMessage: 'Year',
      }),
      dataIndex: 'yearLimit',
      key: 'yearLimit',
      render: (year: number) => <span>{year}</span>,
    },
    {
      title: formatMessage({
        id: 'table.limits.col.global_limit',
        defaultMessage: 'Global',
      }),
      dataIndex: 'globalLimit',
      key: 'globalLimit',
      render: (global: number) => <span>{global}</span>,
    },
    {
      title: formatMessage({
        id: 'table.limits.col.user_groups',
        defaultMessage: 'User groups',
      }),
      dataIndex: 'groupNames',
      key: 'groupNames',
      render: (groupNames: string[]) => (
        <div className="flex flex-wrap gap-2">
          {groupNames?.map((id) => (
            <span className={`rounded-md p-1 border border-base-80 border-solid`}>
            {id}
          </span>
          ))}
        </div>
      ),
    },
    {
      title: formatMessage({
        id: 'table.limits.col.action',
        defaultMessage: 'Action',
      }),
      key: 'action',
      render: () => (
        <button>
          <FaPen />
        </button>
      ),
      width: 20,
    },
  ];

  const { showCreateLimitSidebar } = useSidebarCreateLimit({
    onUpdate,
    onDelete,
  });

  return (
    <Table<LimitModel, {}>
      collection={collection}
      columns={columns}
      onRowClick={(item) => showCreateLimitSidebar(
        userGroups,
        operationGroups,
        item,
      )}
    />
  );
});

export default LimitsTable;
