/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { useCallback, useMemo } from 'react';
import { countries as countriesList, TCountryCode } from 'countries-list';
import countries2to3 from 'countries-list/minimal/countries.2to3.min.json';

import { Country } from 'models/countries/CountryModel';

export const useCountries = () => {
  const countriesMapped = useMemo(() => {
    const newList = {} as Record<TCountryCode, Country>;

    Object.entries(countriesList).forEach(([key, country]) => {
      const alpha2Code = key as TCountryCode;
      const alpha3Code = countries2to3[alpha2Code];

      newList[alpha2Code] = {
        alpha2Code,
        alpha3Code,
        name: country.name,
        flagClassName: `fi fi-${alpha2Code.toLowerCase()} fis`,
      };
    });

    return newList;
  }, []);

  const countriesArray = Object.values(countriesMapped)
                               .sort((a, b) => a.name.localeCompare(b.name));

  const getCountryNameByAlpha3 = useCallback(
    (alpha3Code: string) => {
      const country = countriesArray.find((country) => country.alpha3Code === alpha3Code);
      return country?.name ?? undefined;
    },
    [countriesArray],
  );

  return { countries: countriesMapped, countriesArray, getCountryNameByAlpha3 };
};
