/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { useIntlUserVerificationStatuses } from 'hooks/intl/user-verification-statuses';
import { observer } from 'mobx-react-lite';
import { KycVerificationModel } from 'models/KycVerificationModel';
import React from 'react';
import { useIntl } from 'react-intl';
import { CollectionWithPages } from 'stores/collection/CollectionWithPages';
import { cutId } from 'utils/strings';
import Table from 'components/core/table';
import type { TableProps } from 'antd';
import { UserVerificationStatus } from '../../models/UserModel';
import Button from 'components/core/button';
import { ReactComponent as ExternalLinkIcon } from 'assets/images/icons/external-link.svg';

type Props = {
  collection: CollectionWithPages<KycVerificationModel, {}>;
  onSyncVerification: (id: string) => void;
};

const KycVerificationsTable = observer(
  ({ collection, onSyncVerification }: Props) => {
    const intl = useIntl();

    const { verificationStatusToIntlText } = useIntlUserVerificationStatuses();

    const columns: TableProps<KycVerificationModel>['columns'] = [
      {
        title: intl.formatMessage({
          id: 'table.kyc_verfications.col.id',
          defaultMessage: 'Id',
        }),
        dataIndex: 'id',
        key: 'id',
        render: (id: string) => <span>{cutId(id)}</span>,
        width: 60,
      },
      {
        title: intl.formatMessage({
          id: 'table.kyc_verfications.col.type',
          defaultMessage: 'Type',
        }),
        dataIndex: 'type',
        key: 'type',
        render: (type: string) => <span>{type}</span>,
        width: 30,
      },
      {
        title: intl.formatMessage({
          id: 'table.kyc_verfications.col.link',
          defaultMessage: 'Link',
        }),
        dataIndex: 'externalId',
        key: 'externalId',
        render: (externalId: number, record) =>
          record.type === 'SUMSUB' ? (
            <a
              href={`https://cockpit.sumsub.com/checkus/#/applicant/${externalId}`}
              target='_blank'
              rel='noreferrer'
              style={{ display: 'flex', alignItems: 'center', gap: 4 }}
            >
              <span>Link</span>
              <ExternalLinkIcon />
            </a>
          ) : (
            <span>-</span>
          ),
        width: 20,
      },
      {
        title: intl.formatMessage({
          id: 'table.kyc_verfications.col.status',
          defaultMessage: 'Status',
        }),
        dataIndex: 'status',
        key: 'status',
        render: (status: UserVerificationStatus) => (
          <span>{status ? verificationStatusToIntlText(status) : ''}</span>
        ),
        width: 20,
      },
      {
        title: intl.formatMessage({
          id: 'table.kyc_verfications.col.actions',
          defaultMessage: 'Actions',
        }),
        dataIndex: 'id',
        key: 'action',
        render: (id: string) => (
          <Button
            variant='primary'
            size='small'
            onClick={() => onSyncVerification(id)}
          >
            {intl.formatMessage({
              id: 'table.kyc_verfications.col.synchronize',
              defaultMessage: 'Synchronize KYC status',
            })}
          </Button>
        ),
        width: 20,
      },
    ];

    return (
      <Table<KycVerificationModel, {}>
        collection={collection}
        columns={columns}
      />
    );
  }
);

export default KycVerificationsTable;
