/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import joi from 'joi';
import { Select } from 'antd';

import styles from './index.module.css';
import Button from 'components/core/button';
import FormLabel from 'components/form/form-label';
import FormField from 'components/form/form-field';
import FormSelect from 'components/form/form-select';
// import FormMultiSelect from 'components/form/form-multi-select';

import { OperationGroupDto, OperationGroupType } from 'api/auth';
import { useBaseStores } from 'providers/BaseStoresProvider';
import { useIntlValidation } from 'hooks/intl/validation';
import { OperationModel } from 'models/OperationModel';
import { SelectItemModel } from 'models/form/SelectItemModel';
import { OperationGroupModel } from 'models/OperationGroupModel';

export type CreateOperationGroupForm = {
  name: string;
  groupType: OperationGroupType;
  operations: SelectItemModel<string>[];
}

type Props = {
  group?: OperationGroupModel;
  operations: OperationModel[];
  onCreate?: (group: OperationGroupDto) => Promise<void>;
  onUpdate?: (operationGroupId: number, data: OperationGroupDto) => Promise<void>
  onDelete?: (operationGroupId: number) => Promise<void>;
}

const SidebarOperationGroupCreate = observer((props: Props) => {
  const { group, operations, onCreate, onUpdate, onDelete } = props;
  const { formatMessage } = useIntl();
  const { validationOptions } = useIntlValidation();
  const { layoutStore } = useBaseStores();

  const isCreate = !group;
  const [isLoading, setIsLoading] = useState(false);

  const formScheme = useMemo(
    () =>
      joi.object<CreateOperationGroupForm>({
        name: joi.string().empty('').required(),
        groupType: joi.string().required(),
        operations: joi.array().min(1).required(),
      }),
    [],
  );

  const form = useForm<CreateOperationGroupForm>({
    resolver: joiResolver(formScheme, validationOptions),
    mode: 'onChange',
    defaultValues: group
      ? {
        name: group.name,
        groupType: group.groupType as OperationGroupType,
        operations: group.operations.map(({ id, name }) => ({
          id: String(id),
          label: name,
          value: String(id),
        })),
      }
      : {},
  });

  const { control, formState, handleSubmit } = form;
  const { isValid } = formState;

  const createGroup: SubmitHandler<CreateOperationGroupForm> = async (values) => {
    if (group || !onCreate) return;

    const mappedValues = {
      ...values,
      operations: values.operations.map(item => Number(item)),
    };

    setIsLoading(true);
    try {
      await onCreate(mappedValues);
      layoutStore.toggleSidebar(false);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const editGroup: SubmitHandler<CreateOperationGroupForm> = async (values) => {
    if (!group || !onUpdate) return;

    const mappedValues = {
      ...values,
      operations: typeof values.operations[0] === 'string'
        ? values.operations.map(item => Number(item))
        : values.operations.map(item => Number(item.value)),
    };

    setIsLoading(true);
    try {
      await onUpdate(Number(group.id), mappedValues);
      layoutStore.toggleSidebar(false);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteGroup = async () => {
    if (!group || !onDelete) return;

    setIsLoading(true);
    try {
      await onDelete(Number(group.id));
      layoutStore.toggleSidebar(false);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const groupTypeOptions = Object.values(OperationGroupType).map((type) => {
    return { id: type, label: type, value: type };
  });

  const operationOptions = operations.map((operation) => {
    const { id, name } = operation;
    return { id, label: name, value: id };
  });

  return (
    <div className={styles.main}>
      <div className={styles.fields}>
        <Controller
          control={control}
          name="name"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <FormField
              variant="secondary"
              label={formatMessage({
                id: 'sidebar.operation_group_details.name',
                defaultMessage: 'Name',
              })}
              placeholder={formatMessage({
                id: 'sidebar.operation_group_details.name.placeholder',
                defaultMessage: 'Enter operation group name',
              })}
              value={value}
              onChange={onChange}
              error={error?.message}
            />
          )}
        />

        <Controller
          control={control}
          name="groupType"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <FormSelect
              variant="borderless"
              label={formatMessage({
                id: 'sidebar.operation_group_details.group_type',
                defaultMessage: 'Group type',
              })}
              placeholder={formatMessage({
                id: 'sidebar.operation_group_details.group_type.placeholder',
                defaultMessage: 'Choose operation group type',
              })}
              options={groupTypeOptions}
              value={groupTypeOptions.find((item) => item.value === value)}
              onChange={(value) => onChange(value?.value)}
              error={error?.message}
            />
          )}
        />

        {/*TODO: rework field below for using FormMultiSelect component*/}
        <Controller
          control={control}
          name="operations"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <div className={styles['multi-select-field']}>
              <FormLabel
                text={formatMessage({
                  id: 'sidebar.operation_group_details.operations',
                  defaultMessage: 'Operations',
                })}
              />
              <Select
                mode="multiple"
                showSearch={false}
                size="large"
                placeholder={formatMessage({
                  id: 'sidebar.operation_group_details.operations.placeholder',
                  defaultMessage: 'Choose operations',
                })}
                options={operationOptions}
                value={value}
                onChange={onChange}
                // error={error?.message}
              />
            </div>
          )}
        />
      </div>

      {isCreate ? (
        <Button
          variant="primary"
          disabled={isLoading || !isValid}
          onClick={handleSubmit(createGroup)}
        >
          {formatMessage({
            id: 'sidebar.operation_group_details.create',
            defaultMessage: 'Create operation group',
          })}
        </Button>
      ) : (
        <div className={styles.actions}>
          <Button
            variant="negative"
            disabled={isLoading}
            // TODO: add modal for confirm deletion
            onClick={() => deleteGroup()}
          >
            {formatMessage({
              id: 'sidebar.operation_group_details.delete',
              defaultMessage: 'Delete operation group',
            })}
          </Button>
          <Button
            variant="primary"
            disabled={isLoading || !isValid}
            onClick={handleSubmit(editGroup)}
          >
            {formatMessage({
              id: 'sidebar.operation_group_details.edit',
              defaultMessage: 'Save changes',
            })}
          </Button>
        </div>
      )}
    </div>
  );
});

export const useSidebarCreateOperationGroup = (
  { operations, onCreate, onUpdate, onDelete }:
  {
    operations: OperationModel[];
    onCreate?: (group: OperationGroupDto) => Promise<void>;
    onUpdate?: (operationGroupId: number, data: OperationGroupDto) => Promise<void>
    onDelete?: (operationGroupId: number) => Promise<void>;
  },
) => {
  const { formatMessage, locale } = useIntl();
  const { layoutStore } = useBaseStores();

  const showSidebar = useCallback(
    (group?: OperationGroupModel) => {
      layoutStore.toggleSidebar(
        true,
        formatMessage(group?.name
            ? {
              id: 'sidebar.operation_group_details.title',
              defaultMessage: '{name}',
            }
            : {
              id: 'sidebar.operation_group_details.create_title',
              defaultMessage: 'Create operation group',
            },
          group?.name ? { name: group.name } : undefined,
        ),
        <SidebarOperationGroupCreate
          group={group}
          operations={operations}
          onCreate={onCreate}
          onUpdate={onUpdate}
          onDelete={onDelete}
        />,
      );
    },
    [locale, layoutStore, operations, onCreate, onUpdate, onDelete],
  );

  return {
    showCreateOperationGroupSidebar: showSidebar,
  };
};
