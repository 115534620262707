/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';
import { useFormContext, useWatch } from 'react-hook-form';
import { Popover, TableProps } from 'antd';

import styles from './index.module.css';
import Button from 'components/core/button';
import FormLabel from 'components/form/form-label';
import TableSimple from 'components/core/table-simple';
import { GroupsModal } from './groups-modal';

import { CreateLimitForm } from '../index';
import { AdminGroupModel } from 'models/GroupModel';

type Props = {
  userGroups: AdminGroupModel[],
};

export const UserGroups: FC<Props> = observer(({ userGroups }) => {
  const { formatMessage } = useIntl();

  const {
    control,
    formState,
    getValues,
    reset,
    setValue,
  } = useFormContext<CreateLimitForm>();
  const groupsCollection = useWatch({ control, name: 'groupIds' });

  // const all = useWatch<CreateLimitForm>({ control });
  // console.log(all);

  const [isOpen, setIsOpen] = useState(false);
  const [rows, setRows] = useState<string[]>([]);

  const resetForm = () => {
    reset();
  };

  const deleteGroups = () => {
    const groups = getValues('groupIds');
    const filtered = groups.filter((group) => !rows.includes(group.id));

    setValue('groupIds', filtered, {
      shouldTouch: true,
      shouldDirty: true,
    });
    setRows([]);
  };

  const columns: TableProps<AdminGroupModel>['columns'] = [
    {
      title: formatMessage({
        id: 'table.limit_details.col.id',
        defaultMessage: 'ID',
      }),
      dataIndex: 'id',
      key: 'id',
      render: (id: string) => <span>{id}</span>,
    },
    {
      title: formatMessage({
        id: 'table.limit_details.col.name',
        defaultMessage: 'Name',
      }),
      dataIndex: 'name',
      key: 'name',
      render: (name: string) => <span>{name}</span>,
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <FormLabel
          text={formatMessage({
            id: 'sidebar.limit_details.user_groups',
            defaultMessage: 'User groups',
          })}
        />

        <div className={styles.buttons}>
          <Button
            variant="secondary"
            onClick={resetForm}
            disabled={!formState.isDirty}
          >
            {formatMessage({
              id: 'sidebar.limit_details.user_groups.reset',
              defaultMessage: 'Reset form',
            })}
          </Button>

          <Button
            variant="secondary"
            onClick={deleteGroups}
            disabled={!rows.length}
          >
            {formatMessage({
              id: 'sidebar.limit_details.user_groups.delete',
              defaultMessage: 'Delete group',
            })}
          </Button>

          <Popover
            content={<GroupsModal isOpen={isOpen} userGroups={userGroups }/>}
            trigger="click"
            placement="bottomRight"
            arrow={false}
            open={isOpen}
            onOpenChange={(v) => setIsOpen(v)}
            destroyTooltipOnHide
          >
            <Button variant="primary">
              {formatMessage({
                id: 'sidebar.limit_details.user_groups.add',
                defaultMessage: 'Add group',
              })}
            </Button>
          </Popover>
        </div>
      </div>

      <TableSimple<AdminGroupModel>
        className={styles.table}
        collection={groupsCollection as AdminGroupModel[]}
        columns={columns}
        rowKey="id"
        scroll={{ y: '400px' }}
        rowSelection={{
          type: 'checkbox',
          selectedRowKeys: rows,
          onChange: (selectedRowKeys) => {
            console.log(selectedRowKeys);
            setRows(selectedRowKeys as string[]);
          },
        }}
      />
    </div>
  );
});
