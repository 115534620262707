/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { Operation, OperationGroup, OperationGroupType } from 'api/auth';

export class OperationGroupModel {
  constructor (dto: OperationGroup) {
    this.groupType = dto.groupType;
    this.id = dto.id?.toString() ?? '';
    this.name = dto.name;
    this.operations = dto.operations;
  }

  groupType: OperationGroupType;
  id: string;
  name: string;
  operations: Operation[];
}
