/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';

import styles from './index.module.css';
import OperationGroupsTable from 'components/tables/operation-groups-table';

import {
  useOperationGroupsPageStore,
} from '../provider/OperationGroupsPageProvider';
import { useBaseStores } from 'providers/BaseStoresProvider';

export const OperationGroupsTab = observer(() => {
  const { formatMessage, locale } = useIntl();
  const { layoutStore } = useBaseStores();
  const operationGroupsPageStore = useOperationGroupsPageStore();

  const totalOperationGroups = useMemo(
    () =>
      operationGroupsPageStore.operationGroups.totalItems > 0
        ? formatMessage(
          {
            id: 'page.operation_groups.total',
            defaultMessage: 'Total {groups}',
          },
          {
            groups: (
              <span className={styles.count}>
                {formatMessage(
                  {
                    id: 'page.operation_groups.total.count',
                    defaultMessage: '{count} operation groups',
                  },
                  { count: operationGroupsPageStore.operationGroups.totalItems },
                )}
              </span>
            ),
          },
        )
        : null,
    [locale, operationGroupsPageStore.operationGroups.totalItems],
  );

  useEffect(() => {
    layoutStore.setNavbarTitle(
      formatMessage({
        id: 'page.operation_groups.title',
        defaultMessage: 'Operation groups',
      }),
    );

    return () => layoutStore.setNavbarTitle(null);
  }, [locale, layoutStore]);

  useEffect(() => {
    layoutStore.setNavbarContent(
      <div className={styles.total}>{totalOperationGroups}</div>,
    );

    return () => layoutStore.setNavbarContent(null);
  }, [locale, layoutStore, totalOperationGroups]);

  return (
    <OperationGroupsTable
      collection={operationGroupsPageStore.operationGroups}
      operations={operationGroupsPageStore.operations}
      onUpdate={operationGroupsPageStore.updateOperationGroup}
      onDelete={operationGroupsPageStore.deleteOperationGroup}
    />
  );
});
