/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { Operation } from 'api/auth';

export class OperationModel {
  constructor (dto: Operation) {
    this.currencyType = dto.currencyType;
    this.description = dto.description;
    this.executionMethod = dto.executionMethod;
    this.id = dto.id?.toString() ?? '';
    this.name = dto.name;
    this.recipientType = dto.recipientType;
    this.type = dto.type;
  }

  currencyType: string;
  description?: string;
  executionMethod: string;
  id: string;
  name: string;
  recipientType: string;
  type: string;
}
