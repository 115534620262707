/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { CompanyInfo, IdDocType, VerificationDocument } from 'api/auth';

export class KybVerificationModel {
  constructor(dto: CompanyInfo) {
    this.id = dto.id ?? '';
    this.type = dto.type;
    this.meta = dto.meta ? JSON.stringify(dto.meta) : '';
    this.verifications = dto.verifications;
    this.taxId = dto.taxId;
    this.registrationNumber = dto.registrationNumber;
    this.registrationLocation = dto.registrationLocation;
    this.postalAddress = dto.postalAddress;
    this.phone = dto.phone;
    this.legalAddress = dto.legalAddress;
    this.individuals =
      dto.individuals.length > 0 ? JSON.stringify(dto.individuals) : '';
    this.incorporatedOn = dto.incorporatedOn;
    this.country = dto.country;
    this.email = dto.email;
    this.controlScheme = dto.controlScheme;
    this.companyName = dto.companyName;
    this.companyInfo = dto;
  }

  id: string;
  type?: string;
  meta?: string;
  verifications: string[];
  taxId?: string;
  registrationNumber: string;
  registrationLocation?: string;
  postalAddress?: string;
  phone?: string;
  legalAddress?: string;
  individuals?: string;
  incorporatedOn?: string;
  country?: string;
  email?: string;
  controlScheme?: string;
  companyName: string;
  companyInfo: CompanyInfo;
}

export class KybVerificationFileModel {
  constructor(dto: VerificationDocument, verificationId: string) {
    this.id = dto.id ?? '';
    this.filename = dto.filename;
    this.size = dto.size;
    this.file_type = dto.file_type;
    this.verificationId = verificationId;
    this.status = dto.status;
    this.sub_type = dto.sub_type;
    this.type = dto.type;
    this.external_id = dto.external_id ?? '';
  }

  id: string;
  status: string;
  verificationId: string;
  filename?: string;
  size?: number;
  file_type?: string;
  sub_type?: string;
  type: IdDocType;
  external_id: string;
}
