/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import styles from './index.module.css';
import { LimitsTab } from './limits-tab';
import { LimitsFilter } from './limits-filter';
import { OperationGroupsTab } from './operation-groups-tab';
import { OperationGroupsFilter } from './operation-groups-filter';

import { ProvideLimitsPageStore } from './provider/LimitsPageProvider';
import {
  ProvideOperationGroupsPageStore,
} from './provider/OperationGroupsPageProvider';
import { ROUTES } from 'routes';

const LimitsPageWithProvider = observer(() => {
  return (
    <div className={styles.main}>
      <LimitsFilter />
      <LimitsTab />
    </div>
  );
});

const OperationGroupsPageWithProvider = observer(() => {
  return (
    <div className={styles.main}>
      <OperationGroupsFilter />
      <OperationGroupsTab />
    </div>
  );
});

const LimitsPage: FC = () => {
  return (
    <Routes>
      <Route
        element={
          <ProvideLimitsPageStore>
            <LimitsPageWithProvider />
          </ProvideLimitsPageStore>
        }
        index
      />
      <Route
        path={ROUTES.limits.operationGroups.path}
        element={
          <ProvideOperationGroupsPageStore>
            <OperationGroupsPageWithProvider />
          </ProvideOperationGroupsPageStore>
        }
      />
    </Routes>
  );
};

export default LimitsPage;
