/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { FC } from 'react';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';
import { Steps } from 'antd';

import styles from './index.module.css';
import {
  ReactComponent as ArrowRightIconSvg,
} from 'assets/images/icons/arrow-right.svg';
import {
  useSidebarChangeKycLevel,
} from 'components/sidebars/sidebar-change-kyc-level';
import Button from 'components/core/button';

import { VerificationLevel } from 'api/auth';
import { useUserDetailsPageStore } from '../../../provider/StoreProvider';

export const KycInfo: FC = observer(() => {
  const { formatMessage } = useIntl();
  const userDetailsPageStore = useUserDetailsPageStore();
  const { details, save } = userDetailsPageStore;

  const { showKycLevelChangeSidebar } = useSidebarChangeKycLevel({
    onUpdate: save
  });

  // TODO: activity table logic not implemented on backend
  // const columns: TableProps<any>['columns'] = [
  //   {
  //     title: formatMessage({
  //       id: 'table',
  //       defaultMessage: 'Date',
  //     }),
  //     // dataIndex: 'date',
  //     key: 'date',
  //     render: () => <span>04/23/2024</span>,
  //   },
  //   {
  //     title: formatMessage({
  //       id: 'table',
  //       defaultMessage: 'Desired KYC level',
  //     }),
  //     // dataIndex: 'kycLevel',
  //     key: 'kycLevel',
  //     render: () => <span>2</span>,
  //   },
  //   {
  //     title: formatMessage({
  //       id: 'table',
  //       defaultMessage: 'Status',
  //     }),
  //     // dataIndex: 'status',
  //     key: 'status',
  //     render: () => <span>Pending</span>,
  //   },
  //   {
  //     title: formatMessage({
  //       id: 'table',
  //       defaultMessage: 'Date & time',
  //     }),
  //     // dataIndex: 'dateTime',
  //     key: 'dateTime',
  //     render: () => <span>04/23/2024 • 02:45 AM</span>,
  //   },
  //   {
  //     title: formatMessage({
  //       id: 'table',
  //       defaultMessage: 'Sumsub',
  //     }),
  //     // dataIndex: 'sumsub',
  //     key: 'sumsub',
  //     render: () => <span>Sumsub</span>,
  //   },
  // ];
  // const collection: unknown = {
  //   pagination: {
  //     maxPage: 1,
  //   },
  //   items: [{}],
  // };

  const steps = [
    {
      title: formatMessage({
        id: 'sidebar.change_kyc_level.without_level',
        defaultMessage: 'Initial',
      }),
      value: VerificationLevel.OTHER,
    },
    {
      title: VerificationLevel.L1,
      value: VerificationLevel.L1,
    },
    {
      title: VerificationLevel.L2,
      value: VerificationLevel.L2,
    },
  ];

  const foundIndex = steps.findIndex(step => step.value === details?.kycLevel);
  const currentStep = foundIndex !== -1 ? foundIndex : 0;

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>
        {formatMessage({
          id: 'page.user_details.kyc.kyc_info',
          defaultMessage: 'KYC information',
        })}
      </h2>

      <div className={styles.card}>
        <div className={styles['level-container']}>
          <div className={styles['level-title']}>
            <h2>
              {formatMessage({
                id: 'page.user_details.kyc.kyc_info.level',
                defaultMessage: 'KYC level',
              })}
            </h2>

            <Button
              variant="secondary"
              onClick={() => showKycLevelChangeSidebar(details)}
              size="small"
            >
              <span>
                {formatMessage({
                  id: 'page.user_details.kyc.kyc_info.change_level',
                  defaultMessage: 'Change level',
                })}
              </span>
              <ArrowRightIconSvg />
            </Button>
          </div>

          <div className={styles['level-stepper']}>
            <Steps
              progressDot
              labelPlacement="vertical"
              current={currentStep}
              items={steps}
            />
          </div>
        </div>

        {/*TODO: activity table logic not implemented on backend*/}
        {/*<div className={styles['activity-container']}>*/}
        {/*  <h2>*/}
        {/*    {formatMessage({*/}
        {/*      id: 'page.user_details.kyc.kyc_info.activity',*/}
        {/*      defaultMessage: 'KYC activity',*/}
        {/*    })}*/}
        {/*  </h2>*/}

        {/*  <Table<any, {}>*/}
        {/*    collection={collection}*/}
        {/*    columns={columns}*/}
        {/*  />*/}
        {/*</div>*/}
      </div>
    </div>
  );
});
