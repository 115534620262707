/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';
import { FaPlus } from 'react-icons/fa6';

import styles from './index.module.css';
import Button from 'components/core/button';
import SearchField from 'components/core/search-field';
import { NavigationTab } from '../navigation-tabs';
import {
  useSidebarCreateLimit,
} from 'components/sidebars/sidebar-create-limit';

import { URL_SEARCH_PARAMS } from 'assets/config';
import { useSetQueryParams } from 'hooks/collection-query-params';
import { useLimitsPageStore } from '../provider/LimitsPageProvider';

export const LimitsFilter = observer(() => {
  const { formatMessage } = useIntl();
  const limitsPageStore = useLimitsPageStore();
  const { limits } = limitsPageStore;

  const { setFilterQueryParams } = useSetQueryParams();

  const handleSearch = useCallback(
    (searchTerm: string) => {
      setFilterQueryParams([
        { id: URL_SEARCH_PARAMS.page, value: '1' },
        { id: URL_SEARCH_PARAMS.search, value: searchTerm },
      ]);
    },
    [setFilterQueryParams],
  );

  const { showCreateLimitSidebar } = useSidebarCreateLimit({
    onCreate: limitsPageStore.createLimit,
  });

  return (
    <div className={styles.main}>
      <NavigationTab />

      <div className={styles.filters}>
        <SearchField
          className={styles.search_input}
          placeholder={formatMessage({
            id: 'page.limits.search.placeholder',
            defaultMessage: 'Search by limit',
          })}
          value={limits.filter.search ?? ''}
          onChange={handleSearch}
        />

        <Button
          variant="primary"
          onClick={() => showCreateLimitSidebar(
            limitsPageStore.userGroups,
            limitsPageStore.operationGroups,
          )}
        >
          <span>
            {formatMessage({
              id: 'page.limits.actions.create_limit',
              defaultMessage: 'Create limit',
            })}
          </span>
          <FaPlus />
        </Button>
      </div>
    </div>
  );
});
