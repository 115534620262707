/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';

import { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';

import LimitsTable from 'components/tables/limits-table';

import { useBaseStores } from 'providers/BaseStoresProvider';
import { useLimitsPageStore } from '../provider/LimitsPageProvider';

export const LimitsTab = observer(() => {
  const { formatMessage, locale } = useIntl();
  const { layoutStore } = useBaseStores();
  const limitsPageStore = useLimitsPageStore();

  const totalLimits = useMemo(
    () =>
      limitsPageStore.limits.totalItems > 0
        ? formatMessage(
          {
            id: 'page.limits.total',
            defaultMessage: 'Total {limits}',
          },
          {
            limits: (
              <span className={styles.count}>
                {formatMessage(
                  {
                    id: 'page.limits.total.count',
                    defaultMessage: '{count} limits',
                  },
                  { count: limitsPageStore.limits.totalItems },
                )}
              </span>
            ),
          },
        )
        : null,
    [locale, limitsPageStore.limits.totalItems],
  );

  useEffect(() => {
    layoutStore.setNavbarTitle(
      formatMessage({
        id: 'page.limits.title',
        defaultMessage: 'Limits',
      }),
    );

    return () => layoutStore.setNavbarTitle(null);
  }, [locale, layoutStore]);

  useEffect(() => {
    layoutStore.setNavbarContent(
      <div className={styles.total}>{totalLimits}</div>,
    );

    return () => layoutStore.setNavbarContent(null);
  }, [locale, layoutStore, totalLimits]);

  return (
    <LimitsTable
      collection={limitsPageStore.limits}
      userGroups={limitsPageStore.userGroups}
      operationGroups={limitsPageStore.operationGroups}
      onUpdate={limitsPageStore.updateLimit}
      onDelete={limitsPageStore.deleteLimit}
    />
  );
});
