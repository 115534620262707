/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import joi from 'joi';

import styles from './index.module.css';
import Button from 'components/core/button';
import { PlainFields } from './plain-fields';
import { UserGroups } from './user-groups';

import { Limit } from 'api/auth';
import { useBaseStores } from 'providers/BaseStoresProvider';
import { useIntlValidation } from 'hooks/intl/validation';
import { LimitModel } from 'models/LimitModel';
import { AdminGroupModel } from 'models/GroupModel';
import { OperationGroupModel } from 'models/OperationGroupModel';

export type CreateLimitForm = {
  currency: string;
  operationGroupId: number;
  dayLimit: number;
  monthLimit: number;
  yearLimit: number;
  globalLimit: number;
  groupIds: AdminGroupModel[];
  // userIds: string[];
}

type Props = {
  limit?: LimitModel;
  userGroups: AdminGroupModel[],
  operationGroups: OperationGroupModel[];
  onCreate?: (limit: Limit) => Promise<void>;
  onUpdate?: (limitId: number, data: Limit) => Promise<void>
  onDelete?: (limitId: number) => Promise<void>;
}

const SidebarLimitCreate = observer((props: Props) => {
  const {
    limit,
    userGroups,
    operationGroups,
    onCreate,
    onUpdate,
    onDelete,
  } = props;
  const { formatMessage } = useIntl();
  const { validationOptions } = useIntlValidation();
  const { layoutStore } = useBaseStores();

  const isCreate = !limit;
  const [isLoading, setIsLoading] = useState(false);

  const formScheme = useMemo(
    () =>
      joi.object<CreateLimitForm>({
        currency: joi.string().empty('').required(),
        operationGroupId: joi.number().empty('').required(),
        dayLimit: joi.number().empty('').min(0).max(Number.MAX_SAFE_INTEGER),
        monthLimit: joi.number().empty('').min(0).max(Number.MAX_SAFE_INTEGER),
        yearLimit: joi.number().empty('').min(0).max(Number.MAX_SAFE_INTEGER),
        globalLimit: joi.number().empty('').min(0).max(Number.MAX_SAFE_INTEGER),
        groupIds: joi.array().required(),
        // userIds: joi.array().required(),
      }),
    [],
  );

  const form = useForm<CreateLimitForm>({
    resolver: joiResolver(formScheme, validationOptions),
    mode: 'onChange',
    defaultValues: limit
      ? {
        currency: limit.currency,
        operationGroupId: limit.operationGroupId,
        dayLimit: limit.dayLimit,
        monthLimit: limit.monthLimit,
        yearLimit: limit.yearLimit,
        globalLimit: limit.globalLimit,
        groupIds: userGroups.filter((group) => limit.groupIds?.includes(Number(group.id))),
      }
      : {},
  });

  const { formState, handleSubmit } = form;
  const { isValid } = formState;

  const createLimit: SubmitHandler<CreateLimitForm> = async (values) => {
    if (limit || !onCreate) return;

    const mappedValues = {
      ...values,
      groupIds: values.groupIds.map(item => Number(item.id)),
    };

    setIsLoading(true);
    try {
      await onCreate(mappedValues);
      layoutStore.toggleSidebar(false);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const editLimit: SubmitHandler<CreateLimitForm> = async (values) => {
    if (!limit || !onUpdate) return;

    const mappedValues = {
      ...values,
      groupIds: values.groupIds.map(item => Number(item.id)),
    };

    setIsLoading(true);
    try {
      await onUpdate(Number(limit.id), mappedValues);
      layoutStore.toggleSidebar(false);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteLimit = async () => {
    if (!limit || !onDelete) return;

    setIsLoading(true);
    try {
      await onDelete(Number(limit.id));
      layoutStore.toggleSidebar(false);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormProvider {...form}>
      <div className={styles.main}>
        <div className={styles.fields}>
          <PlainFields operationGroups={operationGroups} />
          <UserGroups userGroups={userGroups} />
        </div>

        {isCreate ? (
          <Button
            variant="primary"
            disabled={isLoading || !isValid}
            onClick={handleSubmit(createLimit)}
          >
            {formatMessage({
              id: 'sidebar.limit_details.create',
              defaultMessage: 'Create limit',
            })}
          </Button>
        ) : (
          <div className={styles.actions}>
            <Button
              variant="negative"
              disabled={isLoading}
              // TODO: add modal for confirm deletion
              onClick={() => deleteLimit()}
            >
              {formatMessage({
                id: 'sidebar.limit_details.delete',
                defaultMessage: 'Delete limit',
              })}
            </Button>
            <Button
              variant="primary"
              disabled={isLoading || !isValid}
              onClick={handleSubmit(editLimit)}
            >
              {formatMessage({
                id: 'sidebar.limit_details.edit',
                defaultMessage: 'Save changes',
              })}
            </Button>
          </div>
        )}
      </div>
    </FormProvider>
  );
});

export const useSidebarCreateLimit = (
  { onCreate, onUpdate, onDelete }:
  {
    onCreate?: (limit: Limit) => Promise<void>;
    onUpdate?: (limitId: number, data: Limit) => Promise<void>
    onDelete?: (limitId: number) => Promise<void>;
  },
) => {
  const { formatMessage, locale } = useIntl();
  const { layoutStore } = useBaseStores();

  const showSidebar = useCallback(
    (userGroups: AdminGroupModel[], operationGroups: OperationGroupModel[], limit?: LimitModel) => {
      layoutStore.toggleSidebar(
        true,
        formatMessage(limit?.id
          ? {
            id: 'sidebar.limit_details.title',
            defaultMessage: 'Limit details',
          }
          : {
            id: 'sidebar.limit_details.create_title',
            defaultMessage: 'Create limit',
          }),
        <SidebarLimitCreate
          limit={limit}
          userGroups={userGroups}
          operationGroups={operationGroups}
          onCreate={onCreate}
          onUpdate={onUpdate}
          onDelete={onDelete}
        />,
      );
    },
    [locale, layoutStore, onCreate, onUpdate, onDelete],
  );

  return {
    showCreateLimitSidebar: showSidebar,
  };
};
