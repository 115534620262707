/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

type UtilRequiredKeys<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

export interface RejectConfirmWithdrawalData {
  /**
   * @minLength 1
   * @maxLength 255
   */
  reasonText: string;
}

export interface AmlFlag {
  /** @format int32 */
  impact?: number;
  /** @format int32 */
  likelihood?: number;
  severity?: 'NOTE' | 'LOW' | 'MEDIUM' | 'HIGH' | 'CRITICAL';
  name?: string;
  description?: string;
  externalLink?: string;
}

export type ConfirmDepositRequest = UtilRequiredKeys<ConfirmRequest, 'requestType'> & {
  deposit?: string;
  currency?: string;
  /** @format number */
  amount?: string;
  amlFlags: AmlFlag[];
};

export interface ConfirmRequest {
  /** @format uuid */
  id?: string;
  requestType: string;
  /** @format date-time */
  updatedAt?: string;
  /** @format date-time */
  createdAt?: string;
  status?: 'PENDING' | 'APPROVING' | 'APPROVED' | 'REJECTING' | 'REJECTED' | 'FAILED' | 'INITIAL';
  statusReasonText?: string;
  /** @format int32 */
  partnerId?: number;
  amlAnalysisGrade?: number;
  limitDto?: Limit;
  summaryDto?: SummaryDto;
}

export type ConfirmWithdrawalRequest = UtilRequiredKeys<ConfirmRequest, 'requestType'> & {
  withdraw?: Withdraw;
  currency?: string;
  /** @format number */
  amount?: string;
  amlFlags: AmlFlag[];
};

export interface Limit {
  type: 'FEE_LIMIT' | 'WITHDRAW_LIMIT' | 'DEPOSIT_LIMIT';
  /**
   * Day limit in currency
   * @format number
   * @example "9.87"
   */
  dayLimit?: string;
  /**
   * Month limit in currency
   * @format number
   * @example "9.87"
   */
  monthLimit?: string;
  /**
   * Year limit in currency
   * @format number
   * @example "9.87"
   */
  yearLimit?: string;
  /**
   * Global limit in currency
   * @format number
   * @example "9.87"
   */
  globalLimit?: string;
  currency?: string;
  group?: string;
  /** @format int32 */
  groupPriority?: number;
}

export interface SummaryDto {
  daySummary?: number;
  monthSummary?: number;
  yearSummary?: number;
  globalSummary?: number;
  currency: string;
}

export interface Withdraw {
  id?: string;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  updatedAt?: string;
  customerId?: string;
  currency?: string;
  processingId?: string;
  /** @format number */
  amount?: string;
  /** @format number */
  availableBalance?: string;
  paymentDestinationType?: 'BITCOIN' | 'ETHEREUM' | 'TON' | 'TRON' | 'FIAT' | 'OTHER' | 'GENERIC_WITHDRAW';
  paymentDestinationId?: string;
  status?: 'PENDING' | 'APPROVED' | 'REJECTED' | 'PROCESSING' | 'WITHDRAWN' | 'CANCELED' | 'FAILED';
  reason?: string;
}

export interface AmlAnalysisDto {
  /** @format uuid */
  operationId: string;
  amlAnalysisResults: AmlAnalysisResultDto[];
}

export interface AmlAnalysisResultDto {
  provider: 'CRYSTAL_AML' | 'BTRACE_AML';
  alertName: string;
  description: string;
  impact?: number;
  severity?: number;
  likelihood: string;
  status: 'PENDING' | 'FAILED' | 'SUCCESS';
}

export interface PageableResponseConfirmRequest {
  data: (ConfirmRequest | ConfirmDepositRequest | ConfirmWithdrawalRequest)[];
  /** @format int32 */
  currentPage: number;
  /** @format int64 */
  totalItems: number;
  /** @format int32 */
  totalPages: number;
}

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from 'axios';
import axios from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || 'https://api.stg.darknet.piefi.app',
    });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Approver
 * @version 0.5.0-SNAPSHOT
 * @baseUrl https://api.stg.darknet.piefi.app
 *
 * Admin API for approving users and withdrawals.
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  requestsController = {
    /**
     * No description
     *
     * @tags requests-controller
     * @name RejectConfirmRequest
     * @request PUT:/api/v1/requests/{id}/reject
     * @secure
     */
    rejectConfirmRequest: (id: string, data: RejectConfirmWithdrawalData, params: RequestParams = {}) =>
      this.request<ConfirmRequest | ConfirmDepositRequest | ConfirmWithdrawalRequest, any>({
        path: `/api/v1/requests/${id}/reject`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags requests-controller
     * @name ApproveConfirmRequest
     * @request PUT:/api/v1/requests/{id}/approve
     * @secure
     */
    approveConfirmRequest: (id: string, data: RejectConfirmWithdrawalData, params: RequestParams = {}) =>
      this.request<ConfirmRequest | ConfirmDepositRequest | ConfirmWithdrawalRequest, any>({
        path: `/api/v1/requests/${id}/approve`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags requests-controller
     * @name FindConfirmRequests
     * @request GET:/api/v1/requests
     * @secure
     */
    findConfirmRequests: (
      query?: {
        /** @format date-time */
        from?: string;
        /** @format date-time */
        to?: string;
        status?: string;
        /** @format int32 */
        page?: number;
        requestType?: string;
        search?: string;
        /** @format int32 */
        size?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageableResponseConfirmRequest, any>({
        path: `/api/v1/requests`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags requests-controller
     * @name FindConfirmRequestById
     * @request GET:/api/v1/requests/{id}
     * @secure
     */
    findConfirmRequestById: (id: string, params: RequestParams = {}) =>
      this.request<ConfirmRequest | ConfirmDepositRequest | ConfirmWithdrawalRequest, any>({
        path: `/api/v1/requests/${id}`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  amlAnalysisController = {
    /**
     * No description
     *
     * @tags aml-analysis-controller
     * @name InitializeAmlAnalysis
     * @request PUT:/api/v1/aml/analysis/{confirmedRequestId}
     * @secure
     */
    initializeAmlAnalysis: (confirmedRequestId: string, params: RequestParams = {}) =>
      this.request<AmlAnalysisDto, any>({
        path: `/api/v1/aml/analysis/${confirmedRequestId}`,
        method: 'PUT',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags aml-analysis-controller
     * @name GetAmlAnalysisResults
     * @request GET:/api/v1/aml/analysis/{confirmedRequestId}/aml/result
     * @secure
     */
    getAmlAnalysisResults: (confirmedRequestId: string, params: RequestParams = {}) =>
      this.request<AmlAnalysisDto, any>({
        path: `/api/v1/aml/analysis/${confirmedRequestId}/aml/result`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  statsController = {
    /**
     * @description Generic stats of registration service
     *
     * @tags stats-controller
     * @name GetStats
     * @summary Generic stats
     * @request GET:/stats
     * @secure
     */
    getStats: (params: RequestParams = {}) =>
      this.request<Record<string, number>, any>({
        path: `/stats`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
}
