/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { Limit } from 'api/auth';

export class LimitModel {
  constructor (dto: Limit) {
    this.currency = dto.currency;
    this.dayLimit = dto.dayLimit;
    this.globalLimit = dto.globalLimit;
    this.groupIds = dto.groupIds;
    this.groupNames = dto.groupNames;
    this.id = dto.id?.toString() ?? '';
    this.monthLimit = dto.monthLimit;
    this.operationGroupId = dto.operationGroupId;
    this.userIds = dto.userIds;
    this.yearLimit = dto.yearLimit;
  }

  currency?: string;
  dayLimit?: number;
  globalLimit?: number;
  groupIds?: number[];
  groupNames?: string[];
  id: string;
  monthLimit?: number;
  operationGroupId?: number;
  userIds?: string[];
  yearLimit?: number;
}
