/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { makeAutoObservable, runInAction } from 'mobx';

import { AuthApi } from 'api';
import { OperationGroupDto } from 'api/auth';
import { NotificationStore } from 'stores/NotificationStore';
import { FilterModel } from 'models/filter/FilterModel';
import { OperationModel } from 'models/OperationModel';
import { OperationGroupModel } from 'models/OperationGroupModel';
import { URL_SEARCH_PARAMS } from 'assets/config';
import { CollectionWithPages } from 'stores/collection/CollectionWithPages';
import { readStrFromQueryParams } from 'utils/queryParams';
import {
  AdminOperationGroupsRequestModel,
} from 'models/request/AdminOperationGroupsRequestModel';

export class OperationGroupsPageStore {
  constructor (
    private authApi: AuthApi,
    private notificationStore: NotificationStore,
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public operations: OperationModel[] = [];

  private _partnerId = 0;

  private _operationGroups = new CollectionWithPages<OperationGroupModel, AdminOperationGroupsRequestModel>({
    pageQueryParamId: URL_SEARCH_PARAMS.page,
    fetchFn: async (params) => {
      const result = await this.authApi.admin.listOperationGroups(params, {
        headers: { PartnerId: this._partnerId },
      });

      const items = result.data.data.map((group) => new OperationGroupModel(group));

      return {
        items: items,
        totalItems: result.data.totalItems,
        totalPages: result.data.totalPages,
      };
    },
    getFilterFromQueryParamsFn: (urlParams) => {
      const filters: FilterModel<AdminOperationGroupsRequestModel>[] = [];

      const searchStr = readStrFromQueryParams(
        urlParams,
        URL_SEARCH_PARAMS.search,
      );

      if (searchStr !== null) {
        filters.push({
          id: URL_SEARCH_PARAMS.search,
          value: {
            search: searchStr,
          },
        });
      }

      return filters;
    },
    filterCounterIgnoreValues: [URL_SEARCH_PARAMS.search],
    itemsPerPage: 12,
  });

  get operationGroups () {
    return this._operationGroups;
  }

  async init (props: {
    partnerId: number;
    filter: FilterModel<AdminOperationGroupsRequestModel>[];
    page: number;
  }) {
    const { partnerId, filter, page } = props;
    if (!partnerId && this._partnerId === 0) return;

    runInAction(() => {
      this._partnerId = partnerId;
    });

    try {
      await this._operationGroups.fetchData(filter, page);

      const [operationsResult] = await Promise.all([
        this.authApi.admin.listOperations(
          { headers: { PartnerId: partnerId || this._partnerId } },
        ),
      ]);

      const operationItems = operationsResult.data.map((operation) => new OperationModel(operation));

      runInAction(() => {
        this.operations = operationItems;
      });
    } catch (e) {
      this.notificationStore.handleApiError(e);
      console.error(e);
    }
  }

  async createOperationGroup (group: OperationGroupDto) {
    try {
      await this.authApi.admin.createOperationGroup(
        group,
        { headers: { PartnerId: this._partnerId } },
      );

      this.notificationStore.notify({
        title: 'Operation group was created',
        type: 'success',
      });

      await this.operationGroups.refresh();
    } catch (e) {
      this.notificationStore.handleApiError(e);
      console.error(e);
    }
  }

  async updateOperationGroup (operationGroupId: number, data: OperationGroupDto) {
    try {
      await this.authApi.admin.updateOperationGroup(
        operationGroupId,
        data,
        { headers: { PartnerId: this._partnerId } },
      );

      this.notificationStore.notify({
        title: 'Operation group was updated',
        type: 'success',
      });

      await this.operationGroups.refresh();
    } catch (e) {
      this.notificationStore.handleApiError(e);
      console.error(e);
    }
  }

  async deleteOperationGroup (operationGroupId: number) {
    try {
      await this.authApi.admin.deleteOperationGroup(
        operationGroupId,
        { headers: { PartnerId: this._partnerId } },
      );

      this.notificationStore.notify({
        title: 'Operation group was deleted',
        type: 'success',
      });

      await this.operationGroups.refresh();
    } catch (e) {
      this.notificationStore.handleApiError(e);
      console.error(e);
    }
  }
}
