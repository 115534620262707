/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { Segmented } from 'components/core/segmented';

import { ROUTES } from 'routes';

export const NavigationTab = () => {
  const { formatMessage, locale } = useIntl();
  const navigate = useNavigate();
  const location = useLocation();

  const tabs = useMemo(() => {
    return [
      {
        label: formatMessage({
          id: 'page.limits.title',
          defaultMessage: 'Limits',
        }),
        value: 'limits',
        path: ROUTES.limits.fullPath,
      },
      {
        label: formatMessage({
          id: 'page.operation_groups.title',
          defaultMessage: 'Operation groups',
        }),
        value: 'operationGroups',
        path: ROUTES.limits.operationGroups.fullPath,
      },
    ];
  }, [formatMessage, locale]);

  const activeTab = useMemo(() => {
    const foundTab = tabs.find((tab) => location.pathname === tab.path);
    return foundTab ? foundTab.value : tabs[0].value;
  }, [location.pathname, tabs]);

  const handleTabChange = (value: unknown) => {
    const targetTab = tabs.find((tab) => tab.value === value);
    if (targetTab) {
      navigate(targetTab.path);
    }
  };

  return (
    <Segmented
      value={activeTab}
      onChange={handleTabChange}
      options={tabs}
    />
  );
};
