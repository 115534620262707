/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { FC } from 'react';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';

import styles from './index.module.css';
import KycVerificationsTable from 'components/tables/kyc-verifications-table';

import { ApiErrorResponse } from 'api/auth';
import { useBaseStores } from 'providers/BaseStoresProvider';
import { useUserDetailsPageStore } from '../../../provider/StoreProvider';

export const Verifications: FC = observer(() => {
  const { formatMessage } = useIntl();
  const { notificationStore, authApi } = useBaseStores();
  const userDetailsPageStore = useUserDetailsPageStore();

  const syncVerification = async (id: string) => {
    try {
      await authApi.admin.adminSyncVerification(id);
      notificationStore.notify({
        title: 'Successfully Synchronize',
        type: 'success',
      });
    } catch (error: unknown) {
      const e = error as ApiErrorResponse;
      notificationStore.notify({
        title: e.message || 'Failed Synchronize',
        type: 'error',
      });
    }
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>
        {formatMessage({
          id: 'page.user_details.kyc.verifications',
          defaultMessage: 'Verifications',
        })}
      </h2>

      <KycVerificationsTable
        collection={userDetailsPageStore.kycVerifications}
        onSyncVerification={syncVerification}
      />
    </div>
  );
});
