/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';
import { useFormContext, useWatch } from 'react-hook-form';
import { Spin } from 'antd';

import styles from './index.module.css';
import SearchField from 'components/core/search-field';

import { CreateLimitForm } from '../../index';
import { AdminGroupModel } from 'models/GroupModel';

type Props = {
  isOpen: boolean;
  userGroups: AdminGroupModel[],
};

// TODO: add fixes with search after backend update

export const GroupsModal = observer(({ isOpen, userGroups }: Props) => {
  const { formatMessage } = useIntl();

  const {
    setValue,
    getValues,
    control,
  } = useFormContext<CreateLimitForm>();

  const groups = useWatch({ control, name: 'groupIds' }) ?? [];

  // const onSearchChanged = (val: string) => {
  //   usersPageStore.users.fetchData([{ id: 'search', value: { search: val } }]);
  // };

  const addGroup = (group: AdminGroupModel) => {
    const groups = getValues('groupIds') || [];
    setValue('groupIds', [group, ...groups], {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const filteredGroups = userGroups.filter(
    (user) => !groups.find((group) => group.id === user.id)
  );

  return (
    <div className={styles.container}>
      <span className={styles.title}>
        {formatMessage({
          id: 'sidebar.limit_details.user_groups.add',
          defaultMessage: 'Add group',
        })}
      </span>

      {/*<SearchField*/}
      {/*  value={''}*/}
      {/*  onChange={onSearchChanged}*/}
      {/*  placeholder={formatMessage({*/}
      {/*    id: 'sidebar.limit_details.user_groups.search',*/}
      {/*    defaultMessage: 'Search by user groups',*/}
      {/*  })}*/}
      {/*/>*/}

      {/*{usersPageStore.users.isLoading ? (*/}
      {/*  <div className={styles.spinner}>*/}
      {/*    <Spin />*/}
      {/*  </div>*/}
      {/*) : (*/}
        <div className={styles.list}>
          {filteredGroups.map((item) => (
            <button className={styles.card} onClick={() => addGroup(item)}>
              <div className={styles['card-row']}>
                <span className={styles['card-label']}>ID:</span>
                <span className={styles['card-value']}>{item.id}</span>
              </div>
              <div className={styles['card-row']}>
                <span className={styles['card-label']}>Name:</span>
                <span className={styles['card-value']}>{item.name}</span>
              </div>
            </button>
          ))}
        </div>
      {/*)}*/}
    </div>
  );
});
