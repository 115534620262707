/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

type UtilRequiredKeys<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

export type BaseAdminWithdraw = BaseBaseAdminWithdraw &
  (
    | BaseBaseAdminWithdrawTypeMapping<'ATM', AdminAtmWithdraw>
    | BaseBaseAdminWithdrawTypeMapping<'BANK_TRANSFER', AdminBankTransferWithdraw>
    | BaseBaseAdminWithdrawTypeMapping<'CRYPTO', AdminCryptoWithdraw>
    | BaseBaseAdminWithdrawTypeMapping<'PAY_SPOT', AdminPaySpotWithdraw>
    | BaseBaseAdminWithdrawTypeMapping<'COUNTERPARTY', AdminCounterPartyWithdraw>
    | BaseBaseAdminWithdrawTypeMapping<'PAYOUT', AdminPayoutWithdraw>
  );

export type BaseDepositAddress = BaseBaseDepositAddress &
  (
    | BaseBaseDepositAddressTypeMapping<'CRYPTO', CryptoAddress>
    | BaseBaseDepositAddressTypeMapping<'ACH', ACHAddress>
    | BaseBaseDepositAddressTypeMapping<'SWIFT', SwiftAddress>
    | BaseBaseDepositAddressTypeMapping<'FEDWIRE', FEDWIREAddress>
    | BaseBaseDepositAddressTypeMapping<'EFT', EFTAddress>
    | BaseBaseDepositAddressTypeMapping<'SEPA', SepaAddress>
  );

/** invoice payment info */
export type BaseInvoicePaymentDto = BaseBaseInvoicePaymentDto &
  (
    | BaseBaseInvoicePaymentDtoTypeMapping<'PLATFORM', PlatformInvoicePaymentDto>
    | BaseBaseInvoicePaymentDtoTypeMapping<'EXTERNAL', ExternalInvoicePaymentDto>
    | BaseBaseInvoicePaymentDtoTypeMapping<'CHECKOUT', CheckoutInvoicePaymentDto>
    | BaseBaseInvoicePaymentDtoTypeMapping<'STRIPE', StripeInvoicePaymentDto>
    | BaseBaseInvoicePaymentDtoTypeMapping<'INWIZO', InwizoInvoicePaymentDto>
    | BaseBaseInvoicePaymentDtoTypeMapping<'ITEZ', ItezInvoicePaymentDto>
  );

/** Object for merchant, invoice info */
export type BaseInvoiceDto = BaseBaseInvoiceDto &
  (
    | BaseBaseInvoiceDtoTypeMapping<'RECURRENT', RecurrentInvoiceDto>
    | BaseBaseInvoiceDtoTypeMapping<'ORIGINAL', OriginalInvoiceDto>
  );

export type BaseWithdraw = BaseBaseWithdraw &
  (
    | BaseBaseWithdrawTypeMapping<'ATM', AtmWithdraw>
    | BaseBaseWithdrawTypeMapping<'BANK_TRANSFER', BankTransferWithdraw>
    | BaseBaseWithdrawTypeMapping<'CRYPTO', CryptoWithdraw>
    | BaseBaseWithdrawTypeMapping<'PAY_SPOT', PaySpotWithdraw>
    | BaseBaseWithdrawTypeMapping<'COUNTERPARTY', CounterPartyWithdraw>
    | BaseBaseWithdrawTypeMapping<'PAYOUT', PayoutWithdraw>
  );

/** Model of counterparty */
export type BaseCounterparty = BaseBaseCounterparty &
  (
    | BaseBaseCounterpartyRailMapping<'CRYPTO', CryptoCounterparty>
    | BaseBaseCounterpartyRailMapping<'ACH', ACHCounterparty>
    | BaseBaseCounterpartyRailMapping<'FEDWIRE', FedwireCounterparty>
    | BaseBaseCounterpartyRailMapping<'SWIFT', SwiftCounterparty>
    | BaseBaseCounterpartyRailMapping<'SEPA', SepaCounterparty>
    | BaseBaseCounterpartyRailMapping<'EFT', EftCounterparty>
    | BaseBaseCounterpartyRailMapping<'IMPS', ImpsCounterparty>
  );

export type ACHAddress = UtilRequiredKeys<BaseBaseDepositAddress, 'accountId' | 'type'> & {
  accountId: string;
  /**
   * Account holder name
   * @example "John Doe"
   */
  accountHolderName: string;
  /**
   * Account number
   * @example "1234567890"
   */
  accountNumber: string;
  /**
   * Routing number
   * @example "026009593"
   */
  accountRoutingNumber: string;
  /**
   * Memo
   * @example "F1QTZ"
   */
  memo: string;
  /**
   * Bank name
   * @example "Bank of America"
   */
  bankName: string;
};

export type ACHCounterparty = UtilRequiredKeys<BaseBaseCounterparty, 'disabled' | 'type' | 'rail' | 'profile'> & {
  /**
   * Counterparty routing number
   * @example "123456789"
   */
  routingNumber: string;
  /**
   * Counterparty account number
   * @example "74279579268"
   */
  accountNumber: string;
  errorDescription?: string;
};

/** Model of account */
export type Account = BaseAccount &
  (
    | BaseAccountAccountTypeMapping<'CARD_PREPAID', PrepaidCardAccount>
    | BaseAccountAccountTypeMapping<'LAYER2', AccountLayer2>
    | BaseAccountAccountTypeMapping<'INVESTMENT', BaseAccount>
    | BaseAccountAccountTypeMapping<'CHECKING', BaseAccount>
    | BaseAccountAccountTypeMapping<'SAVING', BaseAccount>
    | BaseAccountAccountTypeMapping<'OTHER', BaseAccount>
  );

export type AccountBodyDto = object;

export type AccountDto = object;

/** Model of layer2 account */
export type AccountLayer2 = UtilRequiredKeys<BaseAccount, 'account' | 'accountType' | 'shortName'> & {
  /**
   * Currency of account mapped to currency service
   * @example "USD"
   */
  currency?: string;
  /**
   * Blockchain of account mapped to currency service
   * @example "ETH"
   */
  blockchain?: string;
};

/** Model of Investment account */
export interface AccountOther {
  /**
   * UUID or shortname of account id
   * @example "8b3d1cdc-cd79-42ae-8040-7404c35a3026"
   */
  account: string;
  /** User defined short name of account */
  shortName: string;
  status?: AccountStatusDto;
}

/** Status of account */
export enum AccountStatus {
  INIT = 'INIT',
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  CLOSED = 'CLOSED',
  FROZEN = 'FROZEN',
}

export enum AccountStatusDto {
  INIT = 'INIT',
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  CLOSED = 'CLOSED',
  FROZEN = 'FROZEN',
  UnknownDefaultOpenApi = 'unknown_default_open_api',
}

export enum AccountType {
  OTHER = 'OTHER',
  CHECKING = 'CHECKING',
  SAVING = 'SAVING',
  INVESTMENT = 'INVESTMENT',
  LAYER2 = 'LAYER2',
  CARD_PREPAID = 'CARD_PREPAID',
  BANK_ACCOUNT_VIRTUAL = 'BANK_ACCOUNT_VIRTUAL',
  BANK_ACCOUNT_PHYSICAL = 'BANK_ACCOUNT_PHYSICAL',
}

export enum AccountUserRole {
  OWNER = 'OWNER',
  READ = 'READ',
  WRITE = 'WRITE',
}

export interface AddressValidation {
  valid: boolean;
  validChecksum: boolean;
  internal: boolean;
}

export interface AddressValidationDto {
  valid: boolean;
  validChecksum: boolean;
  internal: boolean;
}

export interface AdminAccount {
  accountId: string;
  type: AccountType;
  users: AdminAccountUser[];
  /** @format int64 */
  partnerId?: number;
}

export interface AdminAccountUser {
  accountId: string;
  /** @format uuid */
  userId: string;
  shortName: string;
  role: AccountUserRole;
  /** @format date-time */
  createdAt?: string;
}

export type AdminAtmWithdraw = UtilRequiredKeys<
  BaseBaseAdminWithdraw,
  'currency' | 'amount' | 'type' | 'balanceLogs'
> & {
  externalTransactionId?: string;
  atmId?: string;
  creditorAccountId?: string;
};

export type AdminBankTransferWithdraw = UtilRequiredKeys<
  BaseBaseAdminWithdraw,
  'currency' | 'amount' | 'type' | 'balanceLogs'
> & {
  iban?: string;
  bicOrSwiftCode?: string;
};

export type AdminCounterPartyWithdraw = UtilRequiredKeys<
  BaseBaseAdminWithdraw,
  'currency' | 'amount' | 'type' | 'balanceLogs'
> & {
  /** @format uuid */
  counterPartyId?: string;
};

export type AdminCryptoWithdraw = UtilRequiredKeys<
  BaseBaseAdminWithdraw,
  'currency' | 'amount' | 'type' | 'balanceLogs'
> & {
  to?: string;
  blockchain?: string;
};

export interface AdminDeposit {
  /** @format uuid */
  id?: string;
  depositType?: string;
  accountId?: string;
  currency?: string;
  /**
   * From amount of currency
   * @format number
   * @example "10.2"
   */
  amount?: string;
  blockchain?: string;
  token?: string;
  meta?: string;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  updatedAt?: string;
  status?: DepositStatusEnum;
  balanceLogs: BalanceLog[];
}

export interface AdminDirectExchange {
  /**
   * Direct Exchange Id
   * @format uuid
   */
  id?: string;
  /** User account */
  fromAccount: string;
  /** User account */
  toAccount: string;
  /**
   * From currency short name
   * @example "USDT"
   */
  fromCurrency: string;
  /**
   * To currency short name
   * @example "EPI"
   */
  toCurrency: string;
  /**
   * From amount of currency
   * @format number
   * @example "10.2"
   */
  fromAmount: string;
  /**
   * To amount of currency
   * @format number
   * @example "9.87"
   */
  toAmount: string;
  signedRate?: SignedRate;
  /**
   * Fee amount charged over `toAmount`
   * @format number
   * @example "0.01"
   */
  feeAmount?: string;
  /**
   * Currency of charged fee
   * @example "USDT"
   */
  feeCurrency?: string;
  /** User account */
  feeAccount?: string;
  /** User account */
  mmAccount?: string;
  /**
   * Status of exchange:
   * * `UNDEFINED` - status not set
   * * `ACCEPTED` - request is accepted, can be canceled
   * * `PENDING` - request is processing
   * * `SUCCESS` - request is success (final status)
   * * `FAILED` - request is failed, see `note` for comments (final status)
   */
  status?: DirectExchangeStatus;
  /** Additional info about exchange request */
  note?: string;
  /**
   * Datetime of request being accepted
   * @format date-time
   */
  createdAt?: string;
  /**
   * Last Datetime of request status being updated
   * @format date-time
   */
  updatedAt?: string;
  balanceLogs: BalanceLog[];
}

export interface AdminDirectTransfer {
  /** @format uuid */
  id?: string;
  fromAccount?: string;
  toAccount?: string;
  currency?: string;
  /**
   * From amount of currency
   * @format number
   * @example "10.2"
   */
  amount?: string;
  status?: TransferStatus;
  statusDescription?: string;
  note?: string;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  updatedAt?: string;
  balanceLogs: BalanceLog[];
}

export type AdminPaySpotWithdraw = UtilRequiredKeys<
  BaseBaseAdminWithdraw,
  'currency' | 'amount' | 'type' | 'balanceLogs'
> & {
  beneficiaryAccountNumber?: string;
  beneficiaryName?: string;
  /** @format int32 */
  paymentCode?: number;
  paymentPurpose?: string;
  modul?: string;
};

export type AdminPayoutWithdraw = UtilRequiredKeys<
  BaseBaseAdminWithdraw,
  'currency' | 'amount' | 'type' | 'balanceLogs'
> & {
  invoiceId?: string;
  beneficiaryAccount?: string;
};

export interface ApiError {
  key?: string;
  description: string;
}

export interface ApiErrorDto {
  key?: string;
  description: string;
}

export interface ApiErrorResponse {
  status: HttpStatus;
  message: string;
  traceId: string;
  errors?: ApiError[];
}

export interface ApiErrorResponseDto {
  status: HttpStatusDto;
  message: string;
  traceId: string;
  errors?: ApiErrorDto[];
}

export type AtmWithdraw = UtilRequiredKeys<BaseBaseWithdraw, 'currency' | 'amount' | 'type' | 'fromAccount'> & {
  externalTransactionId?: string;
  atmId?: string;
  creditorAccountId?: string;
};

export interface Balance {
  /**
   * User account
   * @format uuid
   */
  account: string;
  /**
   * Balance
   * @format number
   * @example "9.87"
   */
  balance: string;
  /**
   * To currency short name
   * @example "EPI"
   */
  currency: string;
  /**
   * Last date time of balance updated
   * @format date-time
   */
  lastUpdated?: string;
}

export interface BalanceDto {
  /**
   * User account
   * @format uuid
   */
  account: string;
  /**
   * Balance
   * @example 9.87
   */
  balance: number;
  /**
   * To currency short name
   * @example "EPI"
   */
  currency: string;
  /**
   * Last date time of balance updated
   * @format date-time
   */
  lastUpdated?: string;
}

export interface BalanceHistoryDataDto {
  /** Timestamp in millis from unixtime (UTC) */
  timestamp?: number;
  /** Balance at date in currency */
  balance?: number;
}

export interface BalanceHistoryDto {
  /**
   * Currency slug
   * @pattern ^[a-z_]+$
   * @example "usd"
   */
  currency?: string;
  data?: BalanceHistoryDataDto[];
}

export interface BalanceLog {
  /** Unique Id */
  id?: string;
  /**
   * User account
   * @format uuid
   */
  account: string;
  /**
   * Balance
   * @format number
   * @example "9.87"
   */
  change: string;
  /**
   * Currency short name
   * @example "EPI"
   */
  currency: string;
  /** @format date-time */
  createdAt?: string;
  reasonId?: string;
  reasonType: BalanceLogReasonType;
  note?: string;
  /**
   * Source of the balance log
   * @deprecated
   */
  source?: string;
}

export enum BalanceLogDirectionEnum {
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT',
}

export enum BalanceLogDirectionEnumDto {
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT',
  UnknownDefaultOpenApi = 'unknown_default_open_api',
}

export interface BalanceLogDto {
  /** Unique Id */
  id?: string;
  /** User account */
  account: string;
  /**
   * Balance
   * @example 9.87
   */
  change: number;
  /**
   * Currency short name
   * @example "EPI"
   */
  currency: string;
  /** @format date-time */
  createdAt?: string;
  reasonId?: string;
  reasonType: BalanceLogReasonTypeDto;
  note?: string;
  /**
   * Source of the balance log
   * @deprecated
   */
  source?: string;
}

export enum BalanceLogReasonType {
  ORDER = 'ORDER',
  TRANSFER = 'TRANSFER',
  DEPOSIT = 'DEPOSIT',
  BTC_DEPOSIT = 'BTC_DEPOSIT',
  ETH_DEPOSIT = 'ETH_DEPOSIT',
  TON_DEPOSIT = 'TON_DEPOSIT',
  BTC_WITHDRAWAL_REQUEST = 'BTC_WITHDRAWAL_REQUEST',
  BTC_WITHDRAWAL_REJECT = 'BTC_WITHDRAWAL_REJECT',
  BTC_WITHDRAWAL_FAIL = 'BTC_WITHDRAWAL_FAIL',
  ETH_WITHDRAWAL_REQUEST = 'ETH_WITHDRAWAL_REQUEST',
  ETH_WITHDRAWAL_REJECT = 'ETH_WITHDRAWAL_REJECT',
  ETH_WITHDRAWAL_FAIL = 'ETH_WITHDRAWAL_FAIL',
  TON_WITHDRAWAL_REQUEST = 'TON_WITHDRAWAL_REQUEST',
  TON_WITHDRAWAL_REJECT = 'TON_WITHDRAWAL_REJECT',
  TON_WITHDRAWAL_FAIL = 'TON_WITHDRAWAL_FAIL',
  FIAT_WITHDRAWAL_REQUEST = 'FIAT_WITHDRAWAL_REQUEST',
  FIAT_WITHDRAWAL_REJECT = 'FIAT_WITHDRAWAL_REJECT',
  DEPOSIT_FEE = 'DEPOSIT_FEE',
  WITHDRAWAL = 'WITHDRAWAL',
  WITHDRAWAL_FEE = 'WITHDRAWAL_FEE',
  REJECTED_WITHDRAWAL_FEE = 'REJECTED_WITHDRAWAL_FEE',
  FAILED_WITHDRAWAL_FEE = 'FAILED_WITHDRAWAL_FEE',
  ORDER_FEE = 'ORDER_FEE',
  DIRECT_EXCHANGE = 'DIRECT_EXCHANGE',
  DIRECT_EXCHANGE_FEE = 'DIRECT_EXCHANGE_FEE',
  LOCK_BALANCE = 'LOCK_BALANCE',
  UNLOCK_BALANCE = 'UNLOCK_BALANCE',
  UNKNOWN = 'UNKNOWN',
}

export enum BalanceLogReasonTypeDto {
  ORDER = 'ORDER',
  TRANSFER = 'TRANSFER',
  DEPOSIT = 'DEPOSIT',
  BTC_DEPOSIT = 'BTC_DEPOSIT',
  ETH_DEPOSIT = 'ETH_DEPOSIT',
  TON_DEPOSIT = 'TON_DEPOSIT',
  BTC_WITHDRAWAL_REQUEST = 'BTC_WITHDRAWAL_REQUEST',
  BTC_WITHDRAWAL_REJECT = 'BTC_WITHDRAWAL_REJECT',
  BTC_WITHDRAWAL_FAIL = 'BTC_WITHDRAWAL_FAIL',
  ETH_WITHDRAWAL_REQUEST = 'ETH_WITHDRAWAL_REQUEST',
  ETH_WITHDRAWAL_REJECT = 'ETH_WITHDRAWAL_REJECT',
  ETH_WITHDRAWAL_FAIL = 'ETH_WITHDRAWAL_FAIL',
  TON_WITHDRAWAL_REQUEST = 'TON_WITHDRAWAL_REQUEST',
  TON_WITHDRAWAL_REJECT = 'TON_WITHDRAWAL_REJECT',
  TON_WITHDRAWAL_FAIL = 'TON_WITHDRAWAL_FAIL',
  FIAT_WITHDRAWAL_REQUEST = 'FIAT_WITHDRAWAL_REQUEST',
  FIAT_WITHDRAWAL_REJECT = 'FIAT_WITHDRAWAL_REJECT',
  DEPOSIT_FEE = 'DEPOSIT_FEE',
  WITHDRAWAL = 'WITHDRAWAL',
  WITHDRAWAL_FEE = 'WITHDRAWAL_FEE',
  REJECTED_WITHDRAWAL_FEE = 'REJECTED_WITHDRAWAL_FEE',
  FAILED_WITHDRAWAL_FEE = 'FAILED_WITHDRAWAL_FEE',
  ORDER_FEE = 'ORDER_FEE',
  EXCHANGE = 'EXCHANGE',
  EXCHANGE_FEE = 'EXCHANGE_FEE',
  DIRECT_EXCHANGE = 'DIRECT_EXCHANGE',
  DIRECT_EXCHANGE_FEE = 'DIRECT_EXCHANGE_FEE',
  LOCK_BALANCE = 'LOCK_BALANCE',
  UNLOCK_BALANCE = 'UNLOCK_BALANCE',
  UNKNOWN = 'UNKNOWN',
  UnknownDefaultOpenApi = 'unknown_default_open_api',
}

export interface BalanceLogWithReceipt {
  /** Unique Id */
  id?: string;
  /**
   * User account
   * @format uuid
   */
  account: string;
  /**
   * Balance
   * @format number
   * @example "9.87"
   */
  change: string;
  /**
   * Currency short name
   * @example "EPI"
   */
  currency: string;
  /** @format date-time */
  createdAt?: string;
  reasonId?: string;
  reasonType: BalanceLogReasonType;
  status?: string;
  note?: string;
  /**
   * Source of the balance log
   * @deprecated
   */
  source?: string;
  receipt?: Receipt;
}

export interface BalanceLogWithReceiptDto {
  /** Unique Id */
  id?: string;
  /**
   * User account
   * @format uuid
   */
  account: string;
  /**
   * Balance
   * @example 9.87
   */
  change: number;
  /**
   * Currency short name
   * @example "EPI"
   */
  currency: string;
  /** @format date-time */
  createdAt?: string;
  reasonId?: string;
  reasonType: BalanceLogReasonTypeDto;
  status?: string;
  note?: string;
  /**
   * Source of the balance log
   * @deprecated
   */
  source?: string;
  receipt?: ReceiptDto;
}

export type BankTransferWithdraw = UtilRequiredKeys<
  BaseBaseWithdraw,
  'currency' | 'amount' | 'type' | 'fromAccount'
> & {
  /** @pattern ([A-Z]{2}[ -]?[0-9]{2})(?=(?:[ -]?[A-Z0-9]){9,30}$)((?:[ -]?[A-Z0-9]{3,5}){2,7})([ -]?[A-Z0-9]{1,3})? */
  iban?: string;
  /** @pattern [A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})? */
  bicOrSwiftCode?: string;
};

export type CheckoutInvoicePaymentDto = UtilRequiredKeys<BaseBaseInvoicePaymentDto, 'invoiceId' | 'type'> & {
  session?: JsonNode;
};

export interface ConversionFunds {
  /**
   * Rate for exchange
   * @format number
   * @example "1.12"
   */
  amount: string;
  currency: string;
  rate?: SignedRate;
}

export interface ConversionFundsDto {
  /**
   * Rate for exchange
   * @example 1.12
   */
  amount: number;
  currency: string;
  /**
   * Rate for exchange
   * @example 1.12
   */
  rate?: number;
}

export type CounterPartyWithdraw = UtilRequiredKeys<
  BaseBaseWithdraw,
  'currency' | 'amount' | 'type' | 'fromAccount'
> & {
  /** @format uuid */
  counterPartyId?: string;
};

export type CounterpartyBodyDto = object;

export type CounterpartyCounterpartyIdBodyDto = object;

export enum CounterpartyProfileType {
  INDIVIDUAL = 'INDIVIDUAL',
  CORPORATION = 'CORPORATION',
  UNKNOWN = 'UNKNOWN',
}

/**
 * Type of rail
 * @example "CRYPTO"
 */
export enum CounterpartyRailType {
  ACH = 'ACH',
  FEDWIRE = 'FEDWIRE',
  SWIFT = 'SWIFT',
  EFT = 'EFT',
  SEPA = 'SEPA',
  IMPS = 'IMPS',
  CRYPTO = 'CRYPTO',
}

/**
 * Type of counterparty
 * @example "CRYPTO"
 */
export enum CounterpartyType {
  FIAT = 'FIAT',
  CRYPTO = 'CRYPTO',
}

/**
 * Type of wallet that will hold/custody these funds for the recipient owner (e.g. Custodian, Private Wallet etc)
 * @example "INSTITUTION"
 */
export enum CounterpartyWalletType {
  INSTITUTION = 'INSTITUTION',
  OTHER = 'OTHER',
  UNKNOWN = 'UNKNOWN',
}

export interface CreatePayin {
  /**
   * User account
   * @format uuid
   */
  account: string;
  /**
   * User will sell this fiat currency
   * @default "EUR"
   */
  fromCurrency?: string;
  /**
   * User will buy this crypto currency
   * @example "EPI"
   */
  toCurrency: string;
  /**
   * From amount of fiat currency
   * @format number
   * @example "10.2"
   */
  fromAmount?: string;
  signedRate?: SignedRate;
}

export interface CreatePayinDto {
  /**
   * User account
   * @format uuid
   */
  account: string;
  /** User will sell this fiat currency */
  fromCurrency?: string;
  /**
   * User will buy this crypto currency
   * @example "EPI"
   */
  toCurrency: string;
  /**
   * From amount of fiat currency
   * @example 10.2
   */
  fromAmount?: number;
  signedRate?: SignedRateDto;
}

export type CryptoAddress = UtilRequiredKeys<BaseBaseDepositAddress, 'accountId' | 'type'> & {
  accountId: string;
  /** @format int64 */
  accountIndex?: number;
  /**
   * Address index (used for multiple deposit address for account)
   * @format int64
   * @example 0
   */
  addressIndex?: number;
  /**
   * Blockchain name
   * @example "ETH"
   */
  blockchain: string;
  /**
   * Address
   * @example "0x1234567890abcdef"
   */
  address: string;
};

/** Counterparty for crypto transactions */
export type CryptoCounterparty = UtilRequiredKeys<BaseBaseCounterparty, 'disabled' | 'type' | 'rail' | 'profile'> & {
  /**
   * Blockchain name
   * @example "ETH"
   */
  blockchain: string;
  /**
   * Blockchain address
   * @example "0xb794f5ea0ba39494ce839613fffba74279579268"
   */
  blockchainAddress: string;
  /** Type of wallet that will hold/custody these funds for the recipient owner (e.g. Custodian, Private Wallet etc) */
  walletType: CounterpartyWalletType;
  errorDescription?: string;
};

export type CryptoWithdraw = UtilRequiredKeys<BaseBaseWithdraw, 'currency' | 'amount' | 'type' | 'fromAccount'> & {
  to?: string;
  blockchain?: string;
};

export interface DepositDto {
  /** @format uuid */
  id?: string;
  depositType?: DepositTypeEnum;
  accountId?: string;
  currency?: string;
  /**
   * From amount of currency
   * @example 10.2
   */
  amount?: number;
  blockchain?: string;
  token?: string;
  meta?: string;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  updatedAt?: string;
}

export enum DepositStatusEnum {
  UNDEFINED = 'UNDEFINED',
  INITIAL = 'INITIAL',
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  PROCESSING = 'PROCESSING',
  PROCESSED = 'PROCESSED',
  FAILING = 'FAILING',
  FAILED = 'FAILED',
  REJECTING = 'REJECTING',
  REJECTED = 'REJECTED',
}

export enum DepositTypeEnum {
  CRYPTO = 'CRYPTO',
  CARD = 'CARD',
  WIRE = 'WIRE',
  UnknownDefaultOpenApi = 'unknown_default_open_api',
}

export interface DirectExchange {
  /**
   * Direct Exchange Id
   * @format uuid
   */
  id?: string;
  /**
   * User from account
   * @format uuid
   */
  fromAccount: string;
  /**
   * User destination account
   * @format uuid
   */
  toAccount?: string;
  /**
   * From currency short name
   * @example "USDT"
   */
  fromCurrency: string;
  /**
   * To currency short name
   * @example "EPI"
   */
  toCurrency: string;
  /**
   * From amount of currency
   * @format number
   * @example "10.2"
   */
  fromAmount?: string;
  /**
   * To amount of currency
   * @format number
   * @example "9.87"
   */
  toAmount?: string;
  signedRate?: SignedRate;
  /**
   * Fee amount charged over `toAmount`
   * @format number
   * @example "0.01"
   */
  feeAmount?: string;
  /**
   * Currency of charged fee
   * @example "USDT"
   */
  feeCurrency?: string;
  /**
   * Status of exchange:
   * * `UNDEFINED` - status not set
   * * `ACCEPTED` - request is accepted, can be canceled
   * * `PENDING` - request is processing
   * * `SUCCESS` - request is success (final status)
   * * `FAILED` - request is failed, see `note` for comments (final status)
   */
  status?: DirectExchangeStatus;
  /** Additional info about exchange request */
  note?: string;
  /**
   * Datetime of request being accepted
   * @format date-time
   */
  createdAt?: string;
  /**
   * Last Datetime of request status being updated
   * @format date-time
   */
  updatedAt?: string;
}

export interface DirectExchangeDto {
  /**
   * Direct Exchange Id
   * @format uuid
   */
  id?: string;
  /** User from account */
  fromAccount: string;
  /** User destination account */
  toAccount?: string;
  /**
   * From currency short name
   * @example "USDT"
   */
  fromCurrency: string;
  /**
   * To currency short name
   * @example "EPI"
   */
  toCurrency: string;
  /**
   * From amount of currency
   * @example 10.2
   */
  fromAmount?: number;
  /**
   * To amount of currency
   * @example 9.87
   */
  toAmount?: number;
  /**
   * Exchange rate
   * @example 9.87
   */
  rate?: number;
  /**
   * Fee amount charged over `toAmount`
   * @example 0.01
   */
  feeAmount?: number;
  /**
   * Currency of charged fee
   * @example "USDT"
   */
  feeCurrency?: string;
  status?: DirectExchangeStatusDto;
  /** Additional info about exchange request */
  note?: string;
  /**
   * Datetime of request being accepted
   * @format date-time
   */
  createdAt?: string;
  /**
   * Last Datetime of request status being updated
   * @format date-time
   */
  updatedAt?: string;
}

/**
 * Status of exchange:
 * * `UNDEFINED` - status not set
 * * `ACCEPTED` - request is accepted, can be canceled
 * * `PENDING` - request is processing
 * * `SUCCESS` - request is success (final status)
 * * `FAILED` - request is failed, see `note` for comments (final status)
 * @example "SUCCESS"
 */
export enum DirectExchangeStatus {
  UNDEFINED = 'UNDEFINED',
  ACCEPTED = 'ACCEPTED',
  PENDING = 'PENDING',
  APPROVING = 'APPROVING',
  APPROVED = 'APPROVED',
  PROCESSING = 'PROCESSING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  REJECTING = 'REJECTING',
  REJECTED = 'REJECTED',
}

export enum DirectExchangeStatusDto {
  UNDEFINED = 'UNDEFINED',
  ACCEPTED = 'ACCEPTED',
  PENDING = 'PENDING',
  APPROVING = 'APPROVING',
  APPROVED = 'APPROVED',
  PROCESSING = 'PROCESSING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  REJECTING = 'REJECTING',
  REJECTED = 'REJECTED',
  UnknownDefaultOpenApi = 'unknown_default_open_api',
}

export type EFTAddress = UtilRequiredKeys<BaseBaseDepositAddress, 'accountId' | 'type'> & {
  accountId: string;
  /**
   * Account number
   * @example "1234567890"
   */
  accountNumber: string;
  /**
   * Institution Number
   * @example "001"
   */
  institutionNumber: string;
  /**
   * Transit Number
   * @example "00022"
   */
  transitNumber: string;
  /**
   * Bank name
   * @example "Bank of America"
   */
  bankName?: string;
  /**
   * Bank address
   * @example "123 Main St, New York, NY 10001"
   */
  bankAddress?: string;
  /**
   * Account holder name
   * @example "John Doe"
   */
  accountHolderName: string;
  /**
   * Account holder address
   * @example "123 Main St, New York, NY 10001"
   */
  accountHolderAddress?: string;
  /**
   * Memo
   * @example "F1QTZ"
   */
  memo?: string;
};

export type EftCounterparty = UtilRequiredKeys<
  BaseBaseCounterparty,
  'disabled' | 'type' | 'rail' | 'institutionName' | 'institutionAddress' | 'profile'
> & {
  institutionName: string;
  /**
   * Counterparty account number
   * @example "74279579268"
   */
  accountNumber: string;
  /**
   * Counterparty institution number
   * @example "123456789"
   */
  institutionNumber: string;
  /**
   * Counterparty transit number
   * @example "123456789"
   */
  transitNumber: string;
  errorDescription?: string;
};

/** Exchange route */
export interface ExchangeRoute {
  routeElements?: ExchangeRouteElement[];
}

export interface ExchangeRouteElement {
  fromCurrency?: string;
  toCurrency?: string;
  exchange?: string;
  rate?: number;
}

export type ExternalInvoicePaymentDto = UtilRequiredKeys<BaseBaseInvoicePaymentDto, 'invoiceId' | 'type'>;

export type FEDWIREAddress = UtilRequiredKeys<BaseBaseDepositAddress, 'accountId' | 'type'> & {
  accountId: string;
  /**
   * Account number
   * @example "1234567890"
   */
  accountNumber: string;
  /**
   * Routing number
   * @example "026009593"
   */
  accountRoutingNumber: string;
  /**
   * Memo
   * @example "F1QTZ"
   */
  memo?: string;
  /**
   * Bank name
   * @example "Bank of America"
   */
  bankName?: string;
  /**
   * Bank address
   * @example "123 Main St, New York, NY 10001"
   */
  bankAddress?: string;
  /**
   * Account holder name
   * @example "John Doe"
   */
  accountHolderName: string;
  /**
   * Account holder address
   * @example "123 Main St, New York, NY 10001"
   */
  accountHolderAddress?: string;
};

export type FedwireCounterparty = UtilRequiredKeys<BaseBaseCounterparty, 'disabled' | 'type' | 'rail' | 'profile'> & {
  /**
   * Counterparty routing number
   * @example "123456789"
   */
  routingNumber: string;
  /**
   * Counterparty account number
   * @example "74279579268"
   */
  accountNumber: string;
  errorDescription?: string;
};

export interface FeeConfig {
  feePercent?: number;
  feeFixed?: number;
  feeMin?: number;
  feeMax?: number;
}

export interface FeeConfigDto {
  feePercent?: number;
  feeFixed?: number;
  feeMin?: number;
  feeMax?: number;
}

export type GetAddresses200ResponseInnerDto = object;

export enum HttpStatus {
  Value100CONTINUE = '100 CONTINUE',
  Value101SWITCHINGPROTOCOLS = '101 SWITCHING_PROTOCOLS',
  Value102PROCESSING = '102 PROCESSING',
  Value103EARLYHINTS = '103 EARLY_HINTS',
  Value103CHECKPOINT = '103 CHECKPOINT',
  Value200OK = '200 OK',
  Value201CREATED = '201 CREATED',
  Value202ACCEPTED = '202 ACCEPTED',
  Value203NONAUTHORITATIVEINFORMATION = '203 NON_AUTHORITATIVE_INFORMATION',
  Value204NOCONTENT = '204 NO_CONTENT',
  Value205RESETCONTENT = '205 RESET_CONTENT',
  Value206PARTIALCONTENT = '206 PARTIAL_CONTENT',
  Value207MULTISTATUS = '207 MULTI_STATUS',
  Value208ALREADYREPORTED = '208 ALREADY_REPORTED',
  Value226IMUSED = '226 IM_USED',
  Value300MULTIPLECHOICES = '300 MULTIPLE_CHOICES',
  Value301MOVEDPERMANENTLY = '301 MOVED_PERMANENTLY',
  Value302FOUND = '302 FOUND',
  Value302MOVEDTEMPORARILY = '302 MOVED_TEMPORARILY',
  Value303SEEOTHER = '303 SEE_OTHER',
  Value304NOTMODIFIED = '304 NOT_MODIFIED',
  Value305USEPROXY = '305 USE_PROXY',
  Value307TEMPORARYREDIRECT = '307 TEMPORARY_REDIRECT',
  Value308PERMANENTREDIRECT = '308 PERMANENT_REDIRECT',
  Value400BADREQUEST = '400 BAD_REQUEST',
  Value401UNAUTHORIZED = '401 UNAUTHORIZED',
  Value402PAYMENTREQUIRED = '402 PAYMENT_REQUIRED',
  Value403FORBIDDEN = '403 FORBIDDEN',
  Value404NOTFOUND = '404 NOT_FOUND',
  Value405METHODNOTALLOWED = '405 METHOD_NOT_ALLOWED',
  Value406NOTACCEPTABLE = '406 NOT_ACCEPTABLE',
  Value407PROXYAUTHENTICATIONREQUIRED = '407 PROXY_AUTHENTICATION_REQUIRED',
  Value408REQUESTTIMEOUT = '408 REQUEST_TIMEOUT',
  Value409CONFLICT = '409 CONFLICT',
  Value410GONE = '410 GONE',
  Value411LENGTHREQUIRED = '411 LENGTH_REQUIRED',
  Value412PRECONDITIONFAILED = '412 PRECONDITION_FAILED',
  Value413PAYLOADTOOLARGE = '413 PAYLOAD_TOO_LARGE',
  Value413REQUESTENTITYTOOLARGE = '413 REQUEST_ENTITY_TOO_LARGE',
  Value414URITOOLONG = '414 URI_TOO_LONG',
  Value414REQUESTURITOOLONG = '414 REQUEST_URI_TOO_LONG',
  Value415UNSUPPORTEDMEDIATYPE = '415 UNSUPPORTED_MEDIA_TYPE',
  Value416REQUESTEDRANGENOTSATISFIABLE = '416 REQUESTED_RANGE_NOT_SATISFIABLE',
  Value417EXPECTATIONFAILED = '417 EXPECTATION_FAILED',
  Value418IAMATEAPOT = '418 I_AM_A_TEAPOT',
  Value419INSUFFICIENTSPACEONRESOURCE = '419 INSUFFICIENT_SPACE_ON_RESOURCE',
  Value420METHODFAILURE = '420 METHOD_FAILURE',
  Value421DESTINATIONLOCKED = '421 DESTINATION_LOCKED',
  Value422UNPROCESSABLEENTITY = '422 UNPROCESSABLE_ENTITY',
  Value423LOCKED = '423 LOCKED',
  Value424FAILEDDEPENDENCY = '424 FAILED_DEPENDENCY',
  Value425TOOEARLY = '425 TOO_EARLY',
  Value426UPGRADEREQUIRED = '426 UPGRADE_REQUIRED',
  Value428PRECONDITIONREQUIRED = '428 PRECONDITION_REQUIRED',
  Value429TOOMANYREQUESTS = '429 TOO_MANY_REQUESTS',
  Value431REQUESTHEADERFIELDSTOOLARGE = '431 REQUEST_HEADER_FIELDS_TOO_LARGE',
  Value451UNAVAILABLEFORLEGALREASONS = '451 UNAVAILABLE_FOR_LEGAL_REASONS',
  Value500INTERNALSERVERERROR = '500 INTERNAL_SERVER_ERROR',
  Value501NOTIMPLEMENTED = '501 NOT_IMPLEMENTED',
  Value502BADGATEWAY = '502 BAD_GATEWAY',
  Value503SERVICEUNAVAILABLE = '503 SERVICE_UNAVAILABLE',
  Value504GATEWAYTIMEOUT = '504 GATEWAY_TIMEOUT',
  Value505HTTPVERSIONNOTSUPPORTED = '505 HTTP_VERSION_NOT_SUPPORTED',
  Value506VARIANTALSONEGOTIATES = '506 VARIANT_ALSO_NEGOTIATES',
  Value507INSUFFICIENTSTORAGE = '507 INSUFFICIENT_STORAGE',
  Value508LOOPDETECTED = '508 LOOP_DETECTED',
  Value509BANDWIDTHLIMITEXCEEDED = '509 BANDWIDTH_LIMIT_EXCEEDED',
  Value510NOTEXTENDED = '510 NOT_EXTENDED',
  Value511NETWORKAUTHENTICATIONREQUIRED = '511 NETWORK_AUTHENTICATION_REQUIRED',
}

export enum HttpStatusDto {
  Value100CONTINUE = '100 CONTINUE',
  Value101SWITCHINGPROTOCOLS = '101 SWITCHING_PROTOCOLS',
  Value102PROCESSING = '102 PROCESSING',
  Value103EARLYHINTS = '103 EARLY_HINTS',
  Value103CHECKPOINT = '103 CHECKPOINT',
  Value200OK = '200 OK',
  Value201CREATED = '201 CREATED',
  Value202ACCEPTED = '202 ACCEPTED',
  Value203NONAUTHORITATIVEINFORMATION = '203 NON_AUTHORITATIVE_INFORMATION',
  Value204NOCONTENT = '204 NO_CONTENT',
  Value205RESETCONTENT = '205 RESET_CONTENT',
  Value206PARTIALCONTENT = '206 PARTIAL_CONTENT',
  Value207MULTISTATUS = '207 MULTI_STATUS',
  Value208ALREADYREPORTED = '208 ALREADY_REPORTED',
  Value226IMUSED = '226 IM_USED',
  Value300MULTIPLECHOICES = '300 MULTIPLE_CHOICES',
  Value301MOVEDPERMANENTLY = '301 MOVED_PERMANENTLY',
  Value302FOUND = '302 FOUND',
  Value302MOVEDTEMPORARILY = '302 MOVED_TEMPORARILY',
  Value303SEEOTHER = '303 SEE_OTHER',
  Value304NOTMODIFIED = '304 NOT_MODIFIED',
  Value305USEPROXY = '305 USE_PROXY',
  Value307TEMPORARYREDIRECT = '307 TEMPORARY_REDIRECT',
  Value308PERMANENTREDIRECT = '308 PERMANENT_REDIRECT',
  Value400BADREQUEST = '400 BAD_REQUEST',
  Value401UNAUTHORIZED = '401 UNAUTHORIZED',
  Value402PAYMENTREQUIRED = '402 PAYMENT_REQUIRED',
  Value403FORBIDDEN = '403 FORBIDDEN',
  Value404NOTFOUND = '404 NOT_FOUND',
  Value405METHODNOTALLOWED = '405 METHOD_NOT_ALLOWED',
  Value406NOTACCEPTABLE = '406 NOT_ACCEPTABLE',
  Value407PROXYAUTHENTICATIONREQUIRED = '407 PROXY_AUTHENTICATION_REQUIRED',
  Value408REQUESTTIMEOUT = '408 REQUEST_TIMEOUT',
  Value409CONFLICT = '409 CONFLICT',
  Value410GONE = '410 GONE',
  Value411LENGTHREQUIRED = '411 LENGTH_REQUIRED',
  Value412PRECONDITIONFAILED = '412 PRECONDITION_FAILED',
  Value413PAYLOADTOOLARGE = '413 PAYLOAD_TOO_LARGE',
  Value413REQUESTENTITYTOOLARGE = '413 REQUEST_ENTITY_TOO_LARGE',
  Value414URITOOLONG = '414 URI_TOO_LONG',
  Value414REQUESTURITOOLONG = '414 REQUEST_URI_TOO_LONG',
  Value415UNSUPPORTEDMEDIATYPE = '415 UNSUPPORTED_MEDIA_TYPE',
  Value416REQUESTEDRANGENOTSATISFIABLE = '416 REQUESTED_RANGE_NOT_SATISFIABLE',
  Value417EXPECTATIONFAILED = '417 EXPECTATION_FAILED',
  Value418IAMATEAPOT = '418 I_AM_A_TEAPOT',
  Value419INSUFFICIENTSPACEONRESOURCE = '419 INSUFFICIENT_SPACE_ON_RESOURCE',
  Value420METHODFAILURE = '420 METHOD_FAILURE',
  Value421DESTINATIONLOCKED = '421 DESTINATION_LOCKED',
  Value422UNPROCESSABLEENTITY = '422 UNPROCESSABLE_ENTITY',
  Value423LOCKED = '423 LOCKED',
  Value424FAILEDDEPENDENCY = '424 FAILED_DEPENDENCY',
  Value425TOOEARLY = '425 TOO_EARLY',
  Value426UPGRADEREQUIRED = '426 UPGRADE_REQUIRED',
  Value428PRECONDITIONREQUIRED = '428 PRECONDITION_REQUIRED',
  Value429TOOMANYREQUESTS = '429 TOO_MANY_REQUESTS',
  Value431REQUESTHEADERFIELDSTOOLARGE = '431 REQUEST_HEADER_FIELDS_TOO_LARGE',
  Value451UNAVAILABLEFORLEGALREASONS = '451 UNAVAILABLE_FOR_LEGAL_REASONS',
  Value500INTERNALSERVERERROR = '500 INTERNAL_SERVER_ERROR',
  Value501NOTIMPLEMENTED = '501 NOT_IMPLEMENTED',
  Value502BADGATEWAY = '502 BAD_GATEWAY',
  Value503SERVICEUNAVAILABLE = '503 SERVICE_UNAVAILABLE',
  Value504GATEWAYTIMEOUT = '504 GATEWAY_TIMEOUT',
  Value505HTTPVERSIONNOTSUPPORTED = '505 HTTP_VERSION_NOT_SUPPORTED',
  Value506VARIANTALSONEGOTIATES = '506 VARIANT_ALSO_NEGOTIATES',
  Value507INSUFFICIENTSTORAGE = '507 INSUFFICIENT_STORAGE',
  Value508LOOPDETECTED = '508 LOOP_DETECTED',
  Value509BANDWIDTHLIMITEXCEEDED = '509 BANDWIDTH_LIMIT_EXCEEDED',
  Value510NOTEXTENDED = '510 NOT_EXTENDED',
  Value511NETWORKAUTHENTICATIONREQUIRED = '511 NETWORK_AUTHENTICATION_REQUIRED',
  UnknownDefaultOpenApi = 'unknown_default_open_api',
}

export type ImpsCounterparty = UtilRequiredKeys<
  BaseBaseCounterparty,
  'disabled' | 'type' | 'rail' | 'institutionName' | 'institutionAddress' | 'profile'
> & {
  institutionName: string;
  /**
   * Counterparty account number
   * @example "74279579268"
   */
  accountNumber: string;
  /**
   * Counterparty ifsc number
   * @example "ICIC0000266"
   */
  ifsc: string;
  errorDescription?: string;
};

export type InlineResponse200Dto = object;

export interface InvoiceWebhookDto {
  status?: PaymentStatusDto;
}

export type InwizoInvoicePaymentDto = UtilRequiredKeys<BaseBaseInvoicePaymentDto, 'invoiceId' | 'type'> & {
  someData?: string;
};

export type ItezInvoicePaymentDto = UtilRequiredKeys<BaseBaseInvoicePaymentDto, 'invoiceId' | 'type'> & {
  someData?: string;
};

export type JsonNode = object;

export interface Layer2EventDtoDto {
  /** @format uuid */
  event_id: string;
  event_type: string;
  /** @format date-time */
  event_date: string;
  event_data: object;
}

export interface LimitsDto {
  withdrawDay?: number;
  withdrawMonth?: number;
  withdrawYear?: number;
  withdrawGlobal?: number;
  currency: string;
}

export interface LimitsDtoDto {
  withdrawDay?: number;
  withdrawMonth?: number;
  withdrawYear?: number;
  withdrawGlobal?: number;
  currency: string;
}

export enum LineOfBusiness {
  ARTS_AND_ANTIQUES = 'ARTS_AND_ANTIQUES',
  AGRICULTURE = 'AGRICULTURE',
  AUDIT_VIDEO = 'AUDIT_VIDEO',
  AUTOMOTIVE = 'AUTOMOTIVE',
  BANK_CREDIT_UNION = 'BANK_CREDIT_UNION',
  BIOTECH_PHARMACEUTICAL = 'BIOTECH_PHARMACEUTICAL',
  BOOKSELLER = 'BOOKSELLER',
  CONSTRUCTION = 'CONSTRUCTION',
  CASINOS_GAMBLING = 'CASINOS_GAMBLING',
  CLOTHING = 'CLOTHING',
  CONSULTING_PROFESSIONAL_SERVICES = 'CONSULTING_PROFESSIONAL_SERVICES',
  CRYPTOCURRENCY = 'CRYPTOCURRENCY',
  ECIGARETTES_TOBACCO = 'ECIGARETTES_TOBACCO',
  ENERGY_CHEMICAL_FUEL = 'ENERGY_CHEMICAL_FUEL',
  ENGINEERING = 'ENGINEERING',
  ADULT_ENTERTAINMENT = 'ADULT_ENTERTAINMENT',
  FINANCIAL_AND_CORPORATE_SERVICES = 'FINANCIAL_AND_CORPORATE_SERVICES',
  FOOD_AND_BEVERAGE = 'FOOD_AND_BEVERAGE',
  FREIGHT_MARINE_SHIPPING_SERVICES = 'FREIGHT_MARINE_SHIPPING_SERVICES',
  GIFTWARE_SEASONAL = 'GIFTWARE_SEASONAL',
  GOVERNMENT_PUBLIC_SERVICES = 'GOVERNMENT_PUBLIC_SERVICES',
  HIGH_TECH_SOFTWARE_TELECOMS = 'HIGH_TECH_SOFTWARE_TELECOMS',
  HOSPITALITY = 'HOSPITALITY',
  HOUSEHOLD_GOOD_FURNITURE = 'HOUSEHOLD_GOOD_FURNITURE',
  HUMAN_RESOURCE_SERVICES = 'HUMAN_RESOURCE_SERVICES',
  INDUSTRIAL_EQUIPMENT = 'INDUSTRIAL_EQUIPMENT',
  INFORMATION_TECHNOLOGY = 'INFORMATION_TECHNOLOGY',
  INSURANCE = 'INSURANCE',
  JEWELLERY_OPTICAL = 'JEWELLERY_OPTICAL',
  LAW = 'LAW',
  MANUFACTURING = 'MANUFACTURING',
  MARKETING = 'MARKETING',
  MEDIA_PUBLISHING = 'MEDIA_PUBLISHING',
  MEDICAL_HEALTHCARE = 'MEDICAL_HEALTHCARE',
  MEDICAL_TOURISM = 'MEDICAL_TOURISM',
  MINING = 'MINING',
  MONEY_SERVICE_BUSINESS = 'MONEY_SERVICE_BUSINESS',
  NON_PROFIT_CHARITY = 'NON_PROFIT_CHARITY',
  ONLINE_RETAIL = 'ONLINE_RETAIL',
  PAYROLL = 'PAYROLL',
  PENSION = 'PENSION',
  PERSONAL_CARE_PRODUCTS = 'PERSONAL_CARE_PRODUCTS',
  PHOTOGRAPHY = 'PHOTOGRAPHY',
  REAL_ESTATE = 'REAL_ESTATE',
  RECREATIONAL_ACTIVITIES = 'RECREATIONAL_ACTIVITIES',
  RELIGIOUS_ORGANISATION = 'RELIGIOUS_ORGANISATION',
  RETAIL = 'RETAIL',
  SECURITY = 'SECURITY',
  SPORTING_RECREATIONAL_PRODUCTS = 'SPORTING_RECREATIONAL_PRODUCTS',
  TRANSPORT = 'TRANSPORT',
  UNIVERSITY_EDUCATION = 'UNIVERSITY_EDUCATION',
  UTILITIES = 'UTILITIES',
  WASTE_MANAGEMENT = 'WASTE_MANAGEMENT',
  WINE_LIQUOR = 'WINE_LIQUOR',
  TECHNICAL_SUPPORT = 'TECHNICAL_SUPPORT',
  OTHER = 'OTHER',
}

export interface OperationDto {
  id: string;
  accountId: string;
  operationType: OperationTypeDto;
  deposit?: DepositDto;
  withdraw?: WithdrawDto;
  exchange?: DirectExchangeDto;
  transfer?: TransferDto;
  payin?: PayinDto;
  payout?: PayoutDto;
  linkedBalanceLog: BalanceLogDto[];
  /**
   * Datetime of request being accepted
   * @format date-time
   */
  createdAt: string;
  /**
   * Last Datetime of request status being updated
   * @format date-time
   */
  updatedAt: string;
}

export interface OperationSearch {
  type: OperationType;
  withdraw?:
    | BaseAdminWithdraw
    | AdminAtmWithdraw
    | AdminBankTransferWithdraw
    | AdminCounterPartyWithdraw
    | AdminCryptoWithdraw
    | AdminPaySpotWithdraw
    | AdminPayoutWithdraw;
  deposit?: AdminDeposit;
  transfer?: AdminDirectTransfer;
  exchange?: AdminDirectExchange;
}

export enum OperationType {
  WITHDRAW = 'WITHDRAW',
  DEPOSIT = 'DEPOSIT',
  TRANSFER = 'TRANSFER',
  EXCHANGE = 'EXCHANGE',
}

export enum OperationTypeDto {
  DEPOSIT = 'DEPOSIT',
  WITHDRAW = 'WITHDRAW',
  PAYIN = 'PAYIN',
  PAYOUT = 'PAYOUT',
  EXCHANGE = 'EXCHANGE',
  TRANSFER = 'TRANSFER',
  UnknownDefaultOpenApi = 'unknown_default_open_api',
}

export type OriginalInvoiceDto = UtilRequiredKeys<BaseBaseInvoiceDto, 'amount' | 'currency' | 'type' | 'alignable'> & {
  /** Status of current invoice different for sub types */
  status?: string;
  /** List of payments */
  payments?: (
    | CheckoutInvoicePaymentDto
    | ExternalInvoicePaymentDto
    | InwizoInvoicePaymentDto
    | ItezInvoicePaymentDto
    | PlatformInvoicePaymentDto
    | StripeInvoicePaymentDto
  )[];
  /** Discount code, that was applied to this invoice */
  discountCode?: string;
  /** Base invoice amount, which shows that discount was applied */
  amountBeforeDiscount?: number;
  /** @format date-time */
  lastModifiedDate?: string;
  recurrentInvoiceId?: string;
};

export interface PageableResponseAdminAccount {
  data: AdminAccount[];
  /** @format int32 */
  currentPage: number;
  /** @format int64 */
  totalItems: number;
  /** @format int32 */
  totalPages: number;
}

export interface PageableResponseAdminDeposit {
  data: AdminDeposit[];
  /** @format int32 */
  currentPage: number;
  /** @format int64 */
  totalItems: number;
  /** @format int32 */
  totalPages: number;
}

export interface PageableResponseAdminDirectExchange {
  data: AdminDirectExchange[];
  /** @format int32 */
  currentPage: number;
  /** @format int64 */
  totalItems: number;
  /** @format int32 */
  totalPages: number;
}

export interface PageableResponseBalanceLog {
  data: BalanceLog[];
  /** @format int32 */
  currentPage: number;
  /** @format int64 */
  totalItems: number;
  /** @format int32 */
  totalPages: number;
}

export interface PageableResponseBalanceLogDto {
  data: BalanceLogDto[];
  /** @format int32 */
  currentPage: number;
  /** @format int64 */
  totalItems: number;
  /** @format int32 */
  totalPages: number;
}

export interface PageableResponseBaseAdminWithdraw {
  data: (
    | BaseAdminWithdraw
    | AdminAtmWithdraw
    | AdminBankTransferWithdraw
    | AdminCounterPartyWithdraw
    | AdminCryptoWithdraw
    | AdminPaySpotWithdraw
    | AdminPayoutWithdraw
  )[];
  /** @format int32 */
  currentPage: number;
  /** @format int64 */
  totalItems: number;
  /** @format int32 */
  totalPages: number;
}

export interface PageableResponseBaseCounterparty {
  data: (
    | ACHCounterparty
    | CryptoCounterparty
    | EftCounterparty
    | FedwireCounterparty
    | ImpsCounterparty
    | SepaCounterparty
    | SwiftCounterparty
  )[];
  /** @format int32 */
  currentPage: number;
  /** @format int64 */
  totalItems: number;
  /** @format int32 */
  totalPages: number;
}

export type PageableResponseBaseCounterpartyDataInnerDto = object;

export interface PageableResponseBaseCounterpartyDto {
  data: PageableResponseBaseCounterpartyDataInnerDto[];
  /** @format int32 */
  currentPage: number;
  /** @format int64 */
  totalItems: number;
  /** @format int32 */
  totalPages: number;
}

export interface PageableResponseOperationsDto {
  data: OperationDto[];
  /** @format int32 */
  currentPage: number;
  /** @format int64 */
  totalItems: number;
  /** @format int32 */
  totalPages: number;
}

export type PaySpotWithdraw = UtilRequiredKeys<BaseBaseWithdraw, 'currency' | 'amount' | 'type' | 'fromAccount'> & {
  beneficiaryAccountNumber?: string;
  beneficiaryName?: string;
  /** @format int32 */
  paymentCode?: number;
  paymentPurpose?: string;
  modul?: string;
};

export interface Payin {
  /**
   * Payin Id
   * @format uuid
   */
  id?: string;
  /** Payin Id */
  invoiceId?: string;
  /**
   * User account
   * @format uuid
   */
  account: string;
  /**
   * From fiat currency short name
   * @default "EUR"
   */
  fromCurrency?: string;
  /**
   * To crypto currency short name
   * @example "EPI"
   */
  toCurrency: string;
  /**
   * From amount of fiat currency
   * @format number
   * @example "10.2"
   */
  fromAmount?: string;
  /**
   * To amount of currency
   * @format number
   * @example "9.87"
   */
  toAmount?: string;
  /**
   * Fee amount
   * @format number
   * @example "9.87"
   */
  feeAmount?: string;
  /**
   * Fee currency short name
   * @example "EPI"
   */
  feeCurrency?: string;
  signedRate?: SignedRate;
  /**
   * Status of payin:
   * * `PENDING` - request is processing
   * * `SUCCESS` - request is success (final status)
   * * `FAILED` - request is failed, see `note` for comments (final status)
   * @example "SUCCESS"
   */
  status: string;
  /**
   * Datetime of request being accepted
   * @format date-time
   */
  createdAt?: string;
  /**
   * Last Datetime of request status being updated
   * @format date-time
   */
  updatedAt?: string;
}

export interface PayinDto {
  /**
   * Payin Id
   * @format uuid
   */
  id?: string;
  /** Payin Id */
  invoiceId?: string;
  /**
   * User account
   * @format uuid
   */
  account: string;
  /** From fiat currency short name */
  fromCurrency?: string;
  /**
   * To crypto currency short name
   * @example "EPI"
   */
  toCurrency: string;
  /**
   * From amount of fiat currency
   * @example 10.2
   */
  fromAmount?: number;
  /**
   * To amount of currency
   * @example 9.87
   */
  toAmount?: number;
  /**
   * Fee amount
   * @example 9.87
   */
  feeAmount?: number;
  /**
   * Fee currency short name
   * @example "EPI"
   */
  feeCurrency?: string;
  /**
   * Exchange rate
   * @example 9.87
   */
  rate?: number;
  status: PayinStatusDto;
  /**
   * Datetime of request being accepted
   * @format date-time
   */
  createdAt?: string;
  /**
   * Last Datetime of request status being updated
   * @format date-time
   */
  updatedAt?: string;
}

export enum PayinStatusDto {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  UnknownDefaultOpenApi = 'unknown_default_open_api',
}

/** payment status */
export enum PaymentStatus {
  INIT = 'INIT',
  PROCESSING = 'PROCESSING',
  SUCCESS = 'SUCCESS',
  CAPTURED = 'CAPTURED',
  REJECTED = 'REJECTED',
  REVERSED = 'REVERSED',
  REVERSED_DECLINED = 'REVERSED_DECLINED',
  FAILED = 'FAILED',
}

export enum PaymentStatusDto {
  INIT = 'INIT',
  PROCESSING = 'PROCESSING',
  SUCCESS = 'SUCCESS',
  CAPTURED = 'CAPTURED',
  REJECTED = 'REJECTED',
  REVERSED = 'REVERSED',
  REVERSED_DECLINED = 'REVERSED_DECLINED',
  FAILED = 'FAILED',
  UnknownDefaultOpenApi = 'unknown_default_open_api',
}

export interface PayoutDto {
  /**
   * Payin Id
   * @format uuid
   */
  id?: string;
  /** Payin Id */
  invoiceId?: string;
  /**
   * User account
   * @format uuid
   */
  account: string;
  /** From fiat currency short name */
  fromCurrency?: string;
  /**
   * To crypto currency short name
   * @example "EPI"
   */
  toCurrency: string;
  /**
   * From amount of fiat currency
   * @example 10.2
   */
  fromAmount?: number;
  /**
   * To amount of currency
   * @example 9.87
   */
  toAmount?: number;
  /**
   * Fee amount
   * @example 9.87
   */
  feeAmount?: number;
  /**
   * Fee currency short name
   * @example "EPI"
   */
  feeCurrency?: string;
  /**
   * Exchange rate
   * @example 9.87
   */
  rate?: number;
  status: PayoutStatusDto;
  /**
   * Datetime of request being accepted
   * @format date-time
   */
  createdAt?: string;
  /**
   * Last Datetime of request status being updated
   * @format date-time
   */
  updatedAt?: string;
}

export enum PayoutStatusDto {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  UnknownDefaultOpenApi = 'unknown_default_open_api',
}

export type PayoutWithdraw = UtilRequiredKeys<BaseBaseWithdraw, 'currency' | 'amount' | 'type' | 'fromAccount'> & {
  invoiceId?: string;
};

export type PlatformInvoicePaymentDto = UtilRequiredKeys<BaseBaseInvoicePaymentDto, 'invoiceId' | 'type'> & {
  /** internal account for payment */
  accountId?: string;
  meta?: string;
};

/** Model of prepaid card account */
export type PrepaidCardAccount = UtilRequiredKeys<BaseAccount, 'account' | 'accountType' | 'shortName'> & {
  cardId?: string;
  cardProvider?: string;
};

export interface ProfileDto {
  profileType: CounterpartyProfileType;
  name: string;
  email?: string;
  telephoneNumber?: string;
  taxReferenceNumber?: string;
  /** User address */
  address?: UserAddress;
  lineOfBusiness?: LineOfBusiness;
  relationshipToCustomer: RelationType;
}

export interface QrResponse {
  type: QrResponseType;
  withdraw?:
    | BaseWithdraw
    | AtmWithdraw
    | BankTransferWithdraw
    | CounterPartyWithdraw
    | CryptoWithdraw
    | PaySpotWithdraw
    | PayoutWithdraw;
}

export interface QrResponseDto {
  type: QrResponseTypeDto;
  withdraw?: QrResponseWithdrawDto;
}

export enum QrResponseType {
  WITHDRAW = 'WITHDRAW',
}

export enum QrResponseTypeDto {
  WITHDRAW = 'WITHDRAW',
  UnknownDefaultOpenApi = 'unknown_default_open_api',
}

export type QrResponseWithdrawDto = object;

export interface RateRequest {
  fromCurrency: string;
  toCurrency: string;
  /** @format number */
  amount: string;
  account?: string;
  partner?: string;
}

export interface RateRequestDto {
  fromCurrency: string;
  toCurrency: string;
  amount: number;
  account?: string;
  partner?: string;
}

export interface Receipt {
  note?: string;
  rail?: string;
  rail_reference?: string;
  purpose?: string;
  memo?: string;
  originator_name?: string;
  originator_address?: string;
  originator_blockchain_address?: string;
  originator_blockchain_network?: string;
  originator_account_number?: string;
  originator_routing_number?: string;
  originator_swift_bic?: string;
  originator_institution_name?: string;
  originator_institution_address?: string;
  exchange_type?: string;
  exchange_source_account_id?: string;
  exchange_destination_account_id?: string;
  exchange_amount_debit?: string;
  exchange_amount_credit?: string;
  counterparty_name?: string;
  counterparty_address?: string;
  counterparty_blockchain_address?: string;
  counterparty_blockchain_network?: string;
  counterparty_account_number?: string;
  counterparty_routing_number?: string;
  counterparty_swift_bic?: string;
  counterparty_institution_name?: string;
  counterparty_institution_address?: string;
  tranfer_source_account_id?: string;
  tranfer_destination_account_id?: string;
  tranfer_amount_debit?: string;
  tranfer_amount_credit?: string;
}

export interface ReceiptDto {
  note?: string;
  rail?: string;
  rail_reference?: string;
  purpose?: string;
  memo?: string;
  originator_name?: string;
  originator_address?: string;
  originator_blockchain_address?: string;
  originator_blockchain_network?: string;
  originator_account_number?: string;
  originator_routing_number?: string;
  originator_swift_bic?: string;
  originator_institution_name?: string;
  originator_institution_address?: string;
  exchange_type?: string;
  exchange_source_account_id?: string;
  exchange_destination_account_id?: string;
  exchange_amount_debit?: string;
  exchange_amount_credit?: string;
  counterparty_name?: string;
  counterparty_address?: string;
  counterparty_blockchain_address?: string;
  counterparty_blockchain_network?: string;
  counterparty_account_number?: string;
  counterparty_routing_number?: string;
  counterparty_swift_bic?: string;
  counterparty_institution_name?: string;
  counterparty_institution_address?: string;
  tranfer_source_account_id?: string;
  tranfer_destination_account_id?: string;
  tranfer_amount_debit?: string;
  tranfer_amount_credit?: string;
}

export type RecurrentInvoiceDto = UtilRequiredKeys<BaseBaseInvoiceDto, 'amount' | 'currency' | 'type' | 'alignable'> & {
  /**
   * Date of the next payment for this recurrent invoice
   * @format int64
   */
  nextPaymentDate?: number;
  /**
   * Amount of first payment for this recurrent invoice
   * @example 0
   */
  initialPaymentAmount?: number;
  /** Current recurrent invoice status */
  status?: string;
  /** List of linked invoices for payment. */
  linkedInvoices?: OriginalInvoiceDto[];
  /** Interval of current recurrent invoice */
  recurrentInvoiceInterval?: RecurrentInvoiceInterval;
  /** Internal account for recurrent payment */
  paymentAccountId?: string;
  anyCurrency?: boolean;
  autoPayment?: boolean;
};

/** Interval of current recurrent invoice */
export enum RecurrentInvoiceInterval {
  DAYS30 = 'DAYS30',
  DAYS365 = 'DAYS365',
}

export enum RelationType {
  SPOUSE = 'SPOUSE',
  EX_SPOUSE = 'EX_SPOUSE',
  CHILDREN = 'CHILDREN',
  PARENT = 'PARENT',
  SIBLING = 'SIBLING',
  RELATIVE = 'RELATIVE',
  SELF = 'SELF',
  FRIEND = 'FRIEND',
  BUSINESS_PARTNER = 'BUSINESS_PARTNER',
  CUSTOMER = 'CUSTOMER',
  EMPLOYEE = 'EMPLOYEE',
  BRANCH_OFFICE = 'BRANCH_OFFICE',
  SUBSIDIARY_COMPANY = 'SUBSIDIARY_COMPANY',
  HOLDING_COMPANY = 'HOLDING_COMPANY',
  SUPPLIER = 'SUPPLIER',
  CREDITOR = 'CREDITOR',
  DEBTOR = 'DEBTOR',
  FRANCHISEE = 'FRANCHISEE',
  NOT_RELATED = 'NOT_RELATED',
}

export interface ScanQrRequest {
  data: string;
  accountId: string;
}

export interface ScanQrRequestDto {
  data: string;
  accountId: string;
}

export type SepaAddress = UtilRequiredKeys<BaseBaseDepositAddress, 'accountId' | 'type'> & {
  accountId: string;
  /**
   * Account number
   * @example "1234567890"
   */
  iban: string;
  /**
   * SWIFT/BIC code of the bank
   * @example "BOFAUS3NXXX"
   */
  bic: string;
  /**
   * Memo
   * @example "F1QTZ"
   */
  memo?: string;
  /**
   * Bank name
   * @example "Bank of America"
   */
  bankName?: string;
  /**
   * Bank address
   * @example "123 Main St, New York, NY 10001"
   */
  bankAddress?: string;
  /**
   * Account holder name
   * @example "John Doe"
   */
  accountHolderName: string;
  /**
   * Account holder address
   * @example "123 Main St, New York, NY 10001"
   */
  accountHolderAddress?: string;
};

export type SepaCounterparty = UtilRequiredKeys<
  BaseBaseCounterparty,
  'disabled' | 'type' | 'rail' | 'institutionName' | 'institutionAddress' | 'profile'
> & {
  institutionName: string;
  /**
   * Counterparty International Bank Account Number (IBAN)
   * @example "NL20INGB0001234567"
   */
  iban?: string;
  /**
   * Counterparty SWIFT/BIC Number
   * @pattern [A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?
   * @example "DEUTDEFF"
   */
  swiftBic?: string;
  errorDescription?: string;
};

export interface SignedRate {
  rateRequest?: RateRequest;
  /**
   * Rate for exchange
   * @format number
   * @example "1.12"
   */
  rate: string;
  feeConfig?: FeeConfig;
  /**
   * Min amount for exchange
   * @format number
   * @example "10.2"
   */
  minAmount?: string;
  /**
   * Max amount for exchange
   * @format number
   * @example "100.2"
   */
  maxAmount?: string;
  /** Exchange route */
  exchangeRoute?: ExchangeRoute;
  quoteId?: string;
  validUntil?: string;
  signature?: string;
}

export interface SignedRateDto {
  rateRequest?: RateRequestDto;
  /**
   * Rate for exchange
   * @example 1.12
   */
  rate: number;
  feeConfig?: FeeConfigDto;
  /**
   * Min amount for exchange
   * @example 10.2
   */
  minAmount?: number;
  /**
   * Max amount for exchange
   * @example 100.2
   */
  maxAmount?: number;
  /** Exchange route */
  exchangeRoute?: ExchangeRoute;
  validUntil?: string;
  signature?: string;
}

export type StripeInvoicePaymentDto = UtilRequiredKeys<BaseBaseInvoicePaymentDto, 'invoiceId' | 'type'> & {
  someData?: string;
};

export type SwiftAddress = UtilRequiredKeys<BaseBaseDepositAddress, 'accountId' | 'type'> & {
  accountId: string;
  /**
   * Account number
   * @example "1234567890"
   */
  accountNumber: string;
  /**
   * SWIFT/BIC code of the bank
   * @example "BOFAUS3NXXX"
   */
  swiftBic: string;
  /**
   * Memo
   * @example "F1QTZ"
   */
  memo?: string;
  /**
   * Bank name
   * @example "Bank of America"
   */
  bankName?: string;
  /**
   * Bank address
   * @example "123 Main St, New York, NY 10001"
   */
  bankAddress?: string;
  /**
   * Account holder name
   * @example "John Doe"
   */
  accountHolderName: string;
  /**
   * Account holder address
   * @example "123 Main St, New York, NY 10001"
   */
  accountHolderAddress?: string;
};

export type SwiftCounterparty = UtilRequiredKeys<
  BaseBaseCounterparty,
  'disabled' | 'type' | 'rail' | 'institutionName' | 'institutionAddress' | 'profile'
> & {
  institutionName: string;
  /**
   * Counterparty account number
   * @example "74279579268"
   */
  accountNumber: string;
  /**
   * Counterparty SWIFT/BIC Number
   * @pattern [A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?
   * @example "DEUTDEFF"
   */
  swiftBic?: string;
  intermediary?: SwiftIntermediary;
  errorDescription?: string;
};

export interface SwiftIntermediary {
  currency?: string;
  institutionName: string;
  /** User address */
  institutionAddress: UserAddress;
  /**
   * Counterparty SWIFT/BIC Number
   * @pattern [A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?
   * @example "DEUTDEFF"
   */
  swiftBic?: string;
  routingNumber?: string;
}

export interface TotalBalanceReportDto {
  currency?: string;
  total?: number;
  /** @format int64 */
  partnerId?: number;
}

export type TransferBodyDto = object;

export type TransferDto = object;

export enum TransferStatus {
  INIT = 'INIT',
  PLACED = 'PLACED',
  PROCESSING = 'PROCESSING',
  REJECTED = 'REJECTED',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

export type Unit = object;

/** User address */
export interface UserAddress {
  /** @format uuid */
  id?: string;
  /**
   * Country Alpha-3 code
   * @minLength 3
   * @maxLength 3
   * @example "GBR"
   */
  country?: string;
  /**
   * Post code
   * @example "SW1A 1AA"
   */
  postCode?: string;
  /**
   * State
   * @example "MA"
   */
  state?: string;
  /**
   * Town
   * @example "London"
   */
  town?: string;
  /**
   * Street
   * @minLength 0
   * @maxLength 35
   * @example "Downing Street"
   */
  street?: string;
  /**
   * Sub street (second line of postal address)
   * @minLength 0
   * @maxLength 35
   * @example "10"
   */
  subStreet?: string;
  /**
   * (Optional) Building name
   * @minLength 0
   * @maxLength 35
   * @example "Buckingham Palace"
   */
  buildingName?: string;
  /**
   * (Optional) Flat number
   * @example "1"
   */
  flatNumber?: string;
  /**
   * (Optional) Building number
   * @example "10"
   */
  buildingNumber?: string;
  confirmed?: boolean;
}

export type WithdrawDto = object;

/** @example "PROCESSED" */
export enum WithdrawStatus {
  INITIAL = 'INITIAL',
  PROCESSING = 'PROCESSING',
  WITHDRAWN = 'WITHDRAWN',
  PENDING = 'PENDING',
  CANCELED = 'CANCELED',
  FAILED = 'FAILED',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
  PLACED = 'PLACED',
  SUCCESS = 'SUCCESS',
  PROCESSED = 'PROCESSED',
  FAILING = 'FAILING',
  REJECTING = 'REJECTING',
}

export enum WithdrawType {
  CRYPTO = 'CRYPTO',
  BANK_TRANSFER = 'BANK_TRANSFER',
  ATM = 'ATM',
  PAY_SPOT = 'PAY_SPOT',
  COUNTERPARTY = 'COUNTERPARTY',
  PAYOUT = 'PAYOUT',
}

interface BaseBaseAdminWithdraw {
  /** @format uuid */
  id?: string;
  /** @pattern [a-zA-Z]+ */
  currency: string;
  /**
   * From amount of currency
   * @format number
   * @example "10.2"
   */
  amount: string;
  type: WithdrawType;
  meta?: JsonNode;
  fromAccount?: string;
  /**
   * From amount of currency
   * @format number
   * @example "10.2"
   */
  feeAmount?: string;
  status?: WithdrawStatus;
  /**
   * Datetime of request being accepted
   * @format date-time
   */
  createdAt?: string;
  /**
   * Last Datetime of request status being updated
   * @format date-time
   */
  updatedAt?: string;
  balanceLogs: BalanceLog[];
}

type BaseBaseAdminWithdrawTypeMapping<Key, Type> = {
  type: Key;
} & Type;

interface BaseBaseDepositAddress {
  /**
   * Account id
   * @example "1234567890"
   */
  accountId: string;
  /** @example "CRYPTO" */
  type: string;
}

type BaseBaseDepositAddressTypeMapping<Key, Type> = {
  type: Key;
} & Type;

/** invoice payment info */
interface BaseBaseInvoicePaymentDto {
  /** id of invoice payment */
  id?: string;
  invoiceId: string;
  /** payment status */
  status?: PaymentStatus;
  /** type of payment */
  type: string;
  /** amount */
  amount?: string;
  /** description */
  description?: string;
  /** payment status description */
  statusDescription?: string;
  /**
   * date of last payment modification
   * @format date-time
   */
  last_modified_date?: string;
  /**
   * date of payment creation
   * @format date-time
   */
  created_date?: string;
}

type BaseBaseInvoicePaymentDtoTypeMapping<Key, Type> = {
  type: Key;
} & Type;

/** Object for merchant, invoice info */
interface BaseBaseInvoiceDto {
  /**
   * field for client_id in external system
   * @example "8b3d1cdc-cd79-42ae-8040-7404c35a3026"
   */
  externalClientId?: string;
  /**
   * invoice id
   * @example "8b3d1cdc-cd79-42ae-8040-7404c35a3026"
   */
  id?: string;
  /**
   * Amount required to complete invoice
   * @example 0
   */
  amount: number;
  /**
   * Currency slug in internal format
   * @example "EUR"
   */
  currency: string;
  /**
   * Date of the payment of this invoice
   * @format int64
   */
  paymentDate?: number;
  /** type of invoice */
  type: string;
  /** Defines ability to apply discount code by invoice */
  alignable: boolean;
  /** Description of invoice */
  description?: string;
}

type BaseBaseInvoiceDtoTypeMapping<Key, Type> = {
  type: Key;
} & Type;

interface BaseBaseWithdraw {
  /** @format uuid */
  id?: string;
  /** @pattern [a-zA-Z0-9]+ */
  currency: string;
  /**
   * From amount of currency
   * @format number
   * @example "10.2"
   */
  amount: string;
  conversion?: ConversionFunds[];
  /** Withdraw type */
  type: string;
  meta?: JsonNode;
  fromAccount: string;
  /**
   * From amount of currency
   * @format number
   * @example "10.2"
   */
  feeAmount?: string;
  status?: WithdrawStatus;
  /**
   * Datetime of request being accepted
   * @format date-time
   */
  createdAt?: string;
  /**
   * Last Datetime of request status being updated
   * @format date-time
   */
  updatedAt?: string;
}

type BaseBaseWithdrawTypeMapping<Key, Type> = {
  type: Key;
} & Type;

/** Model of counterparty */
interface BaseBaseCounterparty {
  /**
   * UUID of counterparty
   * @format uuid
   * @example "8b3d1cdc-cd79-42ae-8040-7404c35a3026"
   */
  id?: string;
  /**
   * Counterparty is disabled
   * @example false
   */
  disabled: boolean;
  /** Type of counterparty */
  type: CounterpartyType;
  /** Type of rail */
  rail: CounterpartyRailType;
  /**
   * Currency of counterparty, required for some types of counterparty and providers
   * @example "USD"
   */
  currency?: string;
  /**
   * Name of institution
   * @example "Bank of America or Kraken"
   */
  institutionName?: string;
  /** User address */
  institutionAddress?: UserAddress;
  /**
   * Description of counterparty
   * @example "Counterparty for trading"
   */
  description?: string;
  profile: ProfileDto;
  /**
   * Status of counterparty
   * @example "ACTIVE"
   */
  status?: string;
  /**
   * Error description
   * @example "Invalid address"
   */
  errorDescription?: string;
}

type BaseBaseCounterpartyRailMapping<Key, Type> = {
  rail: Key;
} & Type;

/** Model of account */
interface BaseAccount {
  /**
   * UUID or shortname of account id
   * @example "8b3d1cdc-cd79-42ae-8040-7404c35a3026"
   */
  account: string;
  /** Account type, to difference between them */
  accountType: string;
  /** User defined short name of account */
  shortName: string;
  /** Status of account */
  status?: AccountStatus;
}

type BaseAccountAccountTypeMapping<Key, Type> = {
  accountType: Key;
} & Type;

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from 'axios';
import axios from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || 'https://api.stg.darknet.piefi.app/wallet',
    });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Wallet
 * @version 0.3.0-b29c40de
 * @baseUrl https://api.stg.darknet.piefi.app/wallet
 *
 * API for interactions with account balances, like creating spend operations and retrieve all corresponding operations.
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  counterparty = {
    /**
     * @description Get counterparty by id for the current user.
     *
     * @tags Counterparty
     * @name GetCounterpartiesById
     * @summary Get counterparty by id
     * @request GET:/v2/counterparty/{counterpartyId}
     * @secure
     */
    getCounterpartiesById: (counterpartyId: string, params: RequestParams = {}) =>
      this.request<InlineResponse200Dto, ApiErrorResponseDto | ApiErrorResponse>({
        path: `/v2/counterparty/${counterpartyId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update a counterparty for the current user. Only counterparty with status INIT or ERROR can be updated.
     *
     * @tags Counterparty
     * @name UpdateCounterparty
     * @summary Update a counterparty.
     * @request PUT:/v2/counterparty/{counterpartyId}
     * @secure
     */
    updateCounterparty: (counterpartyId: string, data: CounterpartyCounterpartyIdBodyDto, params: RequestParams = {}) =>
      this.request<CounterpartyCounterpartyIdBodyDto, ApiErrorResponseDto | ApiErrorResponse>({
        path: `/v2/counterparty/${counterpartyId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Counterparty
     * @name DeleteCounterparty
     * @request DELETE:/v2/counterparty/{counterpartyId}
     * @secure
     */
    deleteCounterparty: (counterpartyId: string, params: RequestParams = {}) =>
      this.request<AccountBodyDto, ApiErrorResponseDto | ApiErrorResponse>({
        path: `/v2/counterparty/${counterpartyId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get counterparty by id for the current user.
     *
     * @tags Counterparty
     * @name GetCounterpartiesById1
     * @summary Get counterparty by id
     * @request GET:/counterparty/{uuid}
     * @secure
     */
    getCounterpartiesById1: (uuid: string, params: RequestParams = {}) =>
      this.request<
        | ACHCounterparty
        | CryptoCounterparty
        | EftCounterparty
        | FedwireCounterparty
        | ImpsCounterparty
        | SepaCounterparty
        | SwiftCounterparty,
        ApiErrorResponse
      >({
        path: `/counterparty/${uuid}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update a counterparty for the current user. Only counterparty with status INIT or ERROR can be updated.
     *
     * @tags Counterparty
     * @name UpdateCounterparty1
     * @summary Update a counterparty.
     * @request PUT:/counterparty/{uuid}
     * @secure
     */
    updateCounterparty1: (
      uuid: string,
      data:
        | ACHCounterparty
        | CryptoCounterparty
        | EftCounterparty
        | FedwireCounterparty
        | ImpsCounterparty
        | SepaCounterparty
        | SwiftCounterparty,
      params: RequestParams = {}
    ) =>
      this.request<
        | ACHCounterparty
        | CryptoCounterparty
        | EftCounterparty
        | FedwireCounterparty
        | ImpsCounterparty
        | SepaCounterparty
        | SwiftCounterparty,
        ApiErrorResponse
      >({
        path: `/counterparty/${uuid}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Counterparty
     * @name DeleteCounterparty1
     * @request DELETE:/counterparty/{uuid}
     * @secure
     */
    deleteCounterparty1: (uuid: string, params: RequestParams = {}) =>
      this.request<AccountBodyDto, ApiErrorResponse>({
        path: `/counterparty/${uuid}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get all counterparties for the current user.
     *
     * @tags Counterparty
     * @name GetCounterparties
     * @summary Get counterparties for current user
     * @request GET:/v2/counterparty
     * @secure
     */
    getCounterparties: (
      query?: {
        /**
         * @format int32
         * @default 0
         */
        page?: number;
        /**
         * @format int32
         * @default 100
         */
        size?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageableResponseBaseCounterpartyDto, ApiErrorResponseDto | ApiErrorResponse>({
        path: `/v2/counterparty`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create a counterparty for the current user.
     *
     * @tags Counterparty
     * @name CreateCounterparty
     * @summary Create a counterparty
     * @request POST:/v2/counterparty
     * @secure
     */
    createCounterparty: (data: CounterpartyBodyDto, params: RequestParams = {}) =>
      this.request<CounterpartyBodyDto, ApiErrorResponseDto | ApiErrorResponse>({
        path: `/v2/counterparty`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get all counterparties for the current user.
     *
     * @tags Counterparty
     * @name GetCounterparties1
     * @summary Get counterparties for current user
     * @request GET:/counterparty
     * @secure
     */
    getCounterparties1: (
      query?: {
        /**
         * @format int32
         * @default 0
         */
        page?: number;
        /**
         * @format int32
         * @default 100
         */
        size?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageableResponseBaseCounterparty, ApiErrorResponse>({
        path: `/counterparty`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create a counterparty for the current user.
     *
     * @tags Counterparty
     * @name CreateCounterparty1
     * @summary Create a counterparty
     * @request POST:/counterparty
     * @secure
     */
    createCounterparty1: (
      data:
        | ACHCounterparty
        | CryptoCounterparty
        | EftCounterparty
        | FedwireCounterparty
        | ImpsCounterparty
        | SepaCounterparty
        | SwiftCounterparty,
      params: RequestParams = {}
    ) =>
      this.request<
        | ACHCounterparty
        | CryptoCounterparty
        | EftCounterparty
        | FedwireCounterparty
        | ImpsCounterparty
        | SepaCounterparty
        | SwiftCounterparty,
        ApiErrorResponse
      >({
        path: `/counterparty`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Confirm counterparty creation, with MFA if required.
     *
     * @tags Counterparty
     * @name ConfirmCounterparty
     * @summary Confirm counterparty creation
     * @request GET:/v2/counterparty/{counterpartyId}/confirm
     * @secure
     */
    confirmCounterparty: (counterpartyId: string, params: RequestParams = {}) =>
      this.request<CounterpartyCounterpartyIdBodyDto, ApiErrorResponseDto | ApiErrorResponse>({
        path: `/v2/counterparty/${counterpartyId}/confirm`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  account = {
    /**
     * @description Returns 200 with AccountInfo objects if success
     *
     * @tags Account
     * @name GetAccount
     * @summary Get account by id
     * @request GET:/v2/account/{accountId}
     * @secure
     */
    getAccount: (accountId: string, params: RequestParams = {}) =>
      this.request<AccountDto, ApiErrorResponseDto | ApiErrorResponse>({
        path: `/v2/account/${accountId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Allows to update shortname of account
     *
     * @tags Account
     * @name UpdateAccount
     * @summary Update account by id
     * @request PUT:/v2/account/{accountId}
     * @secure
     */
    updateAccount: (accountId: string, data: AccountDto, params: RequestParams = {}) =>
      this.request<AccountOther, ApiErrorResponseDto | ApiErrorResponse>({
        path: `/v2/account/${accountId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns 200 with AccountInfo objects if success
     *
     * @tags Account
     * @name GetAccount1
     * @summary Get account by id
     * @request GET:/account/{accountId}
     * @secure
     */
    getAccount1: (accountId: string, params: RequestParams = {}) =>
      this.request<Account | AccountLayer2 | PrepaidCardAccount, ApiErrorResponse>({
        path: `/account/${accountId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Allows to update shortname of account
     *
     * @tags Account
     * @name UpdateAccount1
     * @summary Update account by id
     * @request PUT:/account/{accountId}
     * @secure
     */
    updateAccount1: (
      accountId: string,
      data: Account | AccountLayer2 | PrepaidCardAccount,
      params: RequestParams = {}
    ) =>
      this.request<Account | AccountLayer2 | PrepaidCardAccount, ApiErrorResponse>({
        path: `/account/${accountId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns 200 with AccountInfo objects if success
     *
     * @tags Account
     * @name ListAccount
     * @summary List all accounts
     * @request GET:/v2/account
     * @secure
     */
    listAccount: (params: RequestParams = {}) =>
      this.request<AccountDto[], ApiErrorResponseDto | ApiErrorResponse>({
        path: `/v2/account`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns 200 with AccountInfo objects if success
     *
     * @tags Account
     * @name CreateAccount
     * @summary Create an account
     * @request POST:/v2/account
     * @secure
     */
    createAccount: (data: AccountBodyDto, params: RequestParams = {}) =>
      this.request<AccountBodyDto, ApiErrorResponseDto | ApiErrorResponse>({
        path: `/v2/account`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns 200 with AccountInfo objects if success
     *
     * @tags Account
     * @name ListAccount1
     * @summary List all accounts
     * @request GET:/account
     * @secure
     */
    listAccount1: (params: RequestParams = {}) =>
      this.request<(Account | AccountLayer2 | PrepaidCardAccount)[], ApiErrorResponse>({
        path: `/account`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns 200 with AccountInfo objects if success
     *
     * @tags Account
     * @name CreateAccount1
     * @summary Create an account
     * @request POST:/account
     * @secure
     */
    createAccount1: (
      query?: {
        /** @default "" */
        shortName?: string;
        accountType?: AccountType;
        currency?: string;
        blockchain?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<Account | AccountLayer2 | PrepaidCardAccount, ApiErrorResponse>({
        path: `/account`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  operations = {
    /**
     * @description Get list of withdraws of current user with optional filtering by account id
     *
     * @tags Operations
     * @name GetAllWithdraw
     * @summary List all withdraws
     * @request GET:/withdraw
     * @secure
     */
    getAllWithdraw: (
      query?: {
        accountId?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        (
          | BaseWithdraw
          | AtmWithdraw
          | BankTransferWithdraw
          | CounterPartyWithdraw
          | CryptoWithdraw
          | PaySpotWithdraw
          | PayoutWithdraw
        )[],
        ApiErrorResponse
      >({
        path: `/withdraw`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Operations
     * @name CreateWithdraw
     * @summary Create a withdraw request
     * @request POST:/withdraw
     * @secure
     */
    createWithdraw: (
      data:
        | BaseWithdraw
        | AtmWithdraw
        | BankTransferWithdraw
        | CounterPartyWithdraw
        | CryptoWithdraw
        | PaySpotWithdraw
        | PayoutWithdraw,
      query?: {
        /** Do not perform actual withdraw, only return expected resulting withdraw */
        dryRun?: boolean;
        autoConversion?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        | BaseWithdraw
        | AtmWithdraw
        | BankTransferWithdraw
        | CounterPartyWithdraw
        | CryptoWithdraw
        | PaySpotWithdraw
        | PayoutWithdraw,
        ApiErrorResponse
      >({
        path: `/withdraw`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get list of withdraws of current user with optional filtering by account id
     *
     * @tags Operations
     * @name GetAllWithdraw1
     * @summary List all withdraws
     * @request GET:/v2/withdraw
     * @secure
     */
    getAllWithdraw1: (
      query?: {
        accountId?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<WithdrawDto[], ApiErrorResponseDto | ApiErrorResponse>({
        path: `/v2/withdraw`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Operations
     * @name CreateWithdraw1
     * @summary Create a withdraw request. To execute request use `confirmWithdraw`
     * @request POST:/v2/withdraw
     * @secure
     */
    createWithdraw1: (
      data: WithdrawDto,
      query?: {
        autoConversion?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<WithdrawDto, ApiErrorResponseDto | ApiErrorResponse>({
        path: `/v2/withdraw`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Confirm existing withdrawal
     *
     * @tags Operations
     * @name ConfirmWithdraw
     * @summary Confirm a withdrawal
     * @request POST:/v2/withdraw/{withdrawId}/confirm
     * @secure
     */
    confirmWithdraw: (withdrawId: string, params: RequestParams = {}) =>
      this.request<void, ApiErrorResponseDto | ApiErrorResponse>({
        path: `/v2/withdraw/${withdrawId}/confirm`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * @description Get list of transfers of current user with optional filtering by account id
     *
     * @tags Operations
     * @name ListTransfers
     * @summary List all transfers
     * @request GET:/v2/transfer
     * @secure
     */
    listTransfers: (
      query?: {
        accountId?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<TransferDto[], ApiErrorResponseDto | ApiErrorResponse>({
        path: `/v2/transfer`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Operations
     * @name CreateTransfer
     * @summary Create transfer between accounts
     * @request POST:/v2/transfer
     * @secure
     */
    createTransfer: (
      data: TransferBodyDto,
      query?: {
        /** @default false */
        conversion?: boolean;
        /** @default false */
        anyCurrency?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<TransferBodyDto, ApiErrorResponseDto | ApiErrorResponse>({
        path: `/v2/transfer`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Confirm existing transfer
     *
     * @tags Operations
     * @name ConfirmTransfer
     * @summary Confirm a transfer
     * @request POST:/v2/transfer/{transferId}/confirm
     * @secure
     */
    confirmTransfer: (transferId: string, params: RequestParams = {}) =>
      this.request<TransferBodyDto, ApiErrorResponseDto | ApiErrorResponse>({
        path: `/v2/transfer/${transferId}/confirm`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get all payins
     *
     * @tags Operations
     * @name GetAllPayins
     * @summary Get all payins
     * @request GET:/v2/payin
     * @secure
     */
    getAllPayins: (params: RequestParams = {}) =>
      this.request<PayinDto[], ApiErrorResponseDto | ApiErrorResponse>({
        path: `/v2/payin`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create request for payin fiat currency to user crypto account. `dryRun` request param allows to check resulting rate and fee amount
     *
     * @tags Operations
     * @name CreatePayin
     * @summary Create payin
     * @request POST:/v2/payin
     * @secure
     */
    createPayin: (
      data: CreatePayinDto,
      query?: {
        /** Do not perform actual payin, only return expected resulting payin info */
        dryRun?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<PayinDto, ApiErrorResponseDto | ApiErrorResponse>({
        path: `/v2/payin`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description List of exchanges of current user with optional filtering by account id
     *
     * @tags Operations
     * @name GetAllDirectExchange
     * @summary List all exchanges
     * @request GET:/v2/direct-exchange
     * @secure
     */
    getAllDirectExchange: (
      query?: {
        accountId?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<DirectExchangeDto[], ApiErrorResponseDto | ApiErrorResponse>({
        path: `/v2/direct-exchange`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create request for exchange one currency to another on user account. `dryRun` request param allows to check resulting rate and fee amount
     *
     * @tags Operations
     * @name CreateDirectExchange
     * @summary Create an currency exchange
     * @request POST:/v2/direct-exchange
     * @secure
     */
    createDirectExchange: (data: DirectExchangeDto, params: RequestParams = {}) =>
      this.request<DirectExchangeDto, ApiErrorResponseDto | ApiErrorResponse>({
        path: `/v2/direct-exchange`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Execute exchange
     *
     * @tags Operations
     * @name ExecuteDirectExchangeExchange
     * @summary Execute an exchange
     * @request POST:/v2/direct-exchange/{exchangeId}/confirm
     * @secure
     */
    executeDirectExchangeExchange: (exchangeId: string, params: RequestParams = {}) =>
      this.request<DirectExchangeDto, ApiErrorResponseDto | ApiErrorResponse>({
        path: `/v2/direct-exchange/${exchangeId}/confirm`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Operations
     * @name GetAddresses
     * @request POST:/v2/deposit/address
     */
    getAddresses: (
      query: {
        accountId: string;
        /** @format int64 */
        addressIndex?: number;
        blockchain?: string;
        currency?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetAddresses200ResponseInnerDto[], ApiErrorResponseDto | ApiErrorResponse>({
        path: `/v2/deposit/address`,
        method: 'POST',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get list of transfers of current user with optional filtering by account id
     *
     * @tags Operations
     * @name ListTransfers1
     * @summary List all transfers
     * @request GET:/transfer
     * @secure
     */
    listTransfers1: (
      query?: {
        accountId?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, ApiErrorResponse>({
        path: `/transfer`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Operations
     * @name CreateTransfer1
     * @summary Create transfer between accounts
     * @request POST:/transfer
     * @secure
     */
    createTransfer1: (
      data: any,
      query?: {
        /** @default false */
        dryRun?: boolean;
        /** @default false */
        conversion?: boolean;
        /** @default true */
        anyCurrency?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, ApiErrorResponse>({
        path: `/transfer`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Confirm existing transfer
     *
     * @tags Operations
     * @name ConfirmTransfer1
     * @summary Confirm a transfer
     * @request POST:/transfer/{uuid}/confirm
     * @secure
     */
    confirmTransfer1: (uuid: string, params: RequestParams = {}) =>
      this.request<void, ApiErrorResponse>({
        path: `/transfer/${uuid}/confirm`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * @description Get all payins
     *
     * @tags Operations
     * @name GetAllPayins1
     * @summary Get all payins
     * @request GET:/payin
     * @secure
     */
    getAllPayins1: (params: RequestParams = {}) =>
      this.request<Payin[], ApiErrorResponse>({
        path: `/payin`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create request for payin fiat currency to user crypto account. `dryRun` request param allows to check resulting rate and fee amount
     *
     * @tags Operations
     * @name CreatePayin1
     * @summary Create payin
     * @request POST:/payin
     * @secure
     */
    createPayin1: (
      data: CreatePayin,
      query?: {
        /** Do not perform actual payin, only return expected resulting payin info */
        dryRun?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<Payin, ApiErrorResponse>({
        path: `/payin`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description List of exchanges of current user with optional filtering by account id
     *
     * @tags Operations
     * @name GetAllDirectExchange1
     * @summary List all exchanges
     * @request GET:/direct-exchange
     * @secure
     */
    getAllDirectExchange1: (
      query?: {
        accountId?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<DirectExchange[], ApiErrorResponse>({
        path: `/direct-exchange`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create request for exchange one currency to another on user account. `dryRun` request param allows to check resulting rate and fee amount
     *
     * @tags Operations
     * @name CreateDirectExchange1
     * @summary Create an currency exchange
     * @request POST:/direct-exchange
     * @secure
     */
    createDirectExchange1: (
      data: DirectExchange,
      query?: {
        /** Do not perform actual exchange, only return expected resulting exchange info */
        dryRun?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<DirectExchange, ApiErrorResponse>({
        path: `/direct-exchange`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Operations
     * @name GetWithdraw
     * @summary Retrieve a withdraw
     * @request GET:/withdraw/{uuid}
     * @secure
     */
    getWithdraw: (uuid: string, params: RequestParams = {}) =>
      this.request<
        | BaseWithdraw
        | AtmWithdraw
        | BankTransferWithdraw
        | CounterPartyWithdraw
        | CryptoWithdraw
        | PaySpotWithdraw
        | PayoutWithdraw,
        ApiErrorResponse
      >({
        path: `/withdraw/${uuid}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Operations
     * @name GetWithdraw1
     * @summary Retrieve a withdraw
     * @request GET:/v2/withdraw/{withdrawId}
     * @secure
     */
    getWithdraw1: (withdrawId: string, params: RequestParams = {}) =>
      this.request<WithdrawDto, ApiErrorResponseDto | ApiErrorResponse>({
        path: `/v2/withdraw/${withdrawId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieve a transfer between accounts by uuid
     *
     * @tags Operations
     * @name GetTransfer
     * @summary Retrieve a transfer
     * @request GET:/v2/transfer/{transferId}
     * @secure
     */
    getTransfer: (transferId: string, params: RequestParams = {}) =>
      this.request<TransferBodyDto, ApiErrorResponseDto | ApiErrorResponse>({
        path: `/v2/transfer/${transferId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get payin by UUID
     *
     * @tags Operations
     * @name GetPayin
     * @summary Get payin
     * @request GET:/v2/payin/{payinId}
     * @secure
     */
    getPayin: (payinId: string, params: RequestParams = {}) =>
      this.request<PayinDto, ApiErrorResponseDto | ApiErrorResponse>({
        path: `/v2/payin/${payinId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get operation log
     *
     * @tags Operations
     * @name GetOperationLog
     * @request GET:/v2/log
     */
    getOperationLog: (
      query?: {
        accountId?: string[];
        /** @format date-time */
        fromTime?: string;
        /** @format date-time */
        toTime?: string;
        operationType?: OperationTypeDto[];
        operationStatus?: string[];
        /**
         * @format int32
         * @min 1
         * @default 1
         */
        page?: number;
        /**
         * @format int32
         * @min 1
         * @max 100
         * @default 100
         */
        size?: number;
        /** @default "createdAt,id" */
        sort?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageableResponseOperationsDto, ApiErrorResponse>({
        path: `/v2/log`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieve info about existing exchange operation by uuid
     *
     * @tags Operations
     * @name GetDirectExchange
     * @summary Retrieve an exchange
     * @request GET:/v2/direct-exchange/{exchangeId}
     * @secure
     */
    getDirectExchange: (exchangeId: string, params: RequestParams = {}) =>
      this.request<DirectExchangeDto, ApiErrorResponseDto | ApiErrorResponse>({
        path: `/v2/direct-exchange/${exchangeId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description List of all deposits
     *
     * @tags Operations
     * @name GetDeposits
     * @request GET:/v2/deposit
     * @secure
     */
    getDeposits: (params: RequestParams = {}) =>
      this.request<DepositDto[], ApiErrorResponseDto | ApiErrorResponse>({
        path: `/v2/deposit`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieve a transfer between accounts by uuid
     *
     * @tags Operations
     * @name GetTransfer1
     * @summary Retrieve a transfer
     * @request GET:/transfer/{uuid}
     * @secure
     */
    getTransfer1: (uuid: string, params: RequestParams = {}) =>
      this.request<void, ApiErrorResponse>({
        path: `/transfer/${uuid}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Get payin by UUID
     *
     * @tags Operations
     * @name GetPayin1
     * @summary Get payin
     * @request GET:/payin/{uuid}
     * @secure
     */
    getPayin1: (
      uuid: string,
      query: {
        /**
         * Payin UUID
         * @format uuid
         */
        uuid: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<Payin, ApiErrorResponse>({
        path: `/payin/${uuid}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieve info about existing exchange operation by uuid
     *
     * @tags Operations
     * @name GetDirectExchange1
     * @summary Retrieve an exchange
     * @request GET:/direct-exchange/{uuid}
     * @secure
     */
    getDirectExchange1: (uuid: string, params: RequestParams = {}) =>
      this.request<DirectExchange, ApiErrorResponse>({
        path: `/direct-exchange/${uuid}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  qrCodes = {
    /**
     * No description
     *
     * @tags QR Codes
     * @name ScanCode
     * @summary Scan QR code
     * @request POST:/v2/qr-code/scan
     * @secure
     */
    scanCode: (data: ScanQrRequestDto, params: RequestParams = {}) =>
      this.request<QrResponseDto, ApiErrorResponseDto | ApiErrorResponse>({
        path: `/v2/qr-code/scan`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags QR Codes
     * @name ScanCode1
     * @summary Scan QR code
     * @request POST:/qr-code/scan
     * @secure
     */
    scanCode1: (data: ScanQrRequest, params: RequestParams = {}) =>
      this.request<QrResponse, ApiErrorResponse>({
        path: `/qr-code/scan`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  internal = {
    /**
     * No description
     *
     * @tags Internal
     * @name Webhook
     * @request POST:/v2/layer2/webhook
     */
    webhook: (data: Layer2EventDtoDto, params: RequestParams = {}) =>
      this.request<AccountBodyDto, ApiErrorResponseDto | ApiErrorResponse>({
        path: `/v2/layer2/webhook`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Internal
     * @name InvoiceWebhook
     * @summary Invoice webhook
     * @request POST:/v2/invoice/webhook
     * @secure
     */
    invoiceWebhook: (data: InvoiceWebhookDto, params: RequestParams = {}) =>
      this.request<void, ApiErrorResponseDto | ApiErrorResponse>({
        path: `/v2/invoice/webhook`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  balance = {
    /**
     * @description Get account balance by accountId, currency, optionally by specific date
     *
     * @tags Balance
     * @name GetBalanceList
     * @summary Get account balance
     * @request GET:/v2/balance
     * @secure
     */
    getBalanceList: (
      query?: {
        accountId?: string[];
        currency?: string;
        /** @format date-time */
        byDate?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<BalanceDto[], ApiErrorResponseDto | ApiErrorResponse>({
        path: `/v2/balance`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Balance
     * @name TouchBalance
     * @summary Touch balance for currency if not exist
     * @request POST:/v2/balance
     * @secure
     */
    touchBalance: (
      query: {
        accountId: string;
        currency: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<BalanceDto, ApiErrorResponseDto | ApiErrorResponse>({
        path: `/v2/balance`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get account balance by accountId, currency, optionally by specific date
     *
     * @tags Balance
     * @name GetBalanceList1
     * @summary Get account balance
     * @request GET:/balance
     * @secure
     */
    getBalanceList1: (
      query?: {
        accountId?: string;
        accountIds?: string[];
        currency?: string;
        /** @format date-time */
        byDate?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<Balance[], ApiErrorResponse>({
        path: `/balance`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Balance
     * @name TouchBalance1
     * @summary Touch balance for currency if not exist
     * @request POST:/balance
     * @secure
     */
    touchBalance1: (
      query: {
        accountId: string;
        currency: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<Balance, ApiErrorResponse>({
        path: `/balance`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Balance
     * @name GetBalanceLog
     * @summary Get paged balance log
     * @request GET:/v2/balance/log
     * @secure
     */
    getBalanceLog: (
      query?: {
        accountIds?: string[];
        /** @pattern ^[a-z_]+$ */
        currency?: string;
        /** @format date-time */
        from?: string;
        /** @format date-time */
        to?: string;
        direction?: BalanceLogDirectionEnumDto;
        reasonType?: string;
        search?: string;
        /**
         * @format int32
         * @default 0
         */
        page?: number;
        /**
         * @format int32
         * @default 100
         */
        size?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageableResponseBalanceLogDto, ApiErrorResponseDto | ApiErrorResponse>({
        path: `/v2/balance/log`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Balance
     * @name GetBalanceLogReceipt
     * @summary Get balance log receipt
     * @request GET:/v2/balance/log/{logId}
     * @secure
     */
    getBalanceLogReceipt: (logId: string, params: RequestParams = {}) =>
      this.request<BalanceLogWithReceiptDto, ApiErrorResponseDto | ApiErrorResponse>({
        path: `/v2/balance/log/${logId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get balance history. Result is limited to 100 ticks.
     *
     * @tags Balance
     * @name GetBalanceHistory
     * @summary Get history of balance
     * @request GET:/v2/balance/history
     */
    getBalanceHistory: (
      query?: {
        /** @default "ONE_HOUR" */
        candleType?: string;
        /** Filter by account id, if not specified return total balance */
        accountIds?: string[];
        /**
         * Filter by currencies, if not specified return aggegated balance
         * @pattern ^[a-z_]+$
         */
        currencies?: string;
        /**
         * Get result in specified currency, if not specified return in system base currency.
         * @pattern ^[a-z_]+$
         */
        baseCurrency?: string;
        /**
         * Filter result from this date
         * @format date-time
         */
        fromDate?: string;
        /**
         * Filter result to this date
         * @format date-time
         */
        toDate?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<BalanceHistoryDto, ApiErrorResponse>({
        path: `/v2/balance/history`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Balance
     * @name GetBalanceLog1
     * @summary Get paged balance log
     * @request GET:/balance/log
     * @secure
     */
    getBalanceLog1: (
      query?: {
        accountIds?: string[];
        currency?: string;
        /** @format date-time */
        from?: string;
        /** @format date-time */
        to?: string;
        direction?: BalanceLogDirectionEnum;
        reasonType?: string;
        search?: string;
        /**
         * @format int32
         * @default 0
         */
        page?: number;
        /**
         * @format int32
         * @default 100
         */
        size?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageableResponseBalanceLog, ApiErrorResponse>({
        path: `/balance/log`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Balance
     * @name GetBalanceLogReceipt1
     * @summary Get balance log receipt
     * @request GET:/balance/log/{logId}
     * @secure
     */
    getBalanceLogReceipt1: (logId: string, params: RequestParams = {}) =>
      this.request<BalanceLogWithReceipt, ApiErrorResponse>({
        path: `/balance/log/${logId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  webhooks = {
    /**
     * No description
     *
     * @tags Webhooks
     * @name InvoiceWebhook1
     * @summary Invoice webhook
     * @request POST:/invoice/webhook
     * @secure
     */
    invoiceWebhook1: (data: OriginalInvoiceDto, params: RequestParams = {}) =>
      this.request<void, ApiErrorResponse>({
        path: `/invoice/webhook`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  admin = {
    /**
     * No description
     *
     * @tags Admin
     * @name AdminListWithdraw
     * @request GET:/admin/operations/withdraw
     * @secure
     */
    adminListWithdraw: (
      query?: {
        /** @format uuid */
        userId?: string;
        /**
         * @format int32
         * @default 0
         */
        page?: number;
        /**
         * @format int32
         * @default 100
         */
        size?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageableResponseBaseAdminWithdraw, ApiErrorResponse>({
        path: `/admin/operations/withdraw`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name AdminCreateWithdraw
     * @summary Create a withdraw request
     * @request POST:/admin/operations/withdraw
     * @secure
     */
    adminCreateWithdraw: (
      data:
        | BaseWithdraw
        | AtmWithdraw
        | BankTransferWithdraw
        | CounterPartyWithdraw
        | CryptoWithdraw
        | PaySpotWithdraw
        | PayoutWithdraw,
      query?: {
        /** Do not perform actual withdraw, only return expected resulting withdraw */
        dryRun?: boolean;
        autoConversion?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        | BaseWithdraw
        | AtmWithdraw
        | BankTransferWithdraw
        | CounterPartyWithdraw
        | CryptoWithdraw
        | PaySpotWithdraw
        | PayoutWithdraw,
        ApiErrorResponse
      >({
        path: `/admin/operations/withdraw`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name AdminCreateTransfer
     * @summary Create transfer between accounts
     * @request POST:/admin/operations/transfer
     * @secure
     */
    adminCreateTransfer: (
      data: any,
      query?: {
        /** @default false */
        dryRun?: boolean;
        /** @default false */
        conversion?: boolean;
        /** @default true */
        anyCurrency?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, ApiErrorResponse>({
        path: `/admin/operations/transfer`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name AdminListDirectExchange
     * @request GET:/admin/operations/exchange
     * @secure
     */
    adminListDirectExchange: (
      query?: {
        /** @format uuid */
        userId?: string;
        /**
         * @format int32
         * @default 0
         */
        page?: number;
        /**
         * @format int32
         * @default 100
         */
        size?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageableResponseAdminDirectExchange, ApiErrorResponse>({
        path: `/admin/operations/exchange`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Create request for exchange one currency to another on user account. `dryRun` request param allows to check resulting rate and fee amount
     *
     * @tags Admin
     * @name AdminCreateDirectExchange
     * @summary Create an currency exchange
     * @request POST:/admin/operations/exchange
     * @secure
     */
    adminCreateDirectExchange: (
      data: DirectExchange,
      query?: {
        /** Do not perform actual exchange, only return expected resulting exchange info */
        dryRun?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<DirectExchange, ApiErrorResponse>({
        path: `/admin/operations/exchange`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name AdminListAccount
     * @request GET:/admin/account
     * @secure
     */
    adminListAccount: (
      query?: {
        search?: string;
        /**
         * @format int32
         * @default 0
         */
        page?: number;
        /**
         * @format int32
         * @default 100
         */
        size?: number;
        /** @default "createdAt,id" */
        sort?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageableResponseAdminAccount, ApiErrorResponse>({
        path: `/admin/account`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name AdminCreateAccount
     * @request POST:/admin/account
     * @secure
     */
    adminCreateAccount: (
      query: {
        accountType: AccountType;
        /** @format uuid */
        userId?: string;
        cardId?: string;
        cardProviderType?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<AdminAccount, ApiErrorResponse>({
        path: `/admin/account`,
        method: 'POST',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name AdminUnlinkAccountWithUser
     * @request POST:/admin/account/{accountId}/user/{userId}/unlink
     * @secure
     */
    adminUnlinkAccountWithUser: (userId: string, accountId: string, params: RequestParams = {}) =>
      this.request<Unit, ApiErrorResponse>({
        path: `/admin/account/${accountId}/user/${userId}/unlink`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name AdminLinkAccountWithUser
     * @request POST:/admin/account/{accountId}/user/{userId}/link
     * @secure
     */
    adminLinkAccountWithUser: (userId: string, accountId: string, params: RequestParams = {}) =>
      this.request<AdminAccount, ApiErrorResponse>({
        path: `/admin/account/${accountId}/user/${userId}/link`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name AdminSetSystemAccountStatus
     * @request POST:/admin/account/{accountId}/system
     * @secure
     */
    adminSetSystemAccountStatus: (
      accountId: string,
      query: {
        system: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<AdminAccount, ApiErrorResponse>({
        path: `/admin/account/${accountId}/system`,
        method: 'POST',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Generic stats of wallet service
     *
     * @tags Admin
     * @name GetStats
     * @summary Generic stats
     * @request GET:/admin/stats
     * @secure
     */
    getStats: (params: RequestParams = {}) =>
      this.request<Record<string, number>, ApiErrorResponse>({
        path: `/admin/stats`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Find operation by id (for example from Balance Log -> Reason Id)
     *
     * @tags Admin
     * @name FindOperation
     * @summary Get operation by id
     * @request GET:/admin/operations
     * @secure
     */
    findOperation: (
      query: {
        query: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<OperationSearch, ApiErrorResponse>({
        path: `/admin/operations`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name AdminGetWithdraw
     * @summary Retrieve a withdraw
     * @request GET:/admin/operations/withdraw/{uuid}
     * @secure
     */
    adminGetWithdraw: (uuid: string, params: RequestParams = {}) =>
      this.request<
        | BaseWithdraw
        | AtmWithdraw
        | BankTransferWithdraw
        | CounterPartyWithdraw
        | CryptoWithdraw
        | PaySpotWithdraw
        | PayoutWithdraw,
        ApiErrorResponse
      >({
        path: `/admin/operations/withdraw/${uuid}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieve a transfer between accounts by uuid
     *
     * @tags Admin
     * @name AdminGetTransfer
     * @summary Retrieve a transfer
     * @request GET:/admin/operations/transfer/{uuid}
     * @secure
     */
    adminGetTransfer: (uuid: string, params: RequestParams = {}) =>
      this.request<void, ApiErrorResponse>({
        path: `/admin/operations/transfer/${uuid}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Retrieve info about existing exchange operation by uuid
     *
     * @tags Admin
     * @name AdminGetDirectExchange
     * @summary Retrieve an exchange
     * @request GET:/admin/operations/exchange/{uuid}
     * @secure
     */
    adminGetDirectExchange: (uuid: string, params: RequestParams = {}) =>
      this.request<DirectExchange, ApiErrorResponse>({
        path: `/admin/operations/exchange/${uuid}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name AdminGetBalanceLog
     * @request GET:/admin/log
     * @secure
     */
    adminGetBalanceLog: (
      query?: {
        /** @format uuid */
        userId?: string;
        accounts?: string[];
        currencies?: string;
        /** @format date-time */
        from?: string;
        /** @format date-time */
        to?: string;
        direction?: BalanceLogDirectionEnum;
        reasonType?: string;
        search?: string;
        /**
         * @format int32
         * @default 0
         */
        page?: number;
        /**
         * @format int32
         * @default 100
         */
        size?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageableResponseBalanceLog, ApiErrorResponse>({
        path: `/admin/log`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name AdminListDeposit
     * @request GET:/admin/deposits
     * @secure
     */
    adminListDeposit: (
      query?: {
        /** @format uuid */
        userId?: string;
        /**
         * @format int32
         * @default 0
         */
        page?: number;
        /**
         * @format int32
         * @default 100
         */
        size?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageableResponseAdminDeposit, ApiErrorResponse>({
        path: `/admin/deposits`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name AdminListCounterparty
     * @request GET:/admin/counterparty
     * @secure
     */
    adminListCounterparty: (
      query?: {
        /** @format uuid */
        userId?: string;
        /**
         * @format int32
         * @default 0
         */
        page?: number;
        /**
         * @format int32
         * @default 100
         */
        size?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageableResponseBaseCounterparty, ApiErrorResponse>({
        path: `/admin/counterparty`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name AdminGetCounterpartyById
     * @request GET:/admin/counterparty/{uuid}
     * @secure
     */
    adminGetCounterpartyById: (uuid: string, params: RequestParams = {}) =>
      this.request<
        | ACHCounterparty
        | CryptoCounterparty
        | EftCounterparty
        | FedwireCounterparty
        | ImpsCounterparty
        | SepaCounterparty
        | SwiftCounterparty,
        ApiErrorResponse
      >({
        path: `/admin/counterparty/${uuid}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name AdminSyncCounterparty
     * @request GET:/admin/counterparty/{uuid}/sync
     * @secure
     */
    adminSyncCounterparty: (uuid: string, params: RequestParams = {}) =>
      this.request<
        | ACHCounterparty
        | CryptoCounterparty
        | EftCounterparty
        | FedwireCounterparty
        | ImpsCounterparty
        | SepaCounterparty
        | SwiftCounterparty,
        ApiErrorResponse
      >({
        path: `/admin/counterparty/${uuid}/sync`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name AdminGetTotalBalance
     * @request GET:/admin/balance/totals_report
     * @secure
     */
    adminGetTotalBalance: (params: RequestParams = {}) =>
      this.request<TotalBalanceReportDto[], ApiErrorResponse>({
        path: `/admin/balance/totals_report`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name AdminGetAccount
     * @request GET:/admin/account/{accountId}
     * @secure
     */
    adminGetAccount: (accountId: string, params: RequestParams = {}) =>
      this.request<AdminAccount, ApiErrorResponse>({
        path: `/admin/account/${accountId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name AdminGetAccountBalanceLog
     * @request GET:/admin/account/{accountId}/log
     * @secure
     */
    adminGetAccountBalanceLog: (
      accountId: string,
      query?: {
        currency?: string;
        /** @format date-time */
        from?: string;
        /** @format date-time */
        to?: string;
        direction?: BalanceLogDirectionEnum;
        reasonType?: string;
        search?: string;
        /**
         * @format int32
         * @default 0
         */
        page?: number;
        /**
         * @format int32
         * @default 100
         */
        size?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageableResponseBalanceLog, ApiErrorResponse>({
        path: `/admin/account/${accountId}/log`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Get account balance by account id, optionally by date.
     *
     * @tags Admin
     * @name AdminGetAccountBalance
     * @summary Get account balance
     * @request GET:/admin/account/{accountId}/balance
     * @secure
     */
    adminGetAccountBalance: (
      accountId: string,
      query?: {
        /** @format date-time */
        byDate?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<Balance[], ApiErrorResponse>({
        path: `/admin/account/${accountId}/balance`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Get account addresses by account id, optionally by address index, blockchain and currency.
     *
     * @tags Admin
     * @name AdminGetAddresses
     * @summary Get account addresses
     * @request GET:/admin/account/{accountId}/address
     * @secure
     */
    adminGetAddresses: (
      accountId: string,
      query?: {
        /** @format int64 */
        addressIndex?: number;
        blockchain?: string;
        currency?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        (ACHAddress | CryptoAddress | EFTAddress | FEDWIREAddress | SepaAddress | SwiftAddress)[],
        ApiErrorResponse
      >({
        path: `/admin/account/${accountId}/address`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name AdminListAccountByUser
     * @request GET:/admin/account/user
     * @secure
     */
    adminListAccountByUser: (
      query: {
        /** @format uuid */
        userId: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<AdminAccountUser[], ApiErrorResponse>({
        path: `/admin/account/user`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),
  };
  utils = {
    /**
     * No description
     *
     * @tags Utils
     * @name GetLimits
     * @summary Get current withdraw total status. Currently only in calculated in EUR by the date of creation of request.
     * @request GET:/v2/utils/limits
     * @secure
     */
    getLimits: (params: RequestParams = {}) =>
      this.request<LimitsDtoDto, ApiErrorResponseDto | ApiErrorResponse>({
        path: `/v2/utils/limits`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description check crypto address format validity
     *
     * @tags Utils
     * @name ValidateAddressFormat
     * @request GET:/v2/utils/adress/validation
     */
    validateAddressFormat: (
      query: {
        address: string;
        blockchain: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<AddressValidationDto, ApiErrorResponseDto | ApiErrorResponse>({
        path: `/v2/utils/adress/validation`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Utils
     * @name GetLimits1
     * @summary Get current withdraw total status. Currently only in calculated in EUR by the date of creation of request.
     * @request GET:/utils/limits
     * @secure
     */
    getLimits1: (params: RequestParams = {}) =>
      this.request<LimitsDto, ApiErrorResponse>({
        path: `/utils/limits`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  converterController = {
    /**
     * No description
     *
     * @tags converter-controller
     * @name Convert
     * @request GET:/v2/convert
     */
    convert: (
      query: {
        amount: number;
        accountId: string;
        currency: string;
        priorityCurrency?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<ConversionFundsDto[], ApiErrorResponseDto | ApiErrorResponse>({
        path: `/v2/convert`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags converter-controller
     * @name Convert1
     * @request GET:/convert
     */
    convert1: (
      query: {
        amount: number;
        accountId: string;
        currency: string;
        priorityCurrency?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<ConversionFunds[], ApiErrorResponse>({
        path: `/convert`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  };
  address = {
    /**
     * No description
     *
     * @tags Address
     * @name GetAddresses1
     * @request GET:/v2/address/list
     */
    getAddresses1: (
      query: {
        accountId: string;
        /** @format int64 */
        addressIndex?: number;
        blockchain?: string;
        currency?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        (ACHAddress | CryptoAddress | EFTAddress | FEDWIREAddress | SepaAddress | SwiftAddress)[],
        ApiErrorResponse
      >({
        path: `/v2/address/list`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * @description check crypto address format validity
     *
     * @tags Address
     * @name ValidateAddressFormat1
     * @request GET:/v2/address/format/validation
     */
    validateAddressFormat1: (
      query: {
        address: string;
        blockchain: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<AddressValidation, ApiErrorResponse>({
        path: `/v2/address/format/validation`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * @description Get and create address for current user
     *
     * @tags Address
     * @name GetAddress
     * @request GET:/v2/address/
     * @secure
     */
    getAddress: (
      query: {
        accountId: string;
        /** @format int64 */
        addressIndex?: number;
        blockchain?: string;
        currency?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        ACHAddress | CryptoAddress | EFTAddress | FEDWIREAddress | SepaAddress | SwiftAddress,
        ApiErrorResponse
      >({
        path: `/v2/address/`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),
  };
}
