/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import classNames from 'classnames';

import { FaChartSimple } from 'react-icons/fa6';
import { ReactComponent as DashboardIconSvg } from 'assets/images/icons/dashboard.svg';
import { ReactComponent as UsersIconSvg } from 'assets/images/icons/users.svg';
import { ReactComponent as LogoutIconSvg } from 'assets/images/icons/logout.svg';
import { ReactComponent as UserScopesIconSvg } from 'assets/images/icons/userScopes.svg';
import { ReactComponent as AccountsIconSvg } from 'assets/images/icons/wallet.svg';
import { ReactComponent as TXIconSvg } from 'assets/images/icons/transactions.svg';
import { ReactComponent as TXAppIconSvg } from 'assets/images/icons/approvals.svg';
import { ReactComponent as CompanyIconSvg } from 'assets/images/icons/company.svg';
import { ReactComponent as ArrowsLeftSvg } from 'assets/images/icons/arrows-left.svg';
import { ReactComponent as LoansSvg } from 'assets/images/icons/loans.svg';
import { ReactComponent as InvoicesIconSvg } from 'assets/images/icons/invoices.svg';
import { ReactComponent as SubscriptionsIconSvg } from 'assets/images/icons/loans_management.svg';
import { ReactComponent as CardsIconSvg } from 'assets/images/icons/onboarding.svg';
import { ReactComponent as SettingsIconSvg } from 'assets/images/icons/settings.svg';
import { ReactComponent as QuestsIconSvg } from 'assets/images/icons/quests.svg';

import { ROUTES } from 'routes';
import { observer } from 'mobx-react-lite';
import { useBaseStores } from 'providers/BaseStoresProvider';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';

const LayoutMenu = observer(() => {
  const intl = useIntl();
  const { authStore, layoutStore } = useBaseStores();

  const onCollapseClick = useCallback(() => {
    layoutStore.toggleMenuCollapsed();
  }, [layoutStore]);

  const onLogoutClick = useCallback(() => {
    authStore.signOut();
  }, [authStore]);

  const linksData = useMemo(
    () => [
      {
        text: intl.formatMessage({
          id: 'menu.links.dashboard',
          defaultMessage: 'Dashboard',
        }),
        icon: <DashboardIconSvg />,
        link: ROUTES.index.fullPath,
      },
      {
        text: intl.formatMessage({
          id: 'menu.links.users',
          defaultMessage: 'Users',
        }),
        icon: <UsersIconSvg />,
        link: ROUTES.users.fullPath,
      },
      {
        text: intl.formatMessage({
          id: 'menu.links.userScope',
          defaultMessage: 'User scopes',
        }),
        icon: <UserScopesIconSvg />,
        link: ROUTES.userScopes.fullPath,
      },
      {
        text: intl.formatMessage({
          id: 'menu.links.accounts',
          defaultMessage: 'Accounts',
        }),
        icon: <AccountsIconSvg />,
        link: ROUTES.accounts.fullPath,
      },
      {
        text: intl.formatMessage({
          id: 'menu.links.transactionsHist',
          defaultMessage: 'Transactions history',
        }),
        icon: <TXIconSvg />,
        link: ROUTES.transactions.fullPath,
      },
      {
        text: intl.formatMessage({
          id: 'menu.links.transactionsApp',
          defaultMessage: 'Transactions approver',
        }),
        icon: <TXAppIconSvg />,
        link: ROUTES.transactionsApprove.fullPath,
      },
      {
        text: intl.formatMessage({
          id: 'menu.links.companyInfo',
          defaultMessage: 'Company info',
        }),
        icon: <CompanyIconSvg />,
        link: ROUTES.company.fullPath,
      },
      // {
      //   text: intl.formatMessage({
      //     id: 'menu.links.loans',
      //     defaultMessage: 'Loans',
      //   }),
      //   icon: <LoansSvg />,
      //   link: ROUTES.loans.fullPath,
      // },
      {
        text: intl.formatMessage({
          id: 'menu.links.invoices',
          defaultMessage: 'Invoices',
        }),
        icon: <InvoicesIconSvg />,
        link: ROUTES.invoices.fullPath,
      },
      {
        text: intl.formatMessage({
          id: 'menu.links.subscriptions',
          defaultMessage: 'Subscriptions',
        }),
        icon: <SubscriptionsIconSvg />,
        link: ROUTES.subscriptions.fullPath,
      },
      {
        text: intl.formatMessage({
          id: 'menu.links.cards',
          defaultMessage: 'Cards',
        }),
        icon: <CardsIconSvg />,
        link: ROUTES.cards.fullPath,
      },
      {
        text: intl.formatMessage({
          id: 'menu.links.promo_codes',
          defaultMessage: 'Promo codes',
        }),
        icon: <LoansSvg />,
        link: ROUTES.promoCodes.fullPath,
      },
      {
        text: intl.formatMessage({
          id: 'menu.links.exchange_configuration',
          defaultMessage: 'Exchange configuration',
        }),
        icon: <SettingsIconSvg />,
        link: ROUTES.exchangePairs.fullPath,
      },
      {
        text: intl.formatMessage({
          id: 'menu.links.limits',
          defaultMessage: 'Limits',
        }),
        icon: <FaChartSimple />,
        link: ROUTES.limits.fullPath,
      },
      // {
      //   text: intl.formatMessage({
      //     id: 'menu.links.quests',
      //     defaultMessage: 'Quests',
      //   }),
      //   icon: <QuestsIconSvg />,
      //   link: ROUTES.quests.fullPath,
      // },
    ],
    [intl]
  );
  return (
    <div
      className={classNames(styles.main, {
        [styles.collapsed]: layoutStore.menuCollapsed,
      })}
    >
      <div className={styles.links}>
        {linksData.map((x) => (
          <NavLink
            key={x.text}
            className={({ isActive }) =>
              classNames(styles.link, styles.hoverable, {
                [styles.active]: isActive,
              })
            }
            to={x.link}
          >
            {x.icon}

            <span>{x.text}</span>
          </NavLink>
        ))}
      </div>

      <div className={styles.footer}>
        <div className={classNames(styles.footer_btn, styles.collapse_btn)}>
          <button className={styles.link} onClick={onCollapseClick}>
            <ArrowsLeftSvg />
            <span>
              {intl.formatMessage({
                id: 'menu.collapse',
                defaultMessage: 'Collapse',
              })}
            </span>
          </button>
        </div>

        <div className={styles.footer_btn}>
          <button className={styles.link} onClick={onLogoutClick}>
            <LogoutIconSvg />
            <span>
              {intl.formatMessage({
                id: 'menu.logout',
                defaultMessage: 'Logout',
              })}
            </span>
          </button>
        </div>
      </div>
    </div>
  );
});

export default LayoutMenu;
