/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Scrollbar } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';

import './swiper.css';
import styles from './index.module.css';
import UserDetailsBasicInfoField from '../../../basic-info/field';

import {
  KycVerificationFileModel,
  KycVerificationModel,
} from 'models/KycVerificationModel';
import { IdDocType } from 'api/auth';
import { useCountries } from 'hooks/countries';
import { CollectionWithPages } from 'stores/collection/CollectionWithPages';
import { useUserDetailsPageStore } from '../../../provider/StoreProvider';
import { Modal } from 'antd';

type Props = {
  collection: CollectionWithPages<KycVerificationModel, {}>;
};

export const Documents: FC<Props> = observer(({ collection }) => {
  const { formatMessage } = useIntl();
  const { getCountryNameByAlpha3 } = useCountries();
  const userDetailsPageStore = useUserDetailsPageStore();
  const {
    details,
    fetchKycVerificationDocuments,
    loadKycFiles,
  } = userDetailsPageStore;

  const address = details?.residentialAddress
    ? [
      details.residentialAddress?.country,
      details.residentialAddress?.postCode,
      details.residentialAddress?.state,
      details.residentialAddress?.town,
      details.residentialAddress?.street,
      details.residentialAddress?.subStreet,
      details.residentialAddress?.buildingNumber,
      details.residentialAddress?.buildingName,
      details.residentialAddress?.flatNumber,
    ].filter(Boolean).join(',')
    : '';

  const [docs, setDocs] = useState<KycVerificationFileModel[]>([]);

  const loadDocuments = async () => {
    try {
      const ids = collection.items && [...collection.items].filter(item => item.type === 'SUMSUB')
                                                           .map(item => item.id);
      if (!ids) return;

      const result = await fetchKycVerificationDocuments(
        ids,
        // ['a1ceac90-1124-43ce-a590-a4b9486959ce'],
      );
      setDocs(result);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    void loadDocuments();
  }, [collection.items]);

  const userDocs = docs.reduce<{
    photoDocs: KycVerificationFileModel[];
    otherDocs: KycVerificationFileModel[]
  }>((acc, doc) => {
    if (doc.type === IdDocType.SELFIE || doc.type === IdDocType.VIDEO_SELFIE) {
      acc.photoDocs.push(doc);
    } else {
      acc.otherDocs.push(doc);
    }

    return acc;
  }, { photoDocs: [], otherDocs: [] });

  const [photoFiles, setPhotoFiles] = useState<string[]>([]);
  const loadPhotoFiles = async () => {
    try {
      const result = await loadKycFiles(userDocs.photoDocs);
      setPhotoFiles(result);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (docs.length !== 0) {
      void loadPhotoFiles();
    }
  }, [docs]);

  // useEffect(() => {
  //   return () => {
  //     URL.revokeObjectURL(photoFiles[0]);
  //   }
  // }, []);

  const [otherFiles, setOtherFiles] = useState<string[]>([]);
  const loadOtherFiles = async () => {
    try {
      const result = await loadKycFiles(userDocs.otherDocs);
      setOtherFiles(result);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (docs.length !== 0) {
      void loadOtherFiles();
    }
  }, [docs]);

  // useEffect(() => {
  //   return () => {
  //     URL.revokeObjectURL(photoFiles[0]);
  //   };
  // }, []);

  const otherDocsElements = userDocs.otherDocs.map((doc) => {
    const { id, status, type } = doc;
    return (
      <div key={id} className={styles['document-container']}>
        <h2>{id}</h2>

        <div className={styles['data-fields-container']}>
          <UserDetailsBasicInfoField
            className={styles['data-field-doc-item']}
            label={formatMessage({
              id: 'page.user_details.kyc.doc_type',
              defaultMessage: 'Document type',
            })}
            value={type}
          />
          <UserDetailsBasicInfoField
            className={styles['data-field-doc-item']}
            label={formatMessage({
              id: 'page.user_details.kyc.doc_status',
              defaultMessage: 'Status',
            })}
            value={status}
          />
          <UserDetailsBasicInfoField
            className={styles['data-field-doc-item']}
            label={formatMessage({
              id: 'page.user_details.kyc.doc_id',
              defaultMessage: 'ID',
            })}
            value={id}
          />
        </div>

        <div className={styles['slider-container']}>
          <Swiper
            modules={[Navigation, Scrollbar]}
            navigation={true}
            scrollbar={{ enabled: true }}
            slidesPerView={3}
            spaceBetween={16}
          >
            {otherFiles.map((file, index) => {
              return (
                <SwiperSlide
                  key={index}
                  onClick={() => {
                    setActiveFile(file);
                    setIsModalOpen(true);
                  }}
                >
                  <img src={file} />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    );
  });

  const [activeFile, setActiveFile] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>
        {formatMessage({
          id: 'page.user_details.kyc.personal_data',
          defaultMessage: 'Personal data',
        })}
      </h2>

      <div className={styles.card}>
        {details && (
          <div className={styles['data-fields-container']}>
            <UserDetailsBasicInfoField
              className={styles['data-field-item']}
              label={formatMessage({
                id: 'page.user_details.firstName',
                defaultMessage: 'First name',
              })}
              value={details.firstName}
            />
            <UserDetailsBasicInfoField
              className={styles['data-field-item']}
              label={formatMessage({
                id: 'page.user_details.middle_name',
                defaultMessage: 'Middle name',
              })}
              value={details.individual?.middleName}
            />
            <UserDetailsBasicInfoField
              className={styles['data-field-item']}
              label={formatMessage({
                id: 'page.user_details.lastName',
                defaultMessage: 'Last name',
              })}
              value={details.lastName}
            />
            <UserDetailsBasicInfoField
              className={styles['data-field-item']}
              label={formatMessage({
                id: 'page.user_details.date_of_birth',
                defaultMessage: 'Date of Birth',
              })}
              value={details.individual?.dateOfBirth}
            />
            <UserDetailsBasicInfoField
              className={styles['data-field-item']}
              label={formatMessage({
                id: 'page.user_details.country',
                defaultMessage: 'Country',
              })}
              value={details.individual?.nationality && getCountryNameByAlpha3(details.individual.nationality)}
            />
            <UserDetailsBasicInfoField
              className={styles['data-field-item']}
              label={formatMessage({
                id: 'page.user_details.address',
                defaultMessage: 'Address',
              })}
              value={address}
            />
          </div>
        )}
        <div className={styles['document-container']}>
          <h2>
            {formatMessage({
              id: 'page.user_details.kyc.user_photo',
              defaultMessage: 'User photo',
            })}
          </h2>

          <div className={styles['slider-container']}>
            <Swiper
              modules={[Navigation, Scrollbar]}
              navigation={true}
              scrollbar={{ enabled: true }}
              slidesPerView={3}
              spaceBetween={16}
            >
              {photoFiles.map((photo, index) => {
                return (
                  <SwiperSlide
                    key={index}
                    onClick={() => {
                      setActiveFile(photo);
                      setIsModalOpen(true);
                    }}
                  >
                    <img src={photo} />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      </div>

      <h2 className={styles.title}>
        {formatMessage({
          id: 'page.user_details.kyc.documents',
          defaultMessage: 'Documents',
        })}
      </h2>

      <div className={styles.card}>
        {otherDocsElements}
      </div>

      <Modal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
        centered
      >
        {activeFile && (
          <div>
            <img src={activeFile} />
            {/* TODO: slider for gallery */}
          </div>
        )}
      </Modal>
    </div>
  );
});
