/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { FC, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import FormField from 'components/form/form-field';
import FormSelect from 'components/form/form-select';

import { CreateLimitForm } from '../index';
import { useBaseStores } from 'providers/BaseStoresProvider';
import { OperationGroupModel } from 'models/OperationGroupModel';

type Props = {
  operationGroups: OperationGroupModel[];
};

export const PlainFields: FC<Props> = ({ operationGroups }) => {
  const { formatMessage } = useIntl();
  const { control } = useFormContext<CreateLimitForm>();
  const { currencyStore } = useBaseStores();

  const currencyOptions = useMemo(
    () => {
      return Array.from(currencyStore.currenciesMap.values())
                  .map(({ id, name }) => {
                    return {
                      id: id,
                      label: name,
                      // TODO: clarify what is need for value - name or id
                      value: name,
                    };
                  });
    },
    [currencyStore.currenciesMap],
  );

  const operationGroupsOptions = useMemo(
    () => {
      return operationGroups.map(({ id, name }) => {
        return {
          id: id,
          label: name,
          value: id,
        };
      });
    },
    [operationGroups],
  );

  return (
    <>
      <Controller
        control={control}
        name="currency"
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <FormSelect
            variant="borderless"
            label={formatMessage({
              id: 'sidebar.limit_details.currency',
              defaultMessage: 'Currency',
            })}
            placeholder={formatMessage({
              id: 'sidebar.limit_details.currency.placeholder',
              defaultMessage: 'Choose currency',
            })}
            options={currencyOptions}
            value={currencyOptions.find((item) => item.value === value)}
            onChange={(value) => onChange(value?.value)}
            error={error?.message}
          />
        )}
      />

      <Controller
        control={control}
        name="operationGroupId"
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <FormSelect
            variant="borderless"
            label={formatMessage({
              id: 'sidebar.limit_details.operation_group',
              defaultMessage: 'Operation group',
            })}
            placeholder={formatMessage({
              id: 'sidebar.limit_details.operation_group.placeholder',
              defaultMessage: 'Choose operation group',
            })}
            options={operationGroupsOptions}
            value={operationGroupsOptions.find((item) => item.value === String(value))}
            onChange={(value) => onChange(value?.value)}
            error={error?.message}
          />
        )}
      />

      <Controller
        control={control}
        name="dayLimit"
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <FormField
            variant="secondary"
            label={formatMessage({
              id: 'sidebar.limit_details.day',
              defaultMessage: 'Day limit',
            })}
            placeholder={formatMessage({
              id: 'sidebar.limit_details.day.placeholder',
              defaultMessage: 'Enter amount for day limit',
            })}
            value={value}
            onChange={onChange}
            error={error?.message}
          />
        )}
      />

      <Controller
        control={control}
        name="monthLimit"
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <FormField
            variant="secondary"
            label={formatMessage({
              id: 'sidebar.limit_details.month',
              defaultMessage: 'Month limit',
            })}
            placeholder={formatMessage({
              id: 'sidebar.limit_details.month.placeholder',
              defaultMessage: 'Enter amount for month limit',
            })}
            value={value}
            onChange={onChange}
            error={error?.message}
          />
        )}
      />

      <Controller
        control={control}
        name="yearLimit"
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <FormField
            variant="secondary"
            label={formatMessage({
              id: 'sidebar.limit_details.year',
              defaultMessage: 'Year limit',
            })}
            placeholder={formatMessage({
              id: 'sidebar.limit_details.year.placeholder',
              defaultMessage: 'Enter amount for year limit',
            })}
            value={value}
            onChange={onChange}
            error={error?.message}
          />
        )}
      />

      <Controller
        control={control}
        name="globalLimit"
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <FormField
            variant="secondary"
            label={formatMessage({
              id: 'sidebar.limit_details.global',
              defaultMessage: 'Global limit',
            })}
            placeholder={formatMessage({
              id: 'sidebar.limit_details.global.placeholder',
              defaultMessage: 'Enter amount for global limit',
            })}
            value={value}
            onChange={onChange}
            error={error?.message}
          />
        )}
      />
    </>
  );
};
