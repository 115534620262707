/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import styles from './index.module.css';
import { Verifications } from './verifications';
import { KycInfo } from './kyc-levels';
import { Documents } from './documents';

import { useParseCollectionQueryParams } from 'hooks/collection-query-params';
import {
  useUserDetailsPageStore,
} from 'modules/user-details/provider/StoreProvider';

const UserDetailsKycTab = observer(() => {
  const userDetailsPageStore = useUserDetailsPageStore();

  const { page, filter } = useParseCollectionQueryParams({
    collection: userDetailsPageStore.kycVerifications,
  });

  useEffect(() => {
    void userDetailsPageStore.kycVerifications.fetchData(filter, page);
  }, [filter, page, userDetailsPageStore.kycVerifications]);

  return (
    <div className={styles.container}>
      <Verifications />
      <KycInfo />
      <Documents
        collection={userDetailsPageStore.kycVerifications}
      />
    </div>
  );
});

export default UserDetailsKycTab;
