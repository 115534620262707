/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { makeAutoObservable, runInAction } from 'mobx';

import { AuthApi } from 'api';
import { URL_SEARCH_PARAMS } from 'assets/config';
import { NotificationStore } from 'stores/NotificationStore';
import { CollectionWithPages } from 'stores/collection/CollectionWithPages';
import { readStrFromQueryParams } from 'utils/queryParams';
import { Limit } from 'api/auth';
import { LimitModel } from 'models/LimitModel';
import { FilterModel } from 'models/filter/FilterModel';
import { AdminGroupModel } from 'models/GroupModel';
import { OperationGroupModel } from 'models/OperationGroupModel';
import {
  AdminLimitsRequestModel,
} from 'models/request/AdminLimitsRequestModel';

export class LimitsPageStore {
  constructor (
    private authApi: AuthApi,
    private notificationStore: NotificationStore,
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public operationGroups: OperationGroupModel[] = [];
  public userGroups: AdminGroupModel[] = [];

  private _partnerId = 0;

  private _limits = new CollectionWithPages<LimitModel, AdminLimitsRequestModel>({
    pageQueryParamId: URL_SEARCH_PARAMS.page,
    fetchFn: async (params) => {
      const result = await this.authApi.admin.adminListLimits(params, {
        headers: { PartnerId: this._partnerId },
      });

      const items = result.data.data.map((limit) => new LimitModel(limit));

      return {
        items: items,
        totalItems: result.data.totalItems,
        totalPages: result.data.totalPages,
      };
    },
    getFilterFromQueryParamsFn: (urlParams) => {
      const filters: FilterModel<AdminLimitsRequestModel>[] = [];

      const searchStr = readStrFromQueryParams(
        urlParams,
        URL_SEARCH_PARAMS.search,
      );

      if (searchStr !== null) {
        filters.push({
          id: URL_SEARCH_PARAMS.search,
          value: {
            search: searchStr,
          },
        });
      }

      return filters;
    },
    filterCounterIgnoreValues: [URL_SEARCH_PARAMS.search],
    itemsPerPage: 12,
  });

  get limits () {
    return this._limits;
  }

  async init (props: {
    partnerId: number;
    filter: FilterModel<AdminLimitsRequestModel>[];
    page: number;
  }) {
    const { partnerId, filter, page } = props;

    runInAction(() => {
      this._partnerId = partnerId;
    });

    try {
      await this._limits.fetchData(filter, page);

      const operationsResult = await
        this.authApi.admin.listOperationGroups( { size: 10000 }, { headers: { PartnerId: this._partnerId } });
      const usersResult = await
        this.authApi.admin.adminListGroups(undefined, { headers: { PartnerId: this._partnerId } });

      const operationGroupItems = operationsResult.data.data.map((group) => new OperationGroupModel(group));
      const userGroupItems = usersResult.data.data.map((group) => new AdminGroupModel(group));

      runInAction(() => {
        this.operationGroups = operationGroupItems;
        this.userGroups = userGroupItems;
      });
    } catch (e) {
      this.notificationStore.handleApiError(e);
      console.error(e);
    }
  }

  async createLimit (limit: Limit) {
    try {
      await this.authApi.admin.adminCreateLimit(
        limit,
        { headers: { PartnerId: this._partnerId } },
      );

      this.notificationStore.notify({
        title: 'Limit was created',
        type: 'success',
      });

      await this.limits.refresh();
    } catch (e) {
      this.notificationStore.handleApiError(e);
      console.error(e);
    }
  }

  async updateLimit (limitId: number, data: Limit) {
    try {
      await this.authApi.admin.adminUpdateLimit(
        limitId,
        data,
        { headers: { PartnerId: this._partnerId } },
      );

      this.notificationStore.notify({
        title: 'Limit was updated',
        type: 'success',
      });

      await this.limits.refresh();
    } catch (e) {
      this.notificationStore.handleApiError(e);
      console.error(e);
    }
  }

  async deleteLimit (limitId: number) {
    try {
      await this.authApi.admin.adminDeleteLimit(
        limitId,
        { headers: { PartnerId: this._partnerId } },
      );

      this.notificationStore.notify({
        title: 'Limit was deleted',
        type: 'success',
      });

      await this.limits.refresh();
    } catch (e) {
      this.notificationStore.handleApiError(e);
      console.error(e);
    }
  }
}
